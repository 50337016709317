// React imports
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Material-UI imports
import {
  Box, Collapse, Container, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled, useMediaQuery, useTheme,
} from '@mui/material';

// Constants & Utilities - No imports in this section

// Components imports
import ResponsiveNanoWrapperV2 from 'components/shared/ResponsiveNanoWrapper/ResponsiveNanoWrapperV2';

// Store imports
import { useStoreRecord } from 'store/record-store';

// External library imports
import { intersectionWith } from 'lodash';

// Other imports
import { PATH_USER } from 'constants/PATHS';
import LAYOUT from 'constants/LAYOUT';
import COLORS from 'constants/COLORS';
import { ArrowBack, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { formatAmountUtil, formatDateUtil } from 'util/util';
import SHADOWS from 'constants/SHADOWS';
import RecordNanoDetail from 'components/Record/RecordNano/RecordNanoDetail';
import RecordNanoFiles from 'components/Record/RecordNano/RecordNanoFiles';

export default function RecordNanoScreen() {
  const navigate = useNavigate();
  const { recordsStore, selectedRecordIdsStore } = useStoreRecord();
  const { status } = useParams();

  const [datas, setDatas] = useState([]);

  useEffect(() => {
    const selectedDatas = intersectionWith(recordsStore, selectedRecordIdsStore, (o, id) => o?._id === id) || [];

    if (!selectedDatas?.length) {
      navigate(PATH_USER.invoices.root(status));
    } else {
      setDatas(selectedDatas);
    }
  }, [recordsStore, selectedRecordIdsStore]);

  if (!datas?.length) {
    return <Box />;
  }

  return <ResponsiveNanoWrapperV2><Screen selectedDatas={datas} /></ResponsiveNanoWrapperV2>;
}

const getHeader = ({ isMobile, status }) => {
  if (isMobile) {
    return ['', 'Invoice #'];
  }

  if (!isMobile && status === 'paid') {
    return ['', 'Invoice #', 'Amount due (MYR)', 'Issued on', 'Paid date'];
  }

  return ['', 'Invoice #', 'Amount due (MYR)', 'Issued on', 'Due date'];
};

function Screen({ selectedDatas }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { status } = useParams();
  const HEADER = useMemo(() => getHeader({ isMobile, status }), [isMobile, status]);
  const [isViewId, setIsViewId] = useState(null);

  return (
    <Container
      maxWidth="md"
      disableGutters
      sx={{ p: 4 }}
    >
      <NanoTitle container sx={{ height: 'auto' }} rowSpacing={2}>
        <Grid item xs={12} sm="auto" sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={() => { }} sx={{ display: { xs: 'inherit', md: 'none' } }} size="small"><ArrowBack fontSize="12" /></IconButton>
          <Typography variant="body1" sx={{ fontWeight: 700 }}>
            {selectedDatas.length}
            {' '}
            item(s) selected | MYR
            {' '}
            {formatAmountUtil(selectedDatas.reduce((acc, i) => acc + i.amount.processable_value, 0))}
          </Typography>
        </Grid>
        <Grid item xs={12} sm="auto">
          {/* <InvoiceNanoDetailAction selectedDatas={selectedDatas} /> */}
        </Grid>
      </NanoTitle>

      <TableContainer sx={{
        position: { xs: 'inherit', md: 'relative' },
        boxShadow: SHADOWS.z1,
        border: '1px solid #D0D5DD',
        borderRadius: 1,
        mb: 3,
        backgroundColor: '#fff',
      }}
      >
        <Table
          sx={{
            '& .MuiTableCell-root': {
              borderBottom: '1px solid #D0D5DD',
            },
          }}
        >
          <TableHead>
            <TableRow>
              {HEADER.map((h, i) => (
                <TableCell
                  size="small"
                  key={h + i}
                  sx={{
                    color: COLORS.labelSecondary, fontWeight: 400, backgroundColor: COLORS.secondaryBG,
                  }}
                >
                  {h}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedDatas.map((d) => {
              const isExpand = d?._id === isViewId;
              return (
                <React.Fragment key={d._id}>
                  <TableRow
                    hover
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      setIsViewId(isExpand ? null : d._id);
                    }}
                  >
                    <TableCell
                      align="center"
                      padding="checkbox"
                    >
                      <IconButton>
                        {isExpand ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                      </IconButton>
                    </TableCell>
                    {!isMobile ? (
                      <>
                        <TableCell sx={{ color: COLORS.primary, textDecoration: 'underline' }}>
                          {d?.record_number}
                        </TableCell>
                        <TableCell>
                          {formatAmountUtil(d?.amount?.processable_value)}
                        </TableCell>
                        <TableCell>
                          {formatDateUtil(d?.date?.issue_date)}
                        </TableCell>
                        <TableCell>
                          {status === 'paid' ? formatDateUtil(d?.date?.paid_at) : formatDateUtil(d?.date?.due_date)}
                        </TableCell>
                      </>
                    )
                      : (
                        <TableCell sx={{ color: COLORS.primary, textDecoration: 'underline' }}>
                          {d?.record_number}
                        </TableCell>
                      )}
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ padding: 0, borderBottom: isExpand ? '1px solid #D0D5DD' : 'none' }} colSpan={5}>
                      <Collapse in={isExpand} timeout="auto" unmountOnExit>
                        <RecordNanoDetail data={d} />
                        <RecordNanoFiles data={d} />

                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}

          </TableBody>
        </Table>
      </TableContainer>

    </Container>
  );
}

const NanoTitle = styled(Grid)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: theme.spacing(0),
  height: `${LAYOUT.MICRO_HEADER_HEIGHT}px`,
  padding: theme.spacing(0, 0, 3, 0),
}));
