import React from 'react';
// MUI
import {
  Card, CardContent,
  Typography, Grid, Box, IconButton, useMediaQuery,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import {
  Business, Close, CopyAll, PersonOutlineOutlined,
} from '@mui/icons-material';
// STORES
// CONST & UTIL
import { formatAmountUtil, formatDateUtil } from 'util/util';
// COMPONENTS
import ContentGrid from 'components/shared/ContentGrid/ContentGrid';
// APIS
import { enqueueSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import { PATH_USER } from 'constants/PATHS';
import RecordNanoDetailAction from './RecordNanoDetailAction';
import RecordStatusChip from '../RecordStatusChip';

export default function RecordNanoDetail({
  data,
}) {
  const navigate = useNavigate();
  const currency = data.currency.toUpperCase();
  const { status, recordId } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // -------------------------------------------------------------------------------------------------------------------------------------------------

  return (

    <InvoiceCard>
      <InvoiceCardHeader container rowSpacing={2}>

        <Grid xs item>
          <Box sx={{
            display: 'flex', flexDirection: 'row',
          }}
          >
            <Box mr={{ xs: 0, md: 1 }}>
              <Box sx={{
                display: 'flex', alignItems: 'center', flexDirection: 'row',
              }}
              >
                {data?.customer?.user_type === 'individual' ? <PersonOutlineOutlined sx={{ fontSize: 13.5, mr: 1 }} /> : <Business sx={{ fontSize: 13.5, mr: 1 }} />}
                <Typography variant="body1" sx={{ fontWeight: 600, mr: 1 }}>
                  {data?.customer?.business_name || `${data?.customer?.first_name} ${data?.customer?.last_name}`}
                  {' '}
                  {data?.customer?.email && data?.customer?.phone_number ? (
                    `(${data.customer.email} | ${data.customer.phone_number})`
                  ) : (
                    `(${data?.customer?.email || data?.customer?.phone_number})`
                  )}
                </Typography>
              </Box>
              <Box sx={{
                display: 'flex', alignItems: 'center', flexDirection: 'row',
              }}
              >
                <Typography variant="caption" sx={{ fontWeight: 500, color: grey[700] }}>
                  {data?.record_number}
                </Typography>
                <IconButton
                  size="small"
                  sx={{ ml: 0.5 }}
                  onClick={() => {
                    navigator.clipboard.writeText(data.record_number);
                    enqueueSnackbar('Copied to clipboard', { variant: 'info', autoHideDuration: 1000 });
                  }}
                >
                  <CopyAll sx={{ fontSize: 14 }} />

                </IconButton>
              </Box>
            </Box>
            <RecordStatusChip record={data} />
          </Box>

        </Grid>
        {recordId != null && isMobile
          && (
            <Grid xs="auto" item>
              <IconButton onClick={() => { navigate(PATH_USER.invoices.root(status)); }}><Close /></IconButton>
            </Grid>
          )}
        <RecordNanoDetailAction data={data} />
      </InvoiceCardHeader>

      <CardContent sx={{ mb: 0 }}>
        <Grid container direction="row" spacing={3}>
          <ContentGrid label="Invoice Number" value={`${data?.record_number}`} />
          <ContentGrid
            label="Total Amount"
            value={`${formatAmountUtil(data?.amount?.total_amount)} ${currency}`}
          />
          <ContentGrid
            label={data?.invoice_status === 'paid' ? 'Paid through Ara' : 'Amount Due'}
            value={`${formatAmountUtil(data?.amount?.processable_value)} ${currency}`}
          />

          <ContentGrid
            label="Created On"
            value={`${formatDateUtil(data?.date?.converted_at, 'dd MMM yyyy')}`}
            tooltip="Date when invoice was created"
          />
          <ContentGrid
            label="Issued On"
            value={`${formatDateUtil(data?.date?.issue_date, 'dd MMM yyyy')}`}
            tooltip="Date when invoice issued to customer"
          />
          <ContentGrid label="Due Date" value={`${formatDateUtil(data?.date?.due_date, 'dd MMM yyyy')}`} />
        </Grid>
      </CardContent>

    </InvoiceCard>

  );
}

const InvoiceCard = styled(Card)(({ theme }) => ({
  // boxShadow: SHADOWS.inputPaper,
  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
  padding: theme.spacing(2),
  borderRadius: '8px',
  margin: theme.spacing(0, 0, 0, 0),
  [theme.breakpoints.down('md')]: {
    margin: theme.spacing(0, 0, 0, 0),
  },
}));

const InvoiceCardHeader = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
}));
