// REACT + OTHERS
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

// MUI
import {
  Box, TextField, Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// STORES
import { AuthCheckEmailAPI } from 'services/auth-api-services';
import { enqueueSnackbar } from 'notistack';

// UTIL & CONST
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';

export default function AuthScreen() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchParamsEmail, setSearchParamsEmail] = useState('');

  const SigninSchema = Yup.object().shape({
    email: Yup
      .string()
      .email('Please enter a valid email address')
      .required('Email address is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: SigninSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const [apiStatus, authStatus] = await AuthCheckEmailAPI(values);
      setSubmitting(false);

      if (!apiStatus || !authStatus) return;

      switch (authStatus) {
        case 'SIGN_IN':
          navigate(`sign-in/${values.email}`);
          break;
        case 'SIGN_UP':
          navigate(`sign-up/${values.email}`);
          break;
        case 'VERIFY_ACCOUNT':
          navigate(`verify-account/${values.email}`);
          break;
        case 'MAGIC_LINK':
          navigate(`magic-link-sign-in/${values.email}`);
          break;
        default:
          enqueueSnackbar('Something went wrong');
      }
    },
  });

  const {
    errors, touched, handleSubmit, getFieldProps, isSubmitting, setFieldValue,
  } = formik;

  useEffect(() => {
    let urlEmail = '';
    try {
      urlEmail = decodeURIComponent(searchParams?.get('email') || '') || '';
    } catch (error) {
      console.error('Error decoding email:', error);
    }

    if (urlEmail) {
      setSearchParamsEmail(urlEmail);
      setFieldValue('email', urlEmail);
      searchParams.delete('email');
      setSearchParams(searchParams);
    }
  }, []);

  useEffect(() => {
    if (searchParamsEmail) {
      handleSubmit();
    }
  }, [searchParamsEmail]);

  return (
    <>
      <Box sx={{ mb: 5 }}>
        <Typography variant="h4" gutterBottom>
          Welcome to ARA
        </Typography>
        <Typography color="textSecondary" variant="subtitle1" sx={{ fontWeight: 500 }}>
          Enter your details to sign in. If you want to sign up, input your preferred email address
        </Typography>
      </Box>
      <FormikProvider value={formik}>
        <Form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
        >
          <TextField
            fullWidth
            id="check_email"
            data-cy="email"
            type="email"
            name="email"
            label="Email address"
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
            {...getFieldProps('email')}
            InputProps={{
              autoComplete: 'email',
            }}
          />
          <Box sx={{ mb: 3 }} />

          <LoadingButton
            fullWidth
            size="large"
            data-cy="check_email_btn"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Continue
          </LoadingButton>
        </Form>
      </FormikProvider>
    </>
  );
}
