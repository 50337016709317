import React from 'react';
import {
  Box,
  Typography,
} from '@mui/material';

export default function SettingsStripeFullyOnboarded() {
  return (
    <Box sx={{ width: '100%', textAlign: 'center' }}>
      <Box sx={{ height: 30 }} />
      <Box sx={{ position: 'relative', textAlign: 'center' }}>
        <img alt="payment-successful" src="/images/stripe-successful.webp" style={{ width: '80%', maxWidth: 320 }} />
      </Box>

      <Box sx={{ height: 30 }} />
      <Typography variant="h5" sx={{ fontWeight: 600 }}>You are fully onboarded to Stripe!</Typography>
    </Box>
  );
}
