import React from 'react';
import {
  Box,
  Button,
  Typography,
} from '@mui/material';

export default function MicroEmpty({
  title = 'There are currently no item',
  buttonLabel = 'Try again',
  onClick = null,
}) {
  return (
    <Box sx={{
      mt: 2, display: 'flex', width: '100%', alignItems: 'center', flexDirection: 'column', textAlign: 'center', p: 2,
    }}
    >
      <img
        src="/images/empty.webp"
        alt="micro-empty"
        style={{ width: '90%', maxWidth: '350px' }}
      />
      <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>{title}</Typography>
      {onClick != null && <Button variant="contained" sx={{ mt: 2 }} size="small" onClick={onClick}>{buttonLabel}</Button>}
    </Box>
  );
}
