import React from 'react';
// MUI
import { Grid, styled } from '@mui/material';
// CONST AND UTIL
import COLORS from 'constants/COLORS';
import LAYOUT from 'constants/LAYOUT';
import SHADOWS from 'constants/SHADOWS';
// COMPONENTS
import SettingsMicro from 'components/Settings/SettingsMicro/SettingsMicro';

import ResponsiveNanoWrapperV2 from 'components/shared/ResponsiveNanoWrapper/ResponsiveNanoWrapperV2';
import { Outlet } from 'react-router-dom';

export default function SettingsScreen() {
  return (
    <Grid
      container
      maxWidth="false"
      sx={{
        height: `calc(100vh - ${LAYOUT.APP_BAR_HEIGHT}px)`,
        backgroundColor: COLORS.secondaryBG,
      }}
    >
      <MicroGrid item>
        <SettingsMicro />
      </MicroGrid>
      <ResponsiveNanoWrapperV2>
        <Outlet />
      </ResponsiveNanoWrapperV2>

    </Grid>
  );
}

const MicroGrid = styled(Grid)(({ theme }) => ({
  width: '100%',
  zIndex: 2,

  [theme.breakpoints.up('md')]: {
    width: LAYOUT.MICRO_WIDTH,
    borderRight: `1px solid ${COLORS.divider}`,
    boxShadow: SHADOWS.secondary,
  },
}));
