import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import _ from 'lodash';

const initialCustMgmtState = {
  customersStore: [],
  totalCustomersCountStore: 0,

  customerDetailStore: null,
};

export const useStoreCust = create(devtools(
  (set, get) => ({
    ...initialCustMgmtState,
    resetCustMgmtStore: () => set({ ...initialCustMgmtState }),

    // -----------------------------------------------------------------------------------------------------------------------------------------------
    // customersStore
    setCustomersStore: (arr) => set({ customersStore: arr }),
    upsertOneCustomersStore: (newData) => {
      const { customersStore, modifyTotalCustomersCountStore } = get();

      const tempArr = customersStore.slice();

      const index = _.findIndex(tempArr, { _id: newData._id });
      if (index >= 0) {
        const tempObj = tempArr[index];
        const editedObj = Object.assign(tempObj, newData);

        tempArr.splice(index, 1, editedObj);
      } else {
        modifyTotalCustomersCountStore(+1);
        tempArr.push(newData);
      }
      set({ customersStore: [...tempArr] });
    },
    removeOneCustomersStoreById: (remDataId) => {
      const { customersStore, modifyTotalCustomersCountStore } = get();

      const tempArr = _.filter(customersStore, (item) => item._id !== remDataId);
      set({ customersStore: [...tempArr] });
      modifyTotalCustomersCountStore(-1);
    },

    // -----------------------------------------------------------------------------------------------------------------------------------------------
    // totalCustomersCountStore
    setTotalCustomersCountStore: (int) => set({ totalCustomersCountStore: int }),
    modifyTotalCustomersCountStore: (int) => {
      const { totalCustomersCountStore } = get();
      set({ totalCustomersCountStore: totalCustomersCountStore + int });
    },

  }),
));
