import React, { useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';

// MUI components
import {
  Autocomplete,
  Box,
  Checkbox,
  Container,
  Dialog,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  styled,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

// MUI icons
import { LoadingButton } from '@mui/lab';
import { Close, HelpTwoTone } from '@mui/icons-material';

// Stores
import { useStoreAuth } from 'store/auth-store';
import { useStoreShared } from 'store/shared-store';

// Constants
import COUNTRIES from 'constants/COUNTRIES';
import COLORS from 'constants/COLORS';
import { find } from 'lodash';

// Components
import AlertDialog from '../Dialogs/AlertDialog';
import MuiPhoneInput from '../Input/MuiPhoneInput';
import { UserAboutMeAPI } from 'services/user-api-services';
import { BizOnboardSchema } from 'util/Business/biz-onboard-validation';
import { BusinessCreateOneVerifiedAPI } from 'services/business-api-services';

/* eslint-disable react/no-unescaped-entities */
export default function OnboardingOpenBusinessDialog() {
  const { businessProfileStore } = useStoreAuth();
  const { isOpenBizAccModalStore, setIsOpenBizAccModalStore } = useStoreShared();

  const [isAccept, setIsAccept] = useState(false);

  // -------------------------------------------------------------------------------------------------------------------------------------------------

  const formik = useFormik({
    initialValues: {
      name: '',
      short_name: '',
      type: '',
      country_code: 'MY',
      office_number: '',
      phone_number: '',
      address_line_1: '',
      address_line_2: '',
      city: '',
      state: '',
      postcode: '',
    },
    validationSchema: BizOnboardSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const [createApiStatus] = await BusinessCreateOneVerifiedAPI(values);
      setSubmitting(false);
      if (!createApiStatus) return;

      setIsOpenBizAccModalStore(false);

      const [apiStatus, userData] = await UserAboutMeAPI();

      if (!apiStatus || !userData) return;

      handleClose();
    },
  });

  const {
    errors, touched, handleSubmit, getFieldProps, setFieldValue, isSubmitting,
  } = formik;

  const handleClose = () => {
    if (!isSubmitting) {
      setIsOpenBizAccModalStore(false);
      setIsAccept(false);
      formik.resetForm();
    }
  };

  // -------------------------------------------------------------------------------------------------------------------------------------------------

  if (!isSubmitting && isOpenBizAccModalStore && businessProfileStore) {
    return (
      <AlertDialog
        open
        maxWidth="xs"
        setOpen={setIsOpenBizAccModalStore}
        title="Limited Account Availability"
        message="We apologize for any inconvenience. Currently, we are only able to allow one Malaysia Business Account at a time."
        message2={(
          <Typography variant="body1" sx={{ mb: 2 }}>
            If you would to know more, click on
            {' '}
            <b>"Ask Ara"</b>
            {' '}
            on the top right.
          </Typography>
        )}
        actions={[
          {
            text: 'Okay',
            onClick: () => {
              setIsOpenBizAccModalStore(false);
            },
          },

        ]}
      />
    );
  }

  return (
    <Dialog
      open={isOpenBizAccModalStore === true}
      onClose={handleClose}
      disableEscapeKeyDown={isSubmitting}
    >
      {isOpenBizAccModalStore
        && (
          <FormikProvider value={formik}>
            <Form
              autoComplete="off"
              noValidate
              onSubmit={handleSubmit}
            >
              <NanoPaperContainer
                maxWidth="sm"
                disableGutters
                sx={{ width: { xs: 'auto', sm: '560px' } }}
              >
                <IconButton sx={{ position: 'absolute', top: 5, right: 24 }} size="small" onClick={handleClose}>
                  <Close fontSize="small" />
                </IconButton>
                <NanoTitle container spacing={2}>
                  <Grid item xs>
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: 800 }}
                    >
                      Onboard with us now to start receiving payments

                    </Typography>
                    <Typography variant="body1" sx={{ color: COLORS.labelSecondary, mt: 1 }}>
                      Fill in the form below to kickstart your onboarding process
                    </Typography>
                  </Grid>
                </NanoTitle>
                <NanoCollapseHeader container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1" sx={{ fontWeight: 700 }}>Company Information</Typography>
                    <Typography variant="body2" sx={{ color: COLORS.labelSecondary }}>Your company details</Typography>
                  </Grid>
                </NanoCollapseHeader>
                <Box sx={{ height: 24 }} />
                <Grid container spacing={4} name="onboard-business-grid">
                  <Grid item sm={7} xs={12}>
                    <TextField
                      data-cy="onboard-business-business_name"
                      fullWidth
                      label="Business Name"
                      {...getFieldProps('name')}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                      size="small"
                    />
                  </Grid>
                  <Grid item sm={5} xs={12}>
                    <TextField
                      data-cy="onboard-business-business_short_name"
                      fullWidth
                      label="Short Name"
                      {...getFieldProps('short_name')}
                      error={Boolean(touched.short_name && errors.short_name)}
                      helperText={touched.short_name && errors.short_name}
                      size="small"
                      InputProps={{
                        // eslint-disable-next-line
                        endAdornment: <InputAdornment position="end" sx={{ cursor: 'pointer' }}><Tooltip title={`This short name allows to keep our SMS messages as short as possible. For example “Blue Space Technology & Friends” their short name could be “Blue Space”.`} placement="top" ><HelpTwoTone fontSize="small" color="primary" /></Tooltip></InputAdornment>,
                      }}
                    />
                  </Grid>
                </Grid>
                <Box sx={{ height: 24 }} />
                <Autocomplete
                  data-cy="onboard-business-autocomplete_business_type"
                  fullWidth
                  freeSolo
                  options={['Sole Proprietorship', 'Partnership', 'Corporation', 'Limited Liability']}
                  value={getFieldProps('type').value}
                  onInputChange={(_e, value) => {
                    setFieldValue('type', value || '');
                  }}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      data-cy="onboard-business-autocomplete_business_type"
                      {...params}
                      error={Boolean(touched.type && errors.type)}
                      helperText={touched.type && errors.type}
                      label={'Business Type'}
                    />
                  )}
                />
                <Box sx={{ height: 24 }} />
                <Autocomplete
                  data-cy="onboard-business-autocomplete_country"
                  fullWidth
                  options={COUNTRIES}
                  disableClearable
                  disabled // disable country selection for now as we only providing business for Malaysia
                  getOptionLabel={(option) => option.name}
                  onChange={(_e, value) => {
                    setFieldValue('country_code', value.code || '');
                  }}
                  value={find(COUNTRIES, (c) => c?.code === getFieldProps('country_code')?.value) || COUNTRIES[128]}
                  isOptionEqualToValue={(option, value) => option.name === value.name}
                  filterOptions={(options, { inputValue }) => options
                    .filter((x) => x.code.toLowerCase() === inputValue.toLowerCase() || x.name.toLowerCase().startsWith(inputValue.toLowerCase()))}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      data-cy="onboard-business-autocomplete_country"
                      {...params}
                      error={Boolean(touched.country_code && errors.country_code)}
                      // helperText={touched.country_code && errors.country_code}
                      helperText="Updating country is disabled"
                      label="Country"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'current-password',
                      }}
                    />
                  )}
                />
                <Box sx={{ height: 24 }} />
                <Grid container spacing={4} name="onboard-business-grid-full_name">
                  <Grid item sm={6} xs={12}>
                    <MuiPhoneInput
                      formik={formik}
                      formikFieldKey={'phone_number'}
                      label="Phone Number"
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <MuiPhoneInput
                      formik={formik}
                      formikFieldKey={'office_number'}
                      label="SMS Number (Optional)"
                      forceCallingCode={false}
                    />
                  </Grid>
                </Grid>
                <Box sx={{ height: 24 }} />
                <NanoCollapseHeader container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1" sx={{ fontWeight: 700 }}>Address Information</Typography>
                    <Typography variant="body2" sx={{ color: COLORS.labelSecondary }}>Your company address information</Typography>
                  </Grid>
                </NanoCollapseHeader>
                <Box sx={{ height: 24 }} />
                <TextField
                  data-cy="onboard-business-address_line_1"
                  fullWidth
                  label="Address Line 1"
                  {...getFieldProps('address_line_1')}
                  error={Boolean(touched.address_line_1 && errors.address_line_1)}
                  helperText={touched.address_line_1 && errors.address_line_1}
                  size="small"
                />
                <Box sx={{ height: 24 }} />

                <Grid container spacing={2}>
                  <Grid item sm={8} xs={7}>
                    <TextField
                      fullWidth
                      data-cy="onboard-business-city"
                      label="City"
                      {...getFieldProps('city')}
                      error={Boolean(touched.city && errors.city)}
                      helperText={touched.city && errors.city}
                      size="small"
                    />
                  </Grid>
                  <Grid item sm={4} xs={5}>
                    <TextField
                      fullWidth
                      data-cy="onboard-business-postcode"
                      label="Postcode"
                      {...getFieldProps('postcode')}
                      error={Boolean(touched.postcode && errors.postcode)}
                      helperText={touched.postcode && errors.postcode}
                      size="small"
                    />
                  </Grid>
                </Grid>

                <Box sx={{ height: 24 }} />

                <TextField
                  fullWidth
                  data-cy="onboard-business-state"
                  label="State"
                  {...getFieldProps('state')}
                  error={Boolean(touched.state && errors.state)}
                  helperText={touched.state && errors.state}
                  size="small"
                />

                <FormGroup sx={{ my: 1 }}>
                  <FormControlLabel
                    control={<Checkbox checked={isAccept} onChange={(e) => setIsAccept(e.target.checked)} size="small" />}
                    label={(
                      <Typography>
                        By Onboarding, I Agree with Ara App
                        {' '}
                        <Link
                          sx={{ fontWeight: 900 }}
                          target="_blank"
                          rel="noopener noreferrer"
                          href="/terms"
                        >
                          Terms & Conditions

                        </Link>
                        {' '}
                      </Typography>
                    )}
                  />
                </FormGroup>

                <Grid container justifyContent="flex-end">
                  <LoadingButton
                    size="medium"
                    type="submit"
                    variant="contained"
                    fullWidth
                    loading={isSubmitting}
                    disabled={!isAccept}
                    data-cy="onboard-business-btn"
                  >
                    Start Onboarding Process
                  </LoadingButton>
                </Grid>

              </NanoPaperContainer>
            </Form>
          </FormikProvider>
        )}
    </Dialog>
  );
}

const NanoPaperContainer = styled(Container)(({ theme }) => ({

  padding: theme.spacing(2, 3),
  background: '#fff',
  borderRadius: 2,
  position: 'relative',
  [theme.breakpoints.up('sm')]: {
    border: `1px solid ${COLORS.divider}`,
    boxShadow: '0 8px 10px rgba(112,144,176,0.2)',
    padding: theme.spacing(4),
  },

}));

const NanoTitle = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(0, 0, 3, 0),
}));

const NanoCollapseHeader = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(0),
}));
