// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOT = '/user';
// ----------------------------------------------------------------------

export const PATH_USER = {
  root: ROOT,
  draftinvoices: {
    root: path(ROOT, '/draftinvoices'),
    create: path(ROOT, '/draftinvoices/create'),
    edit: (recordId) => path(ROOT, `/draftinvoices/edit/${recordId}`),
    multi: path(ROOT, '/draftinvoices/multi'),
  },
  invoices: {
    root: (status) => path(ROOT, `/invoices/${status}`),
    multi: (status) => path(ROOT, `/invoices/${status}/multi`),
    detail: (status, recordId) => path(ROOT, `/invoices/${status}/detail/${recordId}`),
  },
  customers: {
    root: (status) => path(ROOT, `/customers/${status}`),
    create: path(ROOT, '/customers/create'),
    detail: (status, customerId) => path(ROOT, `/customers/${status}/detail/${customerId}`),
  },

};
