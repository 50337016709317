import { create } from 'zustand';

const initialSharedState = {
  // PAYEX
  payexOnboardingStatusStore: '',
  payexOnboardingLastCheckAtStore: null,
  // MODALS
  attachmentViewerUrlStore: null,
  isOpenBizAccModalStore: false,
  isOpenReferAraModalStore: false,

};

export const useStoreShared = create((set) => ({
  ...initialSharedState,
  resetSharedStore: () => set({ ...initialSharedState }),
  resetSharedPayexStore: () => set({
    payexOnboardingStatusStore: '',
    payexOnboardingLastCheckAtStore: null,
  }),
  resetSharedModalStore: () => set({
    attachmentViewerUrlStore: null,
    isOpenSwitchAccModalStore: false,
    isOpenBizAccModalStore: false,
    isOpenReferAraModalStore: false,
  }),

  // -------------------------------------------------------------------------------------------------------------------------------------------------
  setPayexOnboardingStatusStore: (status) => set({ payexOnboardingStatusStore: status }),
  setPayexOnboardingLastCheckAtStore: (date) => set({ payexOnboardingLastCheckAtStore: date }),

  // -------------------------------------------------------------------------------------------------------------------------------------------------
  // src/components/shared/AttachmentViewer.js/AttachmentViewerDialog.js
  setAttachmentViewerUrlStore: (url) => set({ attachmentViewerUrlStore: url }),

  // src/components/shared/Onboarding/OnboardingOpenBusinessDialog.js
  setIsOpenBizAccModalStore: (bool) => set({ isOpenBizAccModalStore: bool }),

  // src/components/shared/Dialogs/ReferAraDialog.js
  setIsOpenReferAraModalStore: (bool) => set({ isOpenReferAraModalStore: bool }),

}));
