const LAYOUT = {
  // Primary Color
  APP_BAR_HEIGHT: 64,
  DRAWER_WIDTH: 240,
  MICRO_WIDTH: 400,
  MICRO_HEADER_HEIGHT: 50,
  MICRO_SEARCHBAR_HEIGHT: 35,
  MICRO_LIST_BOX_HEIGHT: 72,
  MICRO_HEADER_SPACING: 20,
  CUST_MICR_LIST_BOX_HEIGHT: 75,

};

export default LAYOUT;
