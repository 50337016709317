import React from 'react';
// MUI
const { Tooltip, Chip } = require('@mui/material');
const { getRecordBadgeColorUtil } = require('util/Record/get-record-badge-color-util');

export default function RecordStatusChip({ record }) {
  const {
    bgColor, color, tooltip, label,
  } = getRecordBadgeColorUtil(record);

  return (
    <Tooltip
      title={tooltip}
      placement="top"
      arrow
    >
      <Chip
        label={label}
        size="small"
        sx={{
          padding: 0.5,
          fontSize: 10,
          color,
          backgroundColor: bgColor,
          fontWeight: 700,
          minWidth: 50,
        }}
      />
    </Tooltip>
  );
}
