import {
  styled, ListItemText, ListSubheader, List, ListItemIcon, Divider, ListItemButton,
} from '@mui/material';
import COLORS from 'constants/COLORS';
import SHADOWS from 'constants/SHADOWS';

import React from 'react';
import {
  AdminPanelSettingsTwoTone,
  ArrowForwardIosTwoTone, LockResetTwoTone, NotificationsActive,
} from '@mui/icons-material';
import { useStoreAuth } from 'store/auth-store';
import { useLocation, useNavigate } from 'react-router-dom';

const BUSINESS_LIST = [
  {
    value: 'stripe',
    icon: <img alt="stripe" src="/images/stripe-icon.svg" style={{ height: '14px' }} />,
    title: 'Stripe Onboarding',
  },
  {
    value: 'notification',
    icon: <NotificationsActive />,
    title: 'Notifications',
  },
];

export default function SettingsMicro() {
  const navigate = useNavigate();
  const location = useLocation();

  const { businessProfileStore } = useStoreAuth();

  return (
    <MicroContainer>
      <List
        disablePadding
        subheader={(
          <ListSubheader sx={{ backgroundColor: COLORS.secondaryBG, fontWeight: 'bold' }}>
            User
          </ListSubheader>
        )}
      >
        <Divider />

        <ListItemButton divider sx={{ display: 'none' }}>
          <ListItemIcon>
            <AdminPanelSettingsTwoTone />
          </ListItemIcon>
          <ListItemText primary="Update Profile" />
          <ListItemIcon sx={{ minWidth: 0 }}>
            <ArrowForwardIosTwoTone fontSize="small" />
          </ListItemIcon>
        </ListItemButton>

        <ListItemButton
          onClick={() => { navigate('password'); }}
          divider
          selected={location?.pathname?.includes('password')}
        >
          <ListItemIcon>
            <LockResetTwoTone />
          </ListItemIcon>
          <ListItemText primary="Change Password" />
          <ListItemIcon sx={{ minWidth: 0 }}>
            <ArrowForwardIosTwoTone fontSize="small" />
          </ListItemIcon>
        </ListItemButton>

      </List>
      {businessProfileStore != null
        && (
          <List
            disablePadding
            subheader={<ListSubheader sx={{ backgroundColor: COLORS.secondaryBG, fontWeight: 'bold' }}>Business</ListSubheader>}
          >
            <Divider />
            {BUSINESS_LIST.map((b) => <MicroListButton key={b.value} {...b} />)}
          </List>
        )}
    </MicroContainer>
  );
}

function MicroListButton({
  icon, title, value,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <ListItemButton
      onClick={() => { navigate(value); }}
      divider
      selected={location?.pathname?.includes(value)}
    >
      <ListItemIcon>
        {icon}
      </ListItemIcon>
      <ListItemText primary={title} />
      <ListItemIcon sx={{ minWidth: 0 }}>
        <ArrowForwardIosTwoTone fontSize="small" />
      </ListItemIcon>
    </ListItemButton>
  );
}

const MicroContainer = styled('div')(() => ({
  height: '100%',
  width: '100%',
  backgroundColor: COLORS.mainBG,
  boxShadow: SHADOWS.z8,
}));
