import React from 'react';
// MUI
import {
  Card, CardContent,
  Typography, Grid, Box, Divider, Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Business, Edit, PersonOutlineOutlined,
} from '@mui/icons-material';
// STORES
// CONST & UTIL
import { formatAmountUtil, formatDateUtil } from 'util/util';
// COMPONENTS
import ContentGrid from 'components/shared/ContentGrid/ContentGrid';
import { useNavigate } from 'react-router-dom';
import { PATH_USER } from 'constants/PATHS';
import AttachmentViewerFile from 'components/shared/AttachmentViewer.js/AttachmentViewerFile';

export default function DraftRecordNanoDetail({
  data,
}) {
  const navigate = useNavigate();
  const currency = data.currency.toUpperCase();

  return (

    <InvoiceCard>
      <InvoiceCardHeader container rowSpacing={2} display={'flex'} alignItems={'center'}>
        <Grid xs={12} md item>
          <Box sx={{
            display: 'flex', alignItems: 'center', flexDirection: 'row',
          }}
          >
            {data?.customer?.user_type === 'individual' ? <PersonOutlineOutlined sx={{ fontSize: 13.5, mr: 1 }} /> : <Business sx={{ fontSize: 13.5, mr: 1 }} />}
            <Typography variant="body1" sx={{ fontWeight: 600 }}>
              {data?.customer?.business_name || `${data?.customer?.first_name || ''} ${data?.customer?.last_name || ''}`}
            </Typography>
          </Box>
        </Grid>
        <Grid xs={12} md="auto" item>
          <Button
            size="small"
            variant="contained"
            startIcon={<Edit />}
            onClick={() => {
              navigate(PATH_USER.draftinvoices.edit(data._id));
            }}
          >
            Edit Draft
          </Button>

        </Grid>
      </InvoiceCardHeader>

      <CardContent sx={{ mb: 0 }}>
        <Grid container direction="row" spacing={3} sx={{ mb: 3 }}>
          <ContentGrid label="Email" value={`${data?.customer?.email || '-'}`} />
          <ContentGrid label="Phone Number" value={`${data?.customer?.phone_number || '-'}`} />

        </Grid>
        <Divider sx={{ mb: 4 }} />
        <Grid container direction="row" spacing={3} sx={{ mb: 3 }}>
          <ContentGrid label="Invoice Number" value={`${data.record_number}`} />
          <ContentGrid label="Description" value={`${data?.description || '-'}`} />
          <ContentGrid
            label="Issued On"
            value={`${formatDateUtil(data?.date?.issue_date, 'dd MMM yyyy')}`}
          />
          <ContentGrid label="Due Date" value={`${formatDateUtil(data?.date?.due_date, 'dd MMM yyyy')}`} />
          <ContentGrid
            label={'Total Invoice Value'}
            value={`${currency} ${formatAmountUtil(data?.amount?.total_amount)}`}
          />
          <ContentGrid
            label={'Total Paid'}
            value={`${currency} ${formatAmountUtil(data?.amount?.previous_payment_amount)}`}
          />
          <ContentGrid
            label={'Amount Due'}
            value={`${currency} ${formatAmountUtil(data?.amount?.processable_value)}`}
          />
          <ContentGrid
            label={'Interest Per Day'}
            value={`${currency} ${formatAmountUtil(data?.charges?.interest_rate_config?.daily_rate)} `}
          />
        </Grid>
      </CardContent>
      {data?.files?.user_uploaded_record?.url
        && (
          <AttachmentViewerFile
            url={`${data?.files?.user_uploaded_record?.url}`}
            fileName={`draft-${data?.record_number}`}
          />
        )}
    </InvoiceCard>

  );
}

const InvoiceCard = styled(Card)(({ theme }) => ({
  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
  padding: theme.spacing(4),
  borderRadius: '8px',
  margin: theme.spacing(0, 0, 0, 0),
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(0, 0, 0, 0),
    padding: theme.spacing(0),
  },
}));

const InvoiceCardHeader = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
}));
