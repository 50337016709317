import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getErrMessageUtil } from 'util/util';
import { useSnackbar } from 'notistack';
import { getAPI } from 'util/API';
// MUI
import {
  Box,
  CircularProgress,
  Stack, Typography,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import COLORS from 'constants/COLORS';
import { round } from 'lodash';
import { format } from 'date-fns';

export default function FileDownloadScreen() {
  const { jwtToken } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(0);

  const handleDownloadFile = () => {
    // 3 minutes timeout
    const API = getAPI('invoice', 1800000);
    const url = `/invoice/download/${jwtToken}`;

    API.get(url, {
      onDownloadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        const p = round((loaded / total) * 100);
        setLoadingProgress(p);
      },
      responseType: 'blob',
      headers: {
        Accept: 'application/octet-stream',
      },
    })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/zip' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `invoices_${format(new Date(), 'ddMMMyyyy')}.zip`;
        link.click();
        window.opener = null;
        window.open('', '_self');
        window.close();
        setIsLoading(false);
      })
      .catch((err) => {
        const errMessage = getErrMessageUtil(err);
        enqueueSnackbar(errMessage, { variant: 'error' });
      });
  };

  useEffect(() => {
    handleDownloadFile();
  }, []);

  return (
    <div style={{
      width: '100vw',
      height: '100vh',
      padding: '20px',
      backgroundColor: COLORS.mainBG,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    }}
    >

      {isLoading ? (
        <Stack sx={{ width: '80%', maxWidth: '400px', textAlign: 'center' }} spacing={2}>
          <img
            alt="verifying"
            src="/images/auth/verifying.webp"
            style={{ width: '100%' }}
          />
          <Box sx={{ pt: 2 }}><CircularProgressWithLabel value={loadingProgress} /></Box>
          <Typography sx={{ fontWeight: 700 }} variant="h6">
            Downloading your invoices
          </Typography>
          <Typography sx={{ fontWeight: 700 }}>
            This may take a minute or two if the file size are large.
          </Typography>
        </Stack>
      )
        : (
          <Stack sx={{ width: '80%', maxWidth: '400px', textAlign: 'center' }} spacing={2}>
            <img
              alt="verified"
              src="/images/auth/verified.webp"
              style={{ width: '100%' }}
            />
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              pt: 4,
              color: COLORS.green,
            }}
            >
              <CheckCircleIcon fontSize="large" sx={{ mr: 2 }} />
              <Typography sx={{ fontWeight: 700 }} variant="h6">
                Invoices Downloaded Succesfully, you may close this window
              </Typography>
            </Box>

          </Stack>
        ) }
    </div>
  );
}

function CircularProgressWithLabel(
  { value },
) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress size={44} color="primary" />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >
          {`${round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}
