import React, { useState } from 'react';
import { FieldArray, useField } from 'formik';
// MUI
import {
  Alert,
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import {
  Add, ExpandLess, ExpandMore, HelpTwoTone,
} from '@mui/icons-material';
// COMPONENTS
import { enqueueSnackbar } from 'notistack';
import MuiPhoneInput from 'components/shared/Input/MuiPhoneInput';

/* eslint-disable max-len */
export default function CustomerInputContactDetail({
  formik, isEditing = true, customer = null,
}) {
  const {
    errors, touched, setFieldValue, getFieldProps, values,
  } = formik;

  const [isCollapse, setIsCollapse] = useState(false);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6}>
          <Typography variant="body1" sx={{ fontWeight: 700 }}>Contact Details</Typography>
        </Grid>
        <Grid item xs={6} sm={6}>
          <Box
            sx={{ height: '100%' }}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            flexDirection="row"
          >
            <Button
              color="neutral"
              size="small"
              data-cy="cust-business-detail-collapse-btn"
              sx={{ ml: 2 }}
              onClick={() => {
                setIsCollapse(!isCollapse);
              }}
              startIcon={isCollapse ? <ExpandMore /> : <ExpandLess />}
              tabIndex={-1}
            >
              {isCollapse ? 'Show' : 'Hide'}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Collapse in={!isCollapse} timeout="auto">
        <Box sx={{ height: 12 }} />
        {isEditing && customer != null
          && (
            <>
              <Alert severity="info">All future invoice reminders will be sent to the contacts added here</Alert>
              <Box sx={{ height: 12 }} />
            </>
          )}
        <Typography variant="caption" sx={{ color: grey[600], fontWeight: 600 }}>Main Contact</Typography>
        <Box sx={{ height: 12 }} />
        <Grid container spacing={4} data-cy="create-customer-grid-contact_number">
          <Grid item sm={6} xs={12}>
            <TextField
              fullWidth
              type="email"
              data-cy="email"
              label={'Email Address'}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
              {...getFieldProps('email')}
              onChange={(e) => {
                setFieldValue('email', e?.target?.value || '');
              }}
              size="small"
              inputProps={{
                autoComplete: 'new-password',
                form: {
                  autoComplete: 'off',
                },
                readOnly: !isEditing,
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <MuiPhoneInput
              formik={formik}
              formikFieldKey={'phone_number'}
              label={'Phone Number'}
              forceCallingCode={false}
              InputProps={{
                autoComplete: 'new-password',
                form: {
                  autoComplete: 'off',
                },
                readOnly: !isEditing,
              }}
              disableDropdown={!isEditing}
            />
          </Grid>
        </Grid>

        <Box sx={{ height: 24 }} />
        <Grid container spacing={4} data-cy="create-customer-grid-full_name">
          <Grid item sm={6} xs={12}>
            <TextField
              data-cy="first_name"
              fullWidth
              label={'First Name'}
              {...getFieldProps('first_name')}
              error={Boolean(touched.first_name && errors.first_name)}
              helperText={touched.first_name && errors.first_name}
              inputProps={{
                autoComplete: 'new-password',
                form: {
                  autoComplete: 'off',
                },
                readOnly: !isEditing,
              }}
              size="small"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              data-cy="last_name"
              fullWidth
              label={'Last Name'}
              {...getFieldProps('last_name')}
              error={Boolean(touched.last_name && errors.last_name)}
              helperText={touched.last_name && errors.last_name}
              inputProps={{
                autoComplete: 'new-password',
                form: {
                  autoComplete: 'off',
                },
                readOnly: !isEditing,
              }}
              size="small"
            />
          </Grid>
        </Grid>
        <Box sx={{ height: 24 }} />
        <Grid container spacing={4} data-cy="create-customer-grid-short_name">
          <Grid item xs={12}>
            <TextField
              date-cy="short_name"
              fullWidth
              label={'Short Name'}
              {...getFieldProps('short_name')}
              error={Boolean(touched.short_name && errors.short_name)}
              helperText={touched.short_name && errors.short_name}
              size="small"
              InputProps={{
                autoComplete: 'new-password',
                form: {
                  autoComplete: 'off',
                },
                readOnly: !isEditing,
                endAdornment: <InputAdornment position="end" sx={{ cursor: 'pointer' }}><Tooltip title="This short name allows to keep our SMS messages as short as possible. For example “Simon Ghost Riley” their short name could be “Simon”." placement="top"><HelpTwoTone fontSize="small" color="primary" /></Tooltip></InputAdornment>,
              }}
            />
          </Grid>
        </Grid>
        <Box sx={{ height: 24 }} />
        <MuiPhoneInput
          formik={formik}
          formikFieldKey={'office_number'}
          label={'SMS Number (Optional)'}
          forceCallingCode={false}
          InputProps={{
            autoComplete: 'new-password',
            form: {
              autoComplete: 'off',
            },
            readOnly: !isEditing,
            endAdornment: <InputAdornment position="end" sx={{ cursor: 'pointer' }}><Tooltip title={'By adding this number we have another channel to remind your customer through and accelerate their payments.'} placement="top"><HelpTwoTone fontSize="small" color="primary" /></Tooltip></InputAdornment>,
          }}
          disableDropdown={!isEditing}
        />
        <Box sx={{ height: 12 }} />

        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Divider orientation="vertical" sx={{ ml: 1, mr: 2, height: 'inherit' }} />
          <FieldArray
            key={isEditing}
            {...getFieldProps('additional_contacts')}
            render={(arrayHelpers) => (
              <Box width={'100%'}>
                <Box sx={{ height: 12 }} />
                {values?.additional_contacts != null && values.additional_contacts.map((contact, index) => (
                  <ContactDetailAdditionalContact
                    contact={contact}
                    index={index}
                    arrayHelpers={arrayHelpers}
                    key={index}
                    isEditing={isEditing}
                  />
                ))}
                <Button
                  disabled={!isEditing}
                  startIcon={<Add />}
                  size="small"
                  sx={{ fontWeight: 'bold' }}
                  onClick={() => {
                    if (values?.additional_contacts?.length >= 5) {
                      enqueueSnackbar('You have reached the maximum "Additional Contact" limit', { variant: 'info' });
                      return;
                    }

                    arrayHelpers.push({
                      email: '', first_name: '', last_name: '', phone_number: '',
                    });
                  }}
                >
                  Add Contact
                </Button>
                <Box sx={{ height: 12 }} />
              </Box>
            )}
          />
        </Box>
      </Collapse>
    </>
  );
}

function ContactDetailAdditionalContact({
  index, arrayHelpers, isEditing = true,
}) {
  const { remove } = arrayHelpers;

  const [emailField, emailMeta] = useField({ name: `additional_contacts.${index}.email` });
  const [firstNameField, firstNameMeta] = useField({ name: `additional_contacts.${index}.first_name` });
  const [lastNameField, lastNameMeta] = useField({ name: `additional_contacts.${index}.last_name` });
  const [phoneNumberField, phoneNumberMeta, phoneNumberHelpers] = useField({ name: `additional_contacts.${index}.phone_number` });

  return (
    <>
      <Box sx={{ height: 12 }} />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="caption" sx={{ color: grey[600], fontWeight: 600 }}>
          {`Additional Contact #${index + 1}`}
        </Typography>
        <Button
          disabled={!isEditing}
          size="small"
          color="error"
          sx={{ fontWeight: 'bold' }}
          onClick={() => remove(index)}
        >
          Remove
        </Button>
      </Box>
      <Box sx={{ height: 12 }} />
      <Grid container columnSpacing={1} rowSpacing={2} name={`create-customer-additional-contacts-${index + 1}-grid-full_name`}>
        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            type="email"
            data-cy={`create-customer-additional-contacts-${index}-email`}
            label={'Email Address'}
            {...emailField}
            error={emailMeta.touched && Boolean(emailMeta.error)}
            helperText={emailMeta.touched && emailMeta.error}
            size="small"
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autoComplete: 'off',
              },
              readOnly: !isEditing,
            }}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <MuiPhoneInput
            // Other props
            data-cy={`create-customer-additional-contacts-${index + 1}-phone_number`}
            label={'Phone Number'}
            forceCallingCode={false}
            error={phoneNumberMeta.touched && Boolean(phoneNumberMeta.error)}
            helperText={phoneNumberMeta.touched && phoneNumberMeta.error}
            {...phoneNumberField}
            onChange={(newValue) => phoneNumberHelpers.setValue(newValue)}
            InputProps={{
              autoComplete: 'new-password',
              form: {
                autoComplete: 'off',
              },
              readOnly: !isEditing,
            }}
            disableDropdown={!isEditing}
          />
        </Grid>
      </Grid>
      <Box sx={{ height: 12 }} />
      <Grid container columnSpacing={1} rowSpacing={2} name={`create-customer-additional-contacts-${index + 1}-grid-full_name`}>
        <Grid item sm={6} xs={12}>
          <TextField
            data-cy="first_name"
            fullWidth
            label={'First Name'}
            {...firstNameField}
            error={firstNameMeta.touched && Boolean(firstNameMeta.error)}
            helperText={firstNameMeta.touched && firstNameMeta.error}
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autoComplete: 'off',
              },
              readOnly: !isEditing,
            }}
            size="small"
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            data-cy={`create-customer-additional-contacts-${index + 1}-last_name`}
            fullWidth
            label={'Last Name'}
            {...lastNameField}
            error={lastNameMeta.touched && Boolean(lastNameMeta.error)}
            helperText={lastNameMeta.touched && lastNameMeta.error}
            inputProps={{
              autoComplete: 'new-password',
              form: {
                autoComplete: 'off',
              },
              readOnly: !isEditing,
            }}
            size="small"
          />
        </Grid>
      </Grid>
      <Box sx={{ height: 12 }} />
    </>
  );
}
