import React, { useState } from 'react';
// MUI
import {
  Box,
  Tab, Tabs,
} from '@mui/material';
import {
  DescriptionTwoTone,
} from '@mui/icons-material';
// STORES
// CONST & UTIL
// APIS
import COLORS from 'constants/COLORS';
import _ from 'lodash';
import AttachmentViewerFile from 'components/shared/AttachmentViewer.js/AttachmentViewerFile';

export default function RecordNanoFiles({
  data,
}) {
  if (!data?.files) return <Box />;

  const filesArr = _.filter(_.map(_.toPairs(data.files), ([key, value]) => ({
    key: `${data?.record_number}_${key}`,
    url: value?.url,
  })), (file) => file?.url != null);

  if (!filesArr?.length) return <Box />;

  const [showAttachment, setShowAttachment] = useState(filesArr[0].url);

  // -------------------------------------------------------------------------------------------------------------------------------------------------

  return (
    <Box sx={{
      backgroundColor: '#fff',
      borderRadius: '8px 8px 0 0',
      boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
      width: '100%',
    }}
    >
      <Tabs
        value={showAttachment}
        onChange={(e, newValue) => setShowAttachment(newValue)}
        variant="scrollable"
        data-cy="invoice_receipt_tab"
        sx={{
          minHeight: 50,
          width: '100%',
        }}
        TabIndicatorProps={{
          style: {
            backgroundColor: COLORS.ara3,
          },
        }}
        scrollButtons
        allowScrollButtonsMobile
      >
        {filesArr.map((file, idx) => (
          <Tab
            key={file.key}
            label={`Attachment ${idx + 1}`}
            value={file.url}
            iconPosition="start"
            icon={<DescriptionTwoTone sx={{ fontSize: 'inherit' }} />}
            sx={{
              textTransform: 'none',
              fontSize: 14,
              minHeight: 50,
              '&.Mui-selected': {
                color: COLORS.ara3,
                fontWeight: 'bold',
              },
              minWidth: 'fit-content',
              flex: 1,
              maxWidth: 'none',

            }}
          />
        ))}

      </Tabs>

      <Box sx={{ px: 6, pb: 6 }}>
        <AttachmentViewerFile url={showAttachment} fileName={filesArr.find((f) => f.url === showAttachment)?.key} />
      </Box>
    </Box>

  );
}
