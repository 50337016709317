import React from 'react';
// MUI
import {
  Dialog, DialogTitle,
  Button,
  DialogActions, Divider,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import './style.css';

import { useStoreShared } from 'store/shared-store';

import AttachmentViewerFile from './AttachmentViewerFile';

export default function AttachmentViewerDialog() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { attachmentViewerUrlStore, setAttachmentViewerUrlStore } = useStoreShared();

  const handleClose = () => {
    setAttachmentViewerUrlStore(null);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={attachmentViewerUrlStore != null}
      onClose={handleClose}
      scroll="paper"
      fullWidth
      maxWidth="md"

    >
      {attachmentViewerUrlStore != null && (
        <>
          <DialogTitle>Viewing Attachment File</DialogTitle>
          <Divider />
          <AttachmentViewerFile {...attachmentViewerUrlStore} />
          <Divider />
          <DialogActions sx={{ p: 2 }}>
            <Button onClick={handleClose} size="small" variant="contained" color="neutral">Close</Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
