/* eslint-disable max-len */
import React, { useState } from 'react';
import { enqueueSnackbar } from 'notistack';

// COMPONENTS

import AlertDialog from 'components/shared/Dialogs/AlertDialog';

// STORES
import { useStoreCust } from 'store/cust-mgmt-store';

// APIS

import {
  useMutationUpdateOneCustomerByCustomerId,
} from 'services/customer-api-services';

// MUI
import {
  Box,
  Button,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';
import {
  DoNotDisturb,
  NoteAlt,
  NotificationsActiveTwoTone,
} from '@mui/icons-material';

// UTIL & CONST
import ActionButton from 'components/shared/Input/ActionButton';
import { useStoreAuth } from 'store/auth-store';
import { useNavigate, useParams } from 'react-router-dom';
import { IOSSwitch } from 'components/shared/styled-component';
import { PATH_USER } from 'constants/PATHS';
import { useQueryClient } from '@tanstack/react-query';

export default function CustomerDetailActions({
  customer, setIsEditing,
}) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { status } = useParams();

  const { businessProfileStore } = useStoreAuth();
  const { removeOneCustomersStoreById } = useStoreCust();

  const [isOpenAlertDialog, setIsOpenAlertDialog] = useState(false);

  const {
    mutIsPending,
    triggerMut,
    asyncTriggerMut,
  } = useMutationUpdateOneCustomerByCustomerId(customer._id);

  const emailNoti = customer?.notification_settings?.email ?? businessProfileStore?.notification_settings?.email ?? null;
  const smsNoti = customer?.notification_settings?.sms ?? businessProfileStore?.notification_settings?.sms ?? null;
  const waNoti = customer?.notification_settings?.whatsapp ?? businessProfileStore?.notification_settings?.whatsapp ?? false;

  const getActionButtonLists = () => {
    const list = [];

    list.push({
      dataCy: 'edit-customer-btn',
      icon: <NoteAlt fontSize="small" color="primary" />,
      label: 'Edit details',
      onClick: () => {
        setIsEditing(true);
      },
    });

    if (smsNoti != null && emailNoti != null) {
      list.push({
        custom:
          // eslint-disable-next-line react/jsx-indent
          <>
            <ListItem>
              <ListItemIcon sx={{ mr: 1.5, minWidth: 0 }}>
                <NotificationsActiveTwoTone fontSize="small" color="primary" />
              </ListItemIcon>
              <ListItemText primary="Notifications" secondary={customer?.notification_settings == null ? 'Using default business settings' : ''} />
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ mr: 1.5, minWidth: 0 }}>
                <Box sx={{ width: 20 }} />
              </ListItemIcon>
              <ListItemText primary="Email notification" />
              <Tooltip title="Switch on/off the email reminders for this customer" arrow>
                <span>
                  <IOSSwitch
                    data-cy="customer-email-notification"
                    checked={emailNoti}
                    onClick={(e) => {
                      const body = {
                        notification_settings: {
                          whatsapp: waNoti,
                          sms: smsNoti,
                          email: e.target.checked,
                        },
                      };

                      triggerMut(body, {
                        onSuccess: () => {
                          queryClient.invalidateQueries({
                            queryKey: ['getOneCustomerByBizIdAndCustId'],
                          });
                        },
                      });
                    }}
                    disabled={mutIsPending}
                  />
                </span>
              </Tooltip>
            </ListItem>
            <ListItem>
              <ListItemIcon sx={{ mr: 1.5, minWidth: 0 }}>
                <Box sx={{ width: 20 }} />
              </ListItemIcon>
              <ListItemText primary="SMS notification" />
              <Tooltip title="Switch on/off the sms reminders for this customer" arrow>
                <span>
                  <IOSSwitch
                    data-cy="customer-sms-notification"
                    checked={smsNoti}
                    onClick={(e) => {
                      const body = {
                        notification_settings: {
                          whatsapp: waNoti,
                          sms: e.target.checked,
                          email: emailNoti,
                        },
                      };

                      triggerMut(body, {
                        onSuccess: () => {
                          queryClient.invalidateQueries({
                            queryKey: ['getOneCustomerByBizIdAndCustId'],
                          });
                        },
                      });
                    }}
                    disabled={mutIsPending}
                  />
                </span>
              </Tooltip>
            </ListItem>
          </>,
        dataCy: 'notifications',
      });
    }

    list.push({
      dataCy: 'deactivate-customer-btn',
      icon: <DoNotDisturb fontSize="small" color="error" />,
      label: customer.is_active ? 'Deactivate' : 'Reactivate',
      color: 'red',
      onClick: () => {
        setIsOpenAlertDialog(true);
      },
    });

    return list;
  };

  const handleDeactivateCustomer = async () => {
    const cust = await asyncTriggerMut({ is_active: false });

    if (!cust) return;
    enqueueSnackbar('Succesfully deactivated customer. Customer is moved to the Inactive Section', { variant: 'success' });
    removeOneCustomersStoreById(cust._id);
    setIsOpenAlertDialog(false);
    navigate(PATH_USER.customers.root(status));
  };

  const handleReactivateCustomer = async () => {
    const cust = await asyncTriggerMut({ is_active: true });

    if (!cust) return;

    enqueueSnackbar('Succesfully re-activated customer. Customer is moved to the Active Section', { variant: 'success' });
    removeOneCustomersStoreById(cust._id);
    setIsOpenAlertDialog(false);
    navigate(PATH_USER.customers.root(status));
  };

  return (
    <Grid item xs={12} md="auto">
      <AlertDialog
        open={isOpenAlertDialog}
        setOpen={setIsOpenAlertDialog}
        isLoading={mutIsPending}
        title={customer?.is_active ? 'Confirm Deactivate Customer?' : 'Confirm Reactivate Customer?'}
        message={customer?.is_active
          ? 'If you choose to deactivate the payer, you\'ll lose the ability to create invoices for this customer. However, any existing invoices will remain accessible.'
          : 'If you opt to reactivate the payer, you\'ll regain the ability to create invoices for them.'}
        actions={[
          {
            text: customer.is_active ? 'Deactivate Customer' : 'Reactivate Customer',
            onClick: () => {
              if (customer.is_active) {
                handleDeactivateCustomer();
              } else {
                handleReactivateCustomer();
              }
            },
          },
          {
            text: 'Cancel',
            variant: 'contained',
            color: 'tertiary',
            onClick: () => {
              setIsOpenAlertDialog(false);
            },
          },
        ]}
      />
      <Box
        height="100%"
        display="flex"
        alignItems="center"
        flexDirection="row"
      >
        {customer?.is_active
          && (
            <Button
              variant="contained"
              color="tertiary"
              size="small"
              disableElevation
              sx={{ mr: 2 }}
              onClick={() => {
                navigate(`${PATH_USER.draftinvoices.create}?customerId=${customer._id}`);
              }}
            >
              New Invoice
            </Button>
          )}
        <ActionButton lists={getActionButtonLists()} variant="outlined" color="primary" />
      </Box>
    </Grid>
  );
}
