// React imports
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Material-UI imports
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
  styled,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// Forms
import { Form, FormikProvider, useFormik } from 'formik';

// Constants & Utils
import COLORS from 'constants/COLORS';
import { DraftInvoiceFormikInitialValues } from 'util/DraftInvoice/draft-invoice-formik-initial-values';
import { DraftInvoiceSchema } from 'util/DraftInvoice/draft-invoice-validation';

// Components
import ResponsiveNanoWrapper from 'components/shared/ResponsiveNanoWrapper/ResponsiveNanoWrapper';
import DraftRecordInputCustomer from 'components/DraftRecord/DraftRecordInput/DraftRecordInputCustomer/DraftRecordInputCustomer';
import DraftRecordInputInvoice from 'components/DraftRecord/DraftRecordInput/DraftRecordInputInvoice';
import DraftRecordInputFile from 'components/DraftRecord/DraftRecordInput/DraftRecordInputFile';

// Validation and Notification
import { cloneDeep } from 'lodash';
import { RecordUpdateOneDraftAPI, useQueryGetOneRecord } from 'services/record-api-services';
import { enqueueSnackbar } from 'notistack';

import MicroEmpty from 'components/shared/Micro/MicroEmpty';
import { PATH_USER } from 'constants/PATHS';

export default function DraftRecordEditScreen() {
  const { recordId } = useParams();

  const {
    isLoading, error, data: invoice, refetch, isRefetchError, isRefetching,
  } = useQueryGetOneRecord(recordId);

  if (isLoading || isRefetching) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress size={20} sx={{ mt: 4 }} />
      </Box>
    );
  }

  if (error || !invoice || isRefetchError) {
    return (
      <MicroEmpty
        title="Oops! Couldn't retrieve invoice. Please try again."
        onClick={() => {
          refetch();
        }}
      />
    );
  }

  return <Screen invoice={invoice} />;
}

function Screen({ invoice }) {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: DraftInvoiceFormikInitialValues(invoice),
    validationSchema: DraftInvoiceSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const reqPayload = cloneDeep(values);
      delete reqPayload.processable_value;
      delete reqPayload.file;

      const [status, data] = await RecordUpdateOneDraftAPI(invoice._id, reqPayload, values?.file || null);
      setSubmitting(false);
      if (!status || !data) return;

      enqueueSnackbar('Draft invoice updated.', { variant: 'success' });
      navigate(PATH_USER.draftinvoices.root);
      setSubmitting(false);
    },
  });

  const handleClose = () => {
    navigate(PATH_USER.draftinvoices.root);
  };

  const { handleSubmit, isSubmitting } = formik;

  return (

    <ResponsiveNanoWrapper
      isShowCloseButton={false}
      isSmallScreenOpen
      handleOnCloseSmallScreen={handleClose}
      handleOnOpenSmallScreen={() => { }}
    >

      <Box sx={{ p: 4 }}>

        <FormikProvider value={formik}>

          <Form
            autoComplete="on"
            noValidate
            onSubmit={handleSubmit}
          >
            <Container maxWidth="md" disableGutters sx={{ mb: 4 }}>
              <Grid container spacing={2}>
                <Grid item xs>
                  <Typography variant="h5" fontWeight={'bold'}>Update One Draft Invoice</Typography>
                </Grid>
                <Grid item xs="auto">
                  <LoadingButton
                    type="button"
                    size="small"
                    color="neutral"
                    sx={{ mr: 2 }}
                    onClick={handleClose}
                  >
                    Cancel
                  </LoadingButton>
                  <LoadingButton
                    type="submit"
                    size="small"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    Save Changes
                  </LoadingButton>
                </Grid>
              </Grid>
            </Container>
            <NanoPaperContainer
              maxWidth="md"
              disableGutters
            >

              <DraftRecordInputCustomer formik={formik} />
              <Box sx={{ height: 40 }} />
              <DraftRecordInputInvoice formik={formik} />
              <Box sx={{ height: 40 }} />
              <DraftRecordInputFile formik={formik} editInvoice={invoice} />
            </NanoPaperContainer>
          </Form>
        </FormikProvider>
      </Box>
    </ResponsiveNanoWrapper>

  );
}

const NanoPaperContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(0),
  background: '#fff',
  borderRadius: 2,
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    border: `1px solid ${COLORS.divider}`,
    boxShadow: '0 8px 10px rgba(112,144,176,0.2)',
    padding: theme.spacing(4),
  },

}));
