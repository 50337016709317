const FONT_SIZE = {
  display: '28px',
  header: '22px',
  headline: '18px',
  title: '16px',
  body: '13px',
  body2: '12px',
  caption: '11px',
  hint: '10px',
};

export default FONT_SIZE;
