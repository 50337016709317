import { useMutation, useQuery } from '@tanstack/react-query';
import { merge } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { useStoreAuth } from 'store/auth-store';
// STORES
import { getAPI, getSecurePayeeAPI } from 'util/API';
import { getErrMessageUtil } from 'util/util';

export const CustomerCreateOneByBusinessIdAPI = async (body) => {
  const { businessProfileStore } = useStoreAuth.getState();

  const API = getSecurePayeeAPI('user');
  const url = '/customer/create/one';

  try {
    const finalBody = { ...body, business_id: businessProfileStore._id };

    const res = await API.post(url, finalBody);
    const { data } = res.data;
    return [true, data?.customer];
  } catch (err) {
    const errMessage = getErrMessageUtil(err);
    enqueueSnackbar(errMessage, { variant: 'error' });
    return [null, errMessage];
  }
};

export const CustomerGetManyByBusinessIdAPI = async (query = null) => {
  const { businessProfileStore } = useStoreAuth.getState();
  const API = getSecurePayeeAPI('user');

  try {
    const defaultQuery = {
      isActive: true,
      pageSize: 25,
      search: '',
      pageNumber: 1,
      userType: 'all',
    };

    const params = merge(defaultQuery, query || {});

    const url = `/customer/get/many/byBusiness/${businessProfileStore._id}`;

    if (params?.userType === 'all') {
      delete params.userType;
    }

    const res = await API.get(url, { params });
    const { data } = res.data;
    return [true, data];
  } catch (err) {
    const errMessage = getErrMessageUtil(err);
    enqueueSnackbar(errMessage, { variant: 'error' });
    return [null, errMessage];
  }
};

export const useQueryGetOneCustomerByBizIdAndCustId = (customerId) => {
  const { businessProfileStore } = useStoreAuth.getState();
  const businessId = businessProfileStore?._id;
  const API = getAPI('user');
  const queryKey = ['getOneCustomerByBizIdAndCustId', businessId, customerId];

  const {
    isLoading, error, data: customer, refetch, isRefetchError, isRefetching, ...others
  } = useQuery({
    queryKey,
    queryFn: () => API.get(`/customer/get/one/byBusiness/${businessId}/${customerId}`)
      .then((res) => res?.data?.data?.customer || null)
      .catch((err) => {
        throw new Error(err); // Rethrow the error so React Query can catch it
      }),
    enabled: !!customerId && !!businessId,
  });

  return {
    isLoading, error, customer, refetch, isRefetchError, isRefetching, ...others,
  };
};

export const useMutationUpdateOneCustomerByCustomerId = (customerId) => {
  const API = getAPI('user');

  const {
    data: mutData,
    error: mutError,
    isPending: mutIsPending,
    mutate: triggerMut,
    mutateAsync: asyncTriggerMut,
    reset: resetMut,
    status: mutStatus,
    ...othersMut
  } = useMutation({
    mutationFn: (body) => API.put(`/customer/update/one/${customerId}`, body)
      .then((res) => res?.data?.data?.customer || null)
      .catch((err) => {
        const errMessage = getErrMessageUtil(err);
        enqueueSnackbar(errMessage, { variant: 'error' });
        throw new Error(errMessage); // Rethrow the error so React Query can catch it
      }),

  });

  return {
    mutData,
    mutError,
    mutIsPending,
    triggerMut,
    asyncTriggerMut,
    resetMut,
    mutStatus,
    ...othersMut,
  };
};
