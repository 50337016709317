/* eslint-disable no-param-reassign */
import axios from 'axios';
import CONFIG from 'constants/CONFIG';
import { useStoreAuth } from 'store/auth-store';

const getFullApiURL = (serviceType) => {
  const upperST = serviceType.toUpperCase();
  const name = `${upperST}_SERVICE_URL`;

  const url = CONFIG.API[process.env.REACT_APP_ENV][`${name}`];

  const path = '/api/v2/payee';

  return `${url}${path}`;
};

export const getAPI = (serviceType = 'auth', timeout = 20000) => {
  const URL = getFullApiURL(serviceType);

  const sessionToken = localStorage.getItem('session_token') || useStoreAuth.getState().sessionToken || null;
  const api = axios.create({
    baseURL: URL,
    timeout,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionToken}`,
    },
  });

  api.CancelToken = axios.CancelToken;
  api.isCancel = axios.isCancel;

  return api;
};

export const getSecurePayeeAPI = (serviceType = 'auth', timeout = 20000) => getAPI(serviceType, timeout);
