// React
import * as React from 'react';

// MUI components
import {
  Drawer,
  List,
  styled,
  Toolbar,
} from '@mui/material';

// MUI icons
import {
  DescriptionTwoTone,
  DrawTwoTone,
  PeopleAltTwoTone,
  SettingsSuggestTwoTone,
} from '@mui/icons-material';

// Constants and utilities
import LAYOUT from 'constants/LAYOUT';

// Components
import SideBarDrawerItems from './SideBarDrawerItems';
import { useQueryGetRecordMetadata } from 'services/record-api-services';

import { PATH_USER } from 'constants/PATHS';

const { APP_BAR_HEIGHT, DRAWER_WIDTH } = LAYOUT;

export default function SideBarDrawer({ isDrawerOpen, isSmallScreen, handleDrawer }) {
  // -------------------------------------------------------------------------------------------------------------------------------------------------

  const {
    data: rms,
  } = useQueryGetRecordMetadata();

  const DRAWER_MENUS = React.useMemo(() => [

    {
      icon: <DescriptionTwoTone />,
      title: 'Invoices',
      to: '/user/invoices',
      subItems: [
        {
          name: `Active (${rms?.active ?? '-'})`,
          to: '/user/invoices/active',
          type: 'screen',
        },
        {
          name: `Not Due (${rms?.not_due ?? '-'})`,
          to: '/user/invoices/not_due',
          type: 'screen',
        },
        {
          name: `Overdue (${rms?.overdue ?? '-'})`,
          to: '/user/invoices/overdue',
          type: 'screen',
        },
        {
          name: 'Divider 1',
          to: '/',
          type: 'divider',
        },
        {
          name: `Paid (${rms?.paid ?? '-'})`,
          to: '/user/invoices/paid',
          type: 'screen',
        },
        {
          name: `Voided (${rms?.voided ?? '-'})`,
          to: '/user/invoices/voided',
          type: 'screen',
        },
      ],
    },
    {
      icon: <DrawTwoTone />,
      title: `Draft Invoices (${rms?.draft ?? '-'})`,
      to: PATH_USER.draftinvoices.root,
    },
    {
      icon: <PeopleAltTwoTone />,
      title: 'Customers',
      to: '/user/customer',
      subItems: [
        {
          name: 'Active',
          to: '/user/customers/active',
          type: 'screen',
        },
        {
          name: 'Inactive',
          to: '/user/customers/inactive',
          type: 'screen',
        },
      ],
    },
    {
      icon: <SettingsSuggestTwoTone />,
      title: 'Settings',
      to: '/user/settings',
    },
  ], [rms]);

  // -------------------------------------------------------------------------------------------------------------------------------------------------

  return (
    <Drawer
      sx={{
        width: DRAWER_WIDTH,
        flexShrink: 0,

        '& .MuiDrawer-paper': {
          width: DRAWER_WIDTH,
          boxSizing: 'border-box',
          boxShadow: 'none',
        },
      }}
      variant={isSmallScreen ? 'temporary' : 'persistent'}
      onClose={handleDrawer}
      anchor="left"
      open={isDrawerOpen}
    >
      <AraToolbar />
      <List sx={{ py: 0 }}>
        {DRAWER_MENUS.map((item) => (
          <SideBarDrawerItems
            item={item}
            key={item.title}
            isSmallScreen={isSmallScreen}
            handleDrawer={handleDrawer}
          />
        ))}
      </List>
    </Drawer>

  );
}

const AraToolbar = styled(Toolbar)(() => ({
  height: APP_BAR_HEIGHT + 0,
}));
