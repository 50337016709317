import * as Yup from 'yup';

export const DraftInvoiceSchema = () => Yup.object().shape({
  customer_id: Yup.string().required('Customer is required'),
  record_number: Yup
    .string()
    .required('Invoice Number is required'),
  description: Yup
    .string()
    .required('Description is required'),
  issue_date: Yup
    .date('Issued On date is invalid')
    .typeError('Issued On date is invalid')
    .required('Issued on date is required'),
  due_date: Yup
    .date('Please enter a valid due date')
    .typeError('Due Date is invalid')
    .required('Due Date is required')
    .test(
      'is-due-date-valid',
      'The due date must be in the future relative to the issue date',
      (value, context) => {
        const dueDate = new Date(value);
        const issueDate = new Date(context.parent.issue_date);

        if (issueDate > dueDate) {
          return false;
        }

        return true;
      },
    ),
  total_amount: Yup
    .number()
    .required('Total Invoice Value is required')
    .min(15, 'Total Invoice Value should be at least MYR 15.00'),
  previous_payment_amount: Yup
    .number()
    .min(0, 'Minimum is 0'),
  processable_value: Yup
    .number()
    .required('Amount Due is required')
    .min(15, 'Amount Due should be at least MYR 15.00')
    .max(999999, 'Amount Due should not be more than MYR 999,999.00'),
  interest_rate: Yup
    .number()
    .required('Interest Rate is required')
    .min(0, 'Minimum is 0'),
});
