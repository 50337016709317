/* eslint-disable max-len */
import React, { useState } from 'react';
// FORMS
import {
  setNestedObjectValues,
} from 'formik';
import {
  Autocomplete,
  Box,
  Button,
  Collapse,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel, MenuItem, Select, TextField, Tooltip, Typography,
} from '@mui/material';
import { ExpandLess, ExpandMore, HelpTwoTone } from '@mui/icons-material';

export default function CustomerInputBusinessDetail({
  formik, isEditing = true,
}) {
  const {
    errors, touched, setFieldValue, getFieldProps,
  } = formik;

  const [isCollapse, setIsCollapse] = useState(false);

  return (
    <>
      <Grid container spacing={2} sx={{ p: 0 }}>
        <Grid item xs={6} sm={6}>
          <Typography variant="body1" sx={{ fontWeight: 700 }}>Business Details</Typography>
        </Grid>
        <Grid item xs={6} sm={6}>
          <Box
            sx={{ height: '100%' }}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            flexDirection="row"
          >
            <Button
              color="neutral"
              size="small"
              data-cy="cust-business-detail-collapse-btn"
              sx={{ ml: 2 }}
              onClick={() => {
                setIsCollapse(!isCollapse);
              }}
              tabIndex={-1}
              startIcon={isCollapse ? <ExpandMore /> : <ExpandLess />}
            >
              {isCollapse ? 'Show' : 'Hide'}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Collapse in={!isCollapse} timeout="auto">
        <Box sx={{ height: 24 }} />
        <FormControl fullWidth size="small">
          <InputLabel>Type of Customer</InputLabel>
          <Select
            value={getFieldProps('user_type').value}
            onChange={(e) => {
              setFieldValue('business_name', '');
              setFieldValue('business_short_name', '');
              setFieldValue('business_type', '');
              formik.setTouched(setNestedObjectValues(errors, false));
              setFieldValue('user_type', e.target.value || '');
            }}
            label="Type of Customer"
          >
            <MenuItem value="individual">Individual</MenuItem>
            <MenuItem value="business">Company / Organization</MenuItem>
          </Select>
        </FormControl>
        {getFieldProps('user_type')?.value === 'business' && (
          <>
            <Box sx={{ height: 24 }} />
            <Grid container spacing={4} data-cy="create-customer-grid-business_name">
              <Grid item md={7} xs={12}>
                <TextField
                  data-cy="business_name"
                  fullWidth
                  label={'Business Name'}
                  {...getFieldProps('business_name')}
                  error={Boolean(touched.business_name && errors.business_name)}
                  helperText={touched.business_name && errors.business_name}
                  size="small"
                  InputProps={{
                    autoComplete: 'new-password',
                    form: {
                      autoComplete: 'off',
                    },
                    readOnly: !isEditing,
                  }}
                />
              </Grid>
              <Grid item md={5} xs={12}>
                <TextField
                  data-cy="business_short_name"
                  fullWidth
                  label={'Business Short Name'}
                  {...getFieldProps('business_short_name')}
                  error={Boolean(touched.business_short_name && errors.business_short_name)}
                  helperText={touched.business_short_name && errors.business_short_name}
                  size="small"
                  InputProps={{
                    autoComplete: 'new-password',
                    form: {
                      autoComplete: 'off',
                    },
                    readOnly: !isEditing,
                    endAdornment: <InputAdornment position="end" sx={{ cursor: 'pointer' }}><Tooltip title={'This short name allows to keep our SMS messages as short as possible. For example “Blue Space Technology & Friends” their short name could be “Blue Space”."'} placement="top"><HelpTwoTone fontSize="small" color="primary" /></Tooltip></InputAdornment>,
                  }}
                />
              </Grid>
            </Grid>
            <Box sx={{ height: 24 }} />
            <Autocomplete
              fullWidth
              freeSolo
              options={['Sole Proprietorship', 'Partnership', 'Corporation', 'Limited Liability']}
              value={getFieldProps('business_type').value}
              onInputChange={(_e, value) => {
                setFieldValue('business_type', value || '');
              }}
              size="small"
              disabled={!isEditing}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                    form: {
                      autoComplete: 'off',
                    },
                    readOnly: !isEditing,
                  }}
                  error={Boolean(touched.business_type && errors.business_type)}
                  helperText={touched.business_type && errors.business_type}
                  label={'Business Type'}
                  data-cy="business_type"
                />
              )}
            />
          </>
        )}
      </Collapse>
    </>
  );
}
