import React from 'react';
import {
  Box,
  Checkbox,
  Grid, styled, Typography,
} from '@mui/material';
import LAYOUT from 'constants/LAYOUT';
import { formatAmountUtil, formatDateUtil } from 'util/util';
import {
  AccessTime, Business, DescriptionOutlined, PersonOutlineOutlined,
} from '@mui/icons-material';
import CustomAmountIcon from 'components/shared/Icons/CustomAmountIcon';
import COLORS from 'constants/COLORS';
import { useStoreDraftRecord } from 'store/draft-record-store';

export default function DraftRecordMicroListBox({ record }) {
  const { selectedDraftRecordIdsStore, addOrRemoveSelectedDraftRecordIdsStore } = useStoreDraftRecord();

  const selected = selectedDraftRecordIdsStore.includes(record._id);

  const onClick = () => {
    addOrRemoveSelectedDraftRecordIdsStore(record._id);
  };

  return (
    <ListBox
      selected={selected}
      onClick={onClick}
      sx={{ display: 'flex' }}
    >
      <Checkbox sx={{ pt: 0, px: 0, mr: 1 }} color="primary" checked={selected} size="small" />
      <Grid
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        container
        sx={{ width: 'calc(100% - 38px)' }}
      >
        <Grid xs={6} item sx={{ alignItems: 'center', display: 'flex' }}>
          {record?.customer?.user_type === 'individual'
            ? <PersonOutlineOutlined sx={{ fontSize: 13.5, mr: 1 }} /> : <Business sx={{ fontSize: 13.5, mr: 1 }} />}
          <Typography
            variant="body1"
            noWrap
            sx={{
              fontWeight: selected ? 700 : 600,
            }}
          >
            {record?.customer?.business_name || `${record?.customer?.first_name || ''} ${record?.customer?.last_name || ''}`}
          </Typography>
        </Grid>
        <Grid xs={6} item sx={{ alignItems: 'center', display: 'flex', justifyContent: 'flex-start' }}>
          <DescriptionOutlined sx={{ fontSize: 13.5, mr: 1 }} />
          <Typography
            variant="body2"
            noWrap
            sx={{
              fontWeight: selected ? 600 : 500,
            }}
          >
            {record?.record_number}

          </Typography>
        </Grid>
        <Grid xs={6} item sx={{ alignItems: 'center', display: 'flex' }}>
          <CustomAmountIcon sx={{ fontSize: 13.5, mr: 1 }} />
          <Typography
            variant="body2"
            noWrap
            sx={{
              fontWeight: selected ? 600 : 500,
            }}
          >
            {`${record?.currency} ${formatAmountUtil(record?.amount?.processable_value)}`}
          </Typography>
        </Grid>
        <Grid xs={6} item sx={{ alignItems: 'center', display: 'flex', justifyContent: 'flex-start' }}>
          <AccessTime sx={{ fontSize: 13.5, mr: 1 }} />
          <Typography
            variant="body2"
            noWrap
            sx={{
              fontWeight: selected ? 600 : 500,
            }}
          >
            {formatDateUtil(record?.date?.due_date)}
          </Typography>
        </Grid>
      </Grid>
    </ListBox>
  );
}
const ListBox = styled(
  Box,
  { shouldForwardProp: (prop) => prop !== 'selected' },
)(({ theme, selected }) => ({
  background: selected ? COLORS.primaryTertiary : '#fff',
  border: '1px solid rgba(112,144,176,0.2)',
  cursor: 'pointer',
  display: '-webkit-box',
  height: LAYOUT.MICRO_LIST_BOX_HEIGHT,
  padding: theme.spacing(1),
  position: 'relative',
  width: '100%',
}));
