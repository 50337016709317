import React, { useEffect } from 'react';
import {
  Grid, IconButton, styled, SwipeableDrawer, Toolbar, useMediaQuery, useTheme,
} from '@mui/material';
import COLORS from 'constants/COLORS';
import LAYOUT from 'constants/LAYOUT';
import { Close } from '@mui/icons-material';
import { useStoreShared } from 'store/shared-store';

export default function ResponsiveNanoWrapper({
  isShowCloseButton = true,
  handleOnCloseSmallScreen,
  handleOnOpenSmallScreen = () => { },
  children,
}) {
  const theme = useTheme();
  const isMdDownScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { resetSharedModalStore } = useStoreShared();

  // For Mobile Back Button to increase user experience
  useEffect(() => {
    window.onpopstate = (e) => {
      if (e.state != null && isMdDownScreen) {
        handleOnCloseSmallScreen(false);
        resetSharedModalStore();
      }
    };
  });

  const handleOnClose = () => {
    window.history.back();
  };

  return isMdDownScreen ? (
    <SwipeableDrawer
      anchor="right"
      open
      onClose={handleOnClose}
      onOpen={handleOnOpenSmallScreen}
      sx={styles.FullDrawerStyle}
    >
      {isShowCloseButton && <CloseButton onClick={handleOnClose}><Close /></CloseButton>}
      <Toolbar sx={{ height: { xs: `${LAYOUT.APP_BAR_HEIGHT + 10}px`, sm: `${LAYOUT.APP_BAR_HEIGHT}px` } }} />
      {children}
    </SwipeableDrawer>
  ) : (
    <NanoGrid item xs sx={{ overflow: 'auto' }}>
      {children}
    </NanoGrid>
  );
}

const styles = {
  FullDrawerStyle: {
    '& .MuiDrawer-paper': {
      width: '100%',
      maxWidth: 'md',
    },
  },
  DrawerStyle: {
    '& .MuiDrawer-paper': {
      width: '85%',
      maxWidth: 'sm',
    },
  },
};

const NanoGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: COLORS.secondaryBG,
  height: `calc(100vh - ${LAYOUT.APP_BAR_HEIGHT}px)`,
  overflowY: 'auto',
  // padding: theme.spacing(4, 4),
  margin: theme.spacing(0),
}));

const CloseButton = styled(IconButton)(() => ({
  position: 'fixed',
  zIndex: 2,
  top: LAYOUT.APP_BAR_HEIGHT + 5,
  right: 15,
  background: '#fff',
  borderRadius: '50%',
}));
