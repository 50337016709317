const COLORS = {
  // Primary Color
  primary: '#1A519C',
  primaryLight: '#0a64bc14',
  primaryTertiary: '#DFE4F2',

  mainBG: '#ffffff',
  secondaryBG: '#F9FAFB',
  divider: '#e3e8ee',

  labelPrimary: '#000',
  labelSecondary: '#637381',

  // Constant
  disabled: 'rgba(89,105,216,0.5)',
  default: '#1a1f36',
  gray2: '#3c4257',
  gray1: '#4f566b',
  gray0: '#697386',
  gray99: 'rgba(105,115,134,.5)',

  ara0: '#FED138',
  ara1: '#FBB71D',
  ara2: '#F89C25',
  ara3: '#F4802C',

  cyan: '#027cb9',

  // BADGE
  green: '#1a6139',
  greenLight: '#cbf4c9',
  yellow: '#923d0a',
  yellowLight: '#f4e7b5',
  red: '#9a2042',
  redLight: '#fee4df',
  blueLight: '#dbebff',
  badgeGrey: '#4f566b',
  badgeGreyLight: '#e3e8ee',
};

export default COLORS;
