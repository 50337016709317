import isMobilePhone from 'validator/lib/isMobilePhone';
import * as Yup from 'yup';

export const CustomerSchema = () => {
  const custObj = {
    user_type: Yup
      .string()
      .required('User Type is required')
      .oneOf(['individual', 'business'], 'User Type must be individual or business'),
    email: Yup
      .string()
      .email('Please enter a valid email address')
      .nullable(),
    phone_number: Yup
      .string()
      .test(
        'is-mobile-number',
        'Please fill in a valid SMS mobile phone number',
        (value) => (value ? isMobilePhone(value) : true),
      )
      .nullable(),
    first_name: Yup
      .string()
      .required('First Name is required')
      .min(2, 'First name should be more than 2 characters')
      .max(50, 'First name should be less than 50 characters'),
    last_name: Yup
      .string()
      .required('Last Name is required')
      .min(2, 'Last name should be more than 2 characters')
      .max(50, 'Last name should be less than 50 characters'),
    country_code: Yup
      .string()
      .length(2, 'Country is required')
      .required('Country is required'),
    office_number: Yup
      .string()
      .notRequired()
      .test(
        'is-mobile-number',
        'Please fill in a valid SMS mobile phone number',
        (value) => (value ? isMobilePhone(value) : true),
      ),
    address_line_1: Yup
      .string()
      .notRequired(),
    address_line_2: Yup
      .string()
      .notRequired(),
    city: Yup
      .string()
      .notRequired(),
    state: Yup
      .string()
      .notRequired(),
    postcode: Yup
      .string()
      .notRequired(),
    short_name: Yup
      .string()
      .when('user_type', {
        is: 'individual',
        then: Yup.string().required('Short Name is required for individual users'),
        otherwise: Yup.string().notRequired(),
      }).min(2, `Short Name should be at least ${2} characters`)
      .max(20, `Please keep the Short Name under ${20} characters`),
    business_name: Yup
      .string()
      .when('user_type', {
        is: 'business',
        then: Yup.string().required('Business Name is required for business users'),
        otherwise: Yup.string().notRequired(),
      }),
    business_short_name: Yup
      .string()
      .when('user_type', {
        is: 'business',
        then: Yup.string().required('Business Short Name is required for business users'),
        otherwise: Yup.string().notRequired(),
      })
      .min(2, `Business Short Name should be at least ${2} characters`)
      .max(20, `Please keep the Business Short Name under ${20} characters`),
    additional_contacts: Yup.array(
      Yup.object({
        email: Yup.string()
          .email('Please enter a valid email address')
          .when('phone_number', {
            is: (val) => !val || val.trim() === '', // Check if phone_number is empty or only whitespace
            then: Yup.string().required('Either Email or Phone Number is required'),
            otherwise: Yup.string().notRequired(),
          }),
        first_name: Yup.string()
          .required('First Name is required')
          .min(2, 'First name should be more than 2 characters')
          .max(50, 'First name should be less than 50 characters'),
        last_name: Yup.string()
          .required('Last Name is required')
          .min(2, 'Last name should be more than 2 characters')
          .max(50, 'Last name should be less than 50 characters'),
        phone_number: Yup.string().notRequired(),
      }),
    ),

  };

  return Yup.object(custObj).test({
    name: 'either-email-or-phone-required',
    test(values) {
      const { email, phone_number: phoneNumber } = values;
      if (!email && !phoneNumber) {
        return this.createError({
          path: 'email',
          message: 'Either Email or Phone Number is required',
        });
      }
      return true;
    },
  });
};
