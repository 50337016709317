// REACT + OTHERS
import React from 'react';
import { Outlet } from 'react-router-dom';

// MUI
import { styled } from '@mui/material/styles';
import {
  Container,
} from '@mui/material';

// COMPONENTS
import AuthLayoutBanner from './AuthLayoutBanner';

export default function AuthLayout() {
  return (
    <AuthPage>
      <AuthLayoutBanner />
      <AuthContainer maxWidth="sm">
        <AuthCard>
          <CompanyLogo
            alt="company"
            src="/images/ara-app-logo.webp"
            style={{ height: '35px', width: '140px' }}
          />
          <Outlet />
        </AuthCard>
      </AuthContainer>
    </AuthPage>
  );
}

const AuthPage = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));
const AuthContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    height: '97.5vh',
  },
}));

const AuthCard = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

const CompanyLogo = styled('img')(() => ({

  marginBottom: 30,
}));
