import {
  Backup, FolderSharp, Help,
} from '@mui/icons-material';
import {
  Box, Button, Typography,
} from '@mui/material';
import COLORS from 'constants/COLORS';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useStoreShared } from 'store/shared-store';

export default function FileInput({ formik }) {
  const { enqueueSnackbar } = useSnackbar();

  const { setAttachmentViewerUrlStore } = useStoreShared();

  const [isResetFile, setIsResetFile] = useState(false);

  const { setFieldValue, getFieldProps } = formik;

  const isFileAdded = getFieldProps('file').value != null;

  const handleFileSelection = (e) => {
    const uploadedFile = e.target.files[0];

    const validationReset = () => {
      setFieldValue('file', null);
      setIsResetFile(!isResetFile);
    };

    if (!uploadedFile) {
      enqueueSnackbar('Please select your invoice attachment', { variant: 'warning' });
      validationReset();
    } else if (!uploadedFile.name.match(/\.(jpg|jpeg|png|pdf)$/)) {
      enqueueSnackbar('Please select a valid format (pdf, jpg, jpeg, png)', { variant: 'warning' });
      validationReset();
    } else if (uploadedFile.size <= 0) {
      enqueueSnackbar('File not found, file might be corrupted or deleted', { variant: 'warning' });
      validationReset();
    } else if (uploadedFile.size > 10000000) {
      enqueueSnackbar('File size must be 10MB', { variant: 'warning' });
      validationReset();
    } else {
      setFieldValue('file', uploadedFile, true);
    }
  };

  const viewFile = () => {
    const uploadedFile = getFieldProps('file').value;
    const url = URL.createObjectURL(uploadedFile);

    return (
      <Button
        size="small"
        color="araSecondary"
        sx={{ fontSize: 12, textDecoration: 'underline', fontWeight: 'bold' }}
        startIcon={<FolderSharp />}
        onClick={() => {
          setAttachmentViewerUrlStore({ url });
        }}
      >
        View file
      </Button>
    );
  };

  return (
    <>
      <Button
        variant={isFileAdded ? 'outlined' : 'contained'}
        component="label"
        size="small"
        color={isFileAdded ? 'primary' : 'araSecondary'}
        startIcon={<Backup />}
        sx={{ mr: 2, fontSize: 11 }}
      >
        {isFileAdded
          ? 'Change file'
          : 'Select a new file'}
        <input
          type="file"
          hidden
          key={isResetFile}
          onChange={handleFileSelection}
        />
      </Button>

      {isFileAdded && viewFile()}
      <Box display={'flex'} flexDirection={'row'} alignItems="center" mt={2}>
        <Help sx={{ mr: 1, fontSize: 16, color: COLORS.labelSecondary }} />
        <Typography variant="body2" sx={{ color: COLORS.labelSecondary }}>
          {'File size must be '}
          <b>below 10MB</b>
        </Typography>
      </Box>
      <Box display={'flex'} flexDirection={'row'} alignItems="center" mt={1}>
        <Help sx={{ mr: 1, fontSize: 16, color: COLORS.labelSecondary }} />
        <Typography variant="body2" sx={{ color: COLORS.labelSecondary }}>
          {'File format must be in '}
          <b>jpg, png or pdf only</b>
        </Typography>
      </Box>
    </>
  );
}
