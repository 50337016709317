import React, { useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
// MUI
import {
  Autocomplete,
  Box, Button, Grid, IconButton, InputAdornment, Link, TextField, Typography,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
// CONST AND UTIL
import isMobilePhone from 'validator/lib/isMobilePhone';

import COUNTRIES from 'constants/COUNTRIES';
import isStrongPassword from 'validator/lib/isStrongPassword';
import { AuthSignupAPI } from 'services/auth-api-services';
import { useNavigate, useParams } from 'react-router-dom';
import MuiPhoneInput from 'components/shared/Input/MuiPhoneInput';
// APIS

Yup.addMethod(Yup.string, 'isYupStrongPassword', isStrongPassword);

const SignUpSchema = Yup.object({
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Email address is required'),
  first_name: Yup.string()
    .min(2, 'First name should be more than 2 characters')
    .max(50, 'First name should be less than 50 characters')
    .required('First Name is required'),
  last_name: Yup.string()
    .min(2, 'Last name should be more than 2 characters')
    .max(50, 'Last name should be less than 50 characters')
    .required('Last Name is required'),
  password: Yup.string()
    .required('Password is required')
    .test(
      'is-strong-password',
      'Password should have 8 or more characters with a mix of upper and lower case letters, numbers, and symbols.',
      (value) => isStrongPassword(value || ''),
    ),
  country_code: Yup.string()
    .length(2, 'country_code should be 2 characters')
    .required('country_code is required'),
  phone_number: Yup.string()
    .required('Phone Number is required')
    .test(
      'is-mobile-number',
      'Please fill in a valid SMS mobile phone number',
      (value) => isMobilePhone(value || ''),
    ),
});

export default function AuthSignUpScreen() {
  const { email } = useParams();
  const navigate = useNavigate();

  const [defaultCountry, setDefaultCountry] = useState(COUNTRIES[128]);

  const [isSignedUp, setIsSignedUp] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      email,
      first_name: '',
      last_name: '',
      password: '',
      country_code: 'MY',
      phone_number: '',
    },
    validationSchema: SignUpSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const [apiStatus, data] = await AuthSignupAPI(values);
      setSubmitting(false);

      if (!apiStatus || !data) {
        setIsSignedUp(false);
        return;
      }

      setIsSignedUp(true);
    },
  });

  const {
    errors, touched, handleSubmit, getFieldProps, setFieldValue, isSubmitting,
  } = formik;

  return (
    <>
      <Typography variant="h4" gutterBottom sx={{ mb: 5 }}>
        Sign up for Ara
      </Typography>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          {!isSignedUp && (
            <>
              <TextField
                fullWidth
                data-cy="email"
                type="email"
                disabled
                label="Email address"
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                {...getFieldProps('email')}
                InputProps={{
                  autoComplete: 'email',
                }}
              />
              <Box sx={{ mb: 3 }} />

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    data-cy="first_name"
                    label="First Name"
                    {...getFieldProps('first_name')}
                    error={Boolean(touched.first_name && errors.first_name)}
                    helperText={touched.first_name && errors.first_name}
                    InputProps={{
                      autoComplete: 'given-name',
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    data-cy="last_name"
                    label="Last Name"
                    {...getFieldProps('last_name')}
                    error={Boolean(touched.last_name && errors.last_name)}
                    helperText={touched.last_name && errors.last_name}
                    InputProps={{
                      autoComplete: 'family-name',
                    }}
                  />
                </Grid>
              </Grid>
              <Box sx={{ mb: 3 }} />

              <TextField
                fullWidth
                data-cy="password"
                type={showPassword ? 'text' : 'password'}
                label="Password"
                {...getFieldProps('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => { setShowPassword(!showPassword); }} edge="end" tabIndex={-1}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  autoComplete: 'new-password',
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
              <Box sx={{ mb: 3 }} />

              <Autocomplete
                fullWidth
                options={COUNTRIES}
                data-cy="country_code"
                disabled // disable country selection for now as we only providing business for Malaysia
                disableClearable
                getOptionLabel={(option) => option.name}
                onChange={(_e, value) => {
                  setFieldValue('country_code', value.code || '');
                  setDefaultCountry(value);
                }}
                value={defaultCountry}
                filterOptions={(options, { inputValue }) => options
                  .filter((x) => x.code.toLowerCase() === inputValue.toLowerCase()
                    || x.name.toLowerCase().startsWith(inputValue.toLowerCase()))}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(touched.country_code && errors.country_code)}
                    label="Country"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'current-password',
                    }}
                  />
                )}
              />
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                Ara is currently only available in Malaysia, contact us at
                {' '}
                <Link
                  tabIndex={-1}
                  target="_top"
                  rel="noopener noreferrer"
                  href="mailto:sales@ara-app.com"
                  sx={{ fontWeight: 'bold' }}
                >
                  sales@ara-app.com
                </Link>
                {' '}
                for new markets
              </Typography>
              <Box sx={{ mb: 3 }} />

              <MuiPhoneInput
                formik={formik}
                formikFieldKey={'phone_number'}
                label="Phone Number"
                forceCallingCode={false}
                size="large"
              />
              <Box
                sx={{
                  my: 2,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              />
            </>
          )}

          {isSignedUp && (
            <Box sx={{ mb: 5 }}>
              <Typography variant="h5" gutterBottom>
                Succesfully Created Your Account
              </Typography>
              <Box sx={{ height: 20 }} />
              <Typography variant="body1">
                You’ve successfully created new account.
                Now you need to activate your account by opening the email that we have sent to
                {' '}
                <b>{email}</b>
              </Typography>
              <Box sx={{ height: 20 }} />
              <Typography variant="body1">
                After you activate your account, you can login to dashboard
              </Typography>
              <Box sx={{ height: 20 }} />
              <Typography variant="body1">
                If you need any help you can email us at
                {' '}
                <Link
                  tabIndex={-1}
                  target="_top"
                  rel="noopener noreferrer"
                  href="mailto:sales@ara-app.com"
                  sx={{ fontWeight: 'bold' }}
                >
                  sales@ara-app.com
                </Link>
                .
              </Typography>
              <Box sx={{ height: 20 }} />
              <Typography variant="body1">
                We are here to help connect the dots!

              </Typography>

            </Box>
          )}

          <LoadingButton
            fullWidth
            size="large"
            data-cy="signup_btn"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            disabled={isSignedUp}
          >
            Sign up
          </LoadingButton>
          <Button
            variant="contained"
            color="tertiary"
            sx={{ my: 3 }}
            fullWidth
            onClick={() => {
              navigate('/auth');
            }}
          >
            Cancel
          </Button>
        </Form>
      </FormikProvider>
    </>
  );
}
