import React from 'react';

import ActionButton from 'components/shared/Input/ActionButton';
import { Grid } from '@mui/material';
import UpdateNotification from './UpdateNotification';

export default function RecordNanoDetailAction({
  data,
}) {
  const getActionButtonLists = () => {
    const list = [];

    list.push({
      custom: <UpdateNotification customerId={data?.customer?._id} />,
      dataCy: 'notifications',
    });

    return list;
  };

  return (
    <Grid xs={12} md="auto" item>
      <ActionButton lists={getActionButtonLists()} />

    </Grid>
  );
}
