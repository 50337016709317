import React, { useEffect, useState } from 'react';

// MUI
import {
  Alert,
  AlertTitle,
  Box,
  Button, CircularProgress, Link, Typography,
} from '@mui/material';

import { useNavigate, useParams } from 'react-router-dom';
import { AuthVerifyAccountAPI } from 'services/auth-api-services';

export default function AuthVerifyAccountScreen() {
  const { email } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isSent, setIsSent] = useState(false);

  const verify = async () => {
    setIsLoading(true);
    const [apiStatus] = await AuthVerifyAccountAPI({ email });
    setIsLoading(false);

    if (!apiStatus) {
      setIsSent(false);
      return;
    }

    setIsSent(true);
  };

  useEffect(() => {
    verify();
  }, []);

  return isLoading ? <Box sx={{ textAlign: 'center' }}><CircularProgress size={24} /></Box> : (
    <>
      <Typography variant="h4" gutterBottom sx={{ mb: 5 }}>
        Verify email to continue
      </Typography>
      {isSent
        && (
          <Alert severity="info" sx={{ mb: 3 }}>
            <AlertTitle>
              <strong>
                An account verification link is sent to your email at
                {' '}
                {email}
              </strong>
            </AlertTitle>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Please check your email and verify your account to continue
            </Typography>
            <Typography variant="body1" sx={{ mt: 0.5 }}>
              If you need any help you can email us at
              {' '}
              <Link
                tabIndex={-1}
                target="_top"
                rel="noopener noreferrer"
                href="mailto:sales@ara-app.com"
                sx={{ fontWeight: 'bold' }}
              >
                sales@ara-app.com
              </Link>
              .
            </Typography>
          </Alert>
        )}
      <Button
        variant="contained"
        color="primary"
        sx={{ my: 3 }}
        fullWidth
        onClick={() => {
          navigate('/auth');
        }}
      >
        Okay
      </Button>
    </>
  );
}
