import React from 'react';
// MUI
import {
  Box, Button, CircularProgress, Grid, Typography,
} from '@mui/material';
import { Business, NoteAlt, PersonOutlineOutlined } from '@mui/icons-material';
import { grey } from '@mui/material/colors';

import { useNavigate } from 'react-router-dom';
import MicroEmpty from 'components/shared/Micro/MicroEmpty';
import { useQueryGetOneCustomerByBizIdAndCustId } from 'services/customer-api-services';
import { PATH_USER } from 'constants/PATHS';

export default function DraftRecordInputCustomerEmpty({
  customerId,
}) {
  const navigate = useNavigate();

  const {
    isLoading,
    error,
    customer,
    refetch,
    isRefetchError,
    isRefetching,
  } = useQueryGetOneCustomerByBizIdAndCustId(customerId);

  if (isLoading || isRefetching) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress size={20} sx={{ mt: 4 }} />
      </Box>
    );
  }

  if (error || !customer || isRefetchError) {
    return (
      <MicroEmpty
        title="Oops! Couldn't retrieve customer. Please try again."
        onClick={() => {
          refetch();
        }}
      />
    );
  }

  return (
    <Box>
      <Grid container columnSpacing={4} rowSpacing={2}>
        <Grid xs item>
          <Box sx={{
            display: 'flex', alignItems: 'center', flexDirection: 'row',
          }}
          >
            {customer?.user_type === 'individual'
              ? <PersonOutlineOutlined sx={{ fontSize: 13.5, mr: 1 }} /> : <Business sx={{ fontSize: 13.5, mr: 1 }} />}
            <Typography variant="body1" sx={{ fontWeight: 600 }}>
              {customer?.business_name || `${customer?.first_name} ${customer?.last_name}`}
            </Typography>
          </Box>
          <Typography variant="caption" sx={{ fontWeight: 500, color: grey[700] }}>Malaysia</Typography>
        </Grid>
        <Grid xs="auto" item>
          <Button
            size="small"
            color="araSecondary"
            startIcon={<NoteAlt />}
            sx={{ textDecoration: 'underline' }}
            onClick={() => {
              navigate(PATH_USER.customers.detail('active', customer._id));
            }}
          >
            Edit Customer
          </Button>
        </Grid>
      </Grid>

      <Typography sx={{ my: 2 }} fontWeight={600}>Contact Details</Typography>
      <Grid container direction="row" spacing={2}>
        <Grid item xs="auto">
          <Box backgroundColor="#F2F4F7" p={1} borderRadius={2}>
            <Typography variant="body1" fontWeight={600}>
              {`${customer?.first_name} ${customer?.last_name}`}
              {' '}
              <Typography variant="caption">- Main Contact</Typography>
            </Typography>
            <Typography variant="caption">{`${customer?.email} / ${customer?.phone_number}`}</Typography>
          </Box>
        </Grid>
        {customer?.additional_contacts != null && customer?.additional_contacts?.length > 0 && customer?.additional_contacts.map((ac) => (
          <Grid item xs="auto" key={ac.email}>
            <Box backgroundColor="#F2F4F7" p={1} borderRadius={2}>
              <Typography variant="body1" fontWeight={600}>
                {`${ac?.first_name} ${ac?.last_name}`}
              </Typography>
              <Typography variant="caption">{`${ac?.email} / ${ac?.phone_number || '-'}`}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
