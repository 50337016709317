import { addDays } from 'date-fns';

export const DraftInvoiceFormikInitialValues = (inv = null) => ({
  customer_id: inv?.customer_id || null,
  record_number: inv?.record_number || '',
  description: inv?.description || '',
  issue_date: inv?.date?.issue_date ? new Date(inv.date.issue_date) : new Date(),
  due_date: inv?.date?.due_date ? new Date(inv.date.due_date) : addDays(new Date(), 30),
  total_amount: inv?.amount?.total_amount || '',
  previous_payment_amount: inv?.amount?.previous_payment_amount || 0,
  processable_value: inv?.amount?.processable_value || 0,
  file: null,
  interest_rate: inv?.charges?.interest_rate_config?.interest_rate || 0,
  interest_rate_type: inv?.charges?.interest_rate_config?.interest_rate_type || 'abs',

});
