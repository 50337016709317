import React, { useState } from 'react';
// FORMS
import {
  Autocomplete,
  Box,
  Button,
  Collapse,
  Grid,
  TextField, Typography,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import COUNTRIES from 'constants/COUNTRIES';
import { find } from 'lodash';

export default function CustomerInputAddressDetail({ formik, isEditing = true }) {
  const {
    errors, touched, getFieldProps, setFieldValue,
  } = formik;
  const [isCollapse, setIsCollapse] = useState(false);

  const countryObj = find(COUNTRIES, (c) => c?.code === getFieldProps('country_code')?.value) || COUNTRIES[128];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6}>
          <Typography variant="body1" sx={{ fontWeight: 700 }}>Address Details</Typography>
        </Grid>
        <Grid item xs={6} sm={6}>
          <Box
            sx={{ height: '100%' }}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            flexDirection="row"
          >
            <Button
              color="neutral"
              size="small"
              data-cy="cust-business-detail-collapse-btn"
              sx={{ ml: 2 }}
              onClick={() => {
                setIsCollapse(!isCollapse);
              }}
              startIcon={isCollapse ? <ExpandMore /> : <ExpandLess />}
              tabIndex={-1}
            >
              {isCollapse ? 'Show' : 'Hide'}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Collapse in={!isCollapse} timeout="auto">
        <Box sx={{ height: 24 }} />
        <TextField
          data-cy="address_line_1"
          fullWidth
          label="Address Line 1"
          {...getFieldProps('address_line_1')}
          error={Boolean(touched.address_line_1 && errors.address_line_1)}
          inputProps={{
            autoComplete: 'new-password',
            form: {
              autoComplete: 'off',
            },
            readOnly: !isEditing,
          }}
          helperText={touched.address_line_1 && errors.address_line_1}
          size="small"
        />
        <Box sx={{ height: 24 }} />

        <Grid container spacing={2}>
          <Grid item sm={8} xs={7}>
            <TextField
              fullWidth
              data-cy="city"
              label="City"
              {...getFieldProps('city')}
              error={Boolean(touched.city && errors.city)}
              helperText={touched.city && errors.city}
              size="small"
              inputProps={{
                autoComplete: 'new-password',
                form: {
                  autoComplete: 'off',
                },
                readOnly: !isEditing,
              }}
            />
          </Grid>
          <Grid item sm={4} xs={5}>
            <TextField
              fullWidth
              data-cy="postcode"
              label="Postcode"
              {...getFieldProps('postcode')}
              error={Boolean(touched.postcode && errors.postcode)}
              helperText={touched.postcode && errors.postcode}
              size="small"
              inputProps={{
                autoComplete: 'new-password',
                form: {
                  autoComplete: 'off',
                },
                readOnly: !isEditing,
              }}
            />
          </Grid>
        </Grid>

        <Box sx={{ height: 24 }} />

        <TextField
          fullWidth
          data-cy="state"
          label="State"
          {...getFieldProps('state')}
          error={Boolean(touched.state && errors.state)}
          helperText={touched.state && errors.state}
          size="small"
          inputProps={{
            autoComplete: 'new-password',
            form: {
              autoComplete: 'off',
            },
            readOnly: !isEditing,
          }}
        />
        <Box sx={{ height: 24 }} />
        <Autocomplete
          fullWidth
          options={COUNTRIES}
          disableClearable
          getOptionLabel={(option) => option.name}
          onChange={(_e, value) => {
            setFieldValue('country_code', value.code || '');
          }}
          value={countryObj}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          filterOptions={(options, { inputValue }) => options
            .filter((x) => x.code.toLowerCase() === inputValue.toLowerCase()
              || x.name.toLowerCase().startsWith(inputValue.toLowerCase()))}
          size="small"
          readOnly={!isEditing}
          renderInput={(params) => (
            <TextField
              {...params}
              error={Boolean(touched.country_code && errors.country_code)}
              helperText={touched.country_code && errors.country_code}
              label="Country"
              data-cy="country_code"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password',
                form: {
                  autoComplete: 'off',
                },
              }}
            />
          )}
        />
      </Collapse>
    </>
  );
}
