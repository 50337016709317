import React, { useState } from 'react';
import {
  Grid,
  Typography,
  styled,
  Container,
  Box,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material';

import COLORS from 'constants/COLORS';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
// CONSTANTS
import isStrongPassword from 'validator/lib/isStrongPassword';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { useSnackbar } from 'notistack';
import { AuthUpdatePasswordAPI } from 'services/auth-api-services';
import { LoadingButton } from '@mui/lab';

export default function SettingsNanoSetPassword({ refetch }) {
  const { enqueueSnackbar } = useSnackbar();

  const [showPassword, setShowPassword] = useState(false);

  Yup.addMethod(Yup.string, 'isYupStrongPassword', isStrongPassword);

  const SetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required('Password is required')
      .test(
        'is-strong-password',
        'Password should have 8 or more characters with a mix of upper and lower case letters, numbers, and symbols.',
        (value) => isStrongPassword(value || ''),
      ),
  });

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: SetPasswordSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const [data] = await AuthUpdatePasswordAPI({ currentPassword: null, newPassword: values.password });
      setSubmitting(false);

      if (!data) return;

      enqueueSnackbar('Password updated successfully, thank you for keeping your account secure');
      refetch();
    },
  });

  const {
    errors, touched, getFieldProps, isSubmitting, handleSubmit,
  } = formik;

  return (

    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <NanoPaperContainer maxWidth="sm" disableGutters>
          <NanoTitle container spacing={2}>
            <Grid item xs>
              <Typography variant="h6">
                Set password
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: COLORS.labelSecondary }}
              >
                There is no password set on this account. Please configure a password for easier access.
              </Typography>
            </Grid>
          </NanoTitle>

          <TextField
            fullWidth
            type={showPassword ? 'text' : 'password'}
            data-cy="password"
            label={'Password'}
            error={touched.password && Boolean(errors.password)}
            helperText={touched.password && errors.password}
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    tabIndex={-1}
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box sx={{ height: 24 }} />
          <LoadingButton
            size="medium"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            fullWidth
          >
            Set Password
          </LoadingButton>

        </NanoPaperContainer>
      </Form>
    </FormikProvider>

  );
}

const NanoPaperContainer = styled(Container)(({ theme }) => ({
  background: '#fff',
  borderRadius: 2,
  position: 'relative',
  marginTop: theme.spacing(2),
  border: `1px solid ${COLORS.divider}`,
  boxShadow: '0 8px 10px rgba(112,144,176,0.2)',
  padding: theme.spacing(4),

}));

const NanoTitle = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(0, 0, 4, 0),
}));
