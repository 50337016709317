import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Card } from '@mui/material';
import COLORS from 'constants/COLORS';

export default function AuthLayoutBanner() {
  return (
    <Box sx={{ display: { xs: 'none', lg: 'block', width: 400 } }}>
      <BigCard sx={{ boxShadow: 5 }}>
        <CompanyLogo
          alt="company"
          src="/images/ara-app-logo-white.webp"
          style={{ width: '82.5px', height: '28.62px' }}
        />

        <TitleBox sx={{
          fontSize: 30, px: 5, mt: 20, mb: 5,
        }}
        >
          Hi, Welcome to Ara
        </TitleBox>
        <MainImg
          src="/images/auth/data-visualization.webp"
          alt="ara-app-banner-image"
        />
        <RightCircle
          src="/images/auth/ellipse-right.webp"
          alt="bottom background"
        />
        <LeftCircle
          src="/images/auth/ellipse-left.webp"
          alt="bottom background"
        />

      </BigCard>
    </Box>

  );
}

const BigCard = styled(Card)(() => ({
  width: '100%',
  minHeight: 800,
  maxWidth: 400,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  color: 'rgb(33, 43, 54)',
  height: '100vh',
  background: COLORS.primary,
  borderRadius: 0,
  position: 'fixed',
  top: 0,
  bottom: 0,
}));

const CompanyLogo = styled('img')(() => ({
  marginTop: 50,
  zIndex: 20,
}));

const TitleBox = styled(Box)(() => ({
  zIndex: '10! important',
  fontWeight: '700 !important',
  color: '#fff',
}));

const MainImg = styled('img')(() => ({
  position: 'absolute',
  top: '45%',
  zIndex: 20,
  width: '80%',
  ml: 2,
}));

const RightCircle = styled('img')(() => ({
  position: 'absolute',
  right: 0,
  top: 125,
  width: 45,
  height: 90,
}));

const LeftCircle = styled('img')(() => ({
  position: 'absolute',
  left: 0,
  bottom: 50,
  width: 45,
  height: 90,
}));
