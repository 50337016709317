import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
// MUI
import {
  Box, Button, CircularProgress, styled, Typography,
} from '@mui/material';
import { Add } from '@mui/icons-material';
// STORES
import { useStoreCust } from 'store/cust-mgmt-store';
// CONST AND UTIL
import COLORS from 'constants/COLORS';
import LAYOUT from 'constants/LAYOUT';
// COMPONENTS
import MicroEmpty from 'components/shared/Micro/MicroEmpty';
import { CustomerGetManyByBusinessIdAPI } from 'services/customer-api-services';
import CustomerMicroListBox from './CustomerMicroListBox';
import { useNavigate, useParams } from 'react-router-dom';
import MicroSearchBar from 'components/shared/Micro/MicroSearchBar/MicroSearchBar';
import { PATH_USER } from 'constants/PATHS';

const PAGE_SIZE = 25;

const NON_LIST_HEIGHT = LAYOUT.APP_BAR_HEIGHT + LAYOUT.MICRO_HEADER_HEIGHT;

export default function CustomerMicro({
  status,
}) {
  const { customerId } = useParams();
  const navigate = useNavigate();

  const {
    customersStore, setCustomersStore,
    totalCustomersCountStore, setTotalCustomersCountStore,
  } = useStoreCust();

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const handleGetCustomerByBusinessId = async (currentPage, isActive, searchStr = '') => {
    const [apiStatus, data] = await CustomerGetManyByBusinessIdAPI({
      isActive, pageSize: PAGE_SIZE, search: searchStr, pageNumber: currentPage,
    });
    setIsLoading(false);
    setIsLoadingMore(false);

    if (!apiStatus || !data) return;

    if (currentPage === 1) {
      setCustomersStore([...data.customers]);
    } else {
      setCustomersStore([...customersStore, ...data.customers]);
    }

    setTotalCustomersCountStore(data.count);
  };

  useEffect(() => {
    setIsLoading(true);
    setCustomersStore([]);
    setTotalCustomersCountStore(0);

    setPage(1);

    handleGetCustomerByBusinessId(1, status === 'active', search);
  }, [status, search]);

  return (
    <MicroContainer>
      <MicroHeader>
        <MicroTitle>
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            {status === 'active' && 'Active Customer'}
            {status === 'inactive' && 'Inactive Customer'}
            {totalCustomersCountStore > 0 && ` (${totalCustomersCountStore})`}
          </Typography>
          <Button
            startIcon={<Add />}
            size="small"
            onClick={() => { navigate(PATH_USER.customers.create); }}
            sx={{ fontWeight: 700 }}
          >
            Add Customer
          </Button>
        </MicroTitle>

      </MicroHeader>

      <MicroList id="scrollableDiv">
        <Box sx={{ height: '20px' }} />
        <MicroSearchBar search={search} setSearch={setSearch} placeholder="Search by customer name, business name, phone number or email" timeout={500} />
        <Box sx={{ height: `${LAYOUT.MICRO_HEADER_SPACING}px` }} />
        {isLoading && <Box sx={{ textAlign: 'center', my: 2 }}><CircularProgress size={20} color="neutral" /></Box>}

        {!isLoading && customersStore.length < 1 && (<MicroEmpty title={`There are currently no ${status} customer`} />)}

        {customersStore.length > 0 && (
          <InfiniteScroll
            height={window.innerHieght - NON_LIST_HEIGHT}
            dataLength={customersStore.length || 0}
            endMessage={(
              <Typography sx={{
                fontWeight: 'bold', textAlign: 'center', mb: 3, mt: 3,
              }}
              >
                Yay! You have seen it all
              </Typography>
            )}
            next={() => {
              setIsLoadingMore(true);
              setPage(page + 1);
              handleGetCustomerByBusinessId(page + 1, status === 'active', search);
            }}
            hasMore={customersStore.length < totalCustomersCountStore}
            loader={<Box sx={{ textAlign: 'center', my: 2 }}><CircularProgress size={20} /></Box>}
            scrollableTarget="scrollableDiv"
          >
            {customersStore.map(
              (data) => {
                const selected = data?._id === customerId;
                return (
                  <CustomerMicroListBox
                    data={data}
                    key={data._id}
                    selected={selected}
                    onClick={() => {
                      navigate(`detail/${data._id}`);
                    }}
                  />
                );
              },
            )}
            {!isLoadingMore && customersStore.length < totalCustomersCountStore && <Box sx={{ height: '50px' }} />}
          </InfiniteScroll>
        )}
      </MicroList>
    </MicroContainer>
  );
}

const MicroContainer = styled('div')(() => ({
  height: '100%',
  width: '100%',
  backgroundColor: COLORS.secondaryBG,

}));

const MicroHeader = styled(
  Box,
)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  margin: 0,
  zIndex: 3,
  borderBottom: `1px solid ${COLORS.divider}`,
  height: `${LAYOUT.MICRO_HEADER_HEIGHT}px`,
  boxShadow: 'none',
  backgroundColor: '#fff',
  transition: theme.transitions.create(['boxShadow', 'backgroundColor'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.standard,
  }),
}));

const MicroTitle = styled(Box)(({ theme }) => ({
  height: LAYOUT.MICRO_HEADER_HEIGHT,
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(0, 0),

}));

const MicroList = styled(Box)(({ theme }) => ({
  height: `calc(100vh - ${NON_LIST_HEIGHT}px)`,
  overflowY: 'auto',
  overflowX: 'hidden',
  padding: theme.spacing(0, 2),

}));
