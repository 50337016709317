import { filter, find } from 'lodash';
import { create } from 'zustand';

const initialRecordStore = {
  recordsStore: [],
  selectedRecordIdsStore: [],

  voidRecordIdsStore: [],
};

export const useStoreRecord = create((set, get) => ({
  ...initialRecordStore,
  resetInvoiceStore: () => set({ ...initialRecordStore }),

  // -------------------------------------------------------------------------------------------------------------------------------------------------
  // recordsStore
  setRecordsStore: (arr) => set({ recordsStore: arr }),
  removeOneRecordsStoreById: (remDataId) => {
    const { recordsStore } = get();
    const tempArr = filter(recordsStore, (item) => item._id !== remDataId);
    set({ recordsStore: [...tempArr] });
  },

  // -------------------------------------------------------------------------------------------------------------------------------------------------
  // selectedRecordIdsStore
  setSelectedRecordIdsStore: (arrOfIds) => set({ selectedRecordIdsStore: arrOfIds }),
  removeOneSelectedRecordIds: (remDataId) => {
    const { selectedRecordIdsStore } = get();
    const tempArr = filter(selectedRecordIdsStore, (id) => id !== remDataId);
    set({ selectedRecordIdsStore: [...tempArr] });
  },
  addOrRemoveSelectedRecordIdsStore: (newId) => {
    const { selectedRecordIdsStore } = get();
    const isIdExist = find(selectedRecordIdsStore, (id) => id === newId);

    if (isIdExist) {
      const remainingIds = filter(selectedRecordIdsStore, (id) => id !== newId);
      set({ selectedRecordIdsStore: remainingIds });
    } else {
      set({ selectedRecordIdsStore: [...selectedRecordIdsStore, newId] });
    }
  },

  // -------------------------------------------------------------------------------------------------------------------------------------------------
  // Used on src\screens\Record\RecordScreen.js with <RecordVoidDialog/>
  // voidRecordIdsStore
  setVoidRecordIdsStore: (arrOfIds) => set({ voidRecordIdsStore: arrOfIds }),
}));
