import React from 'react';
// MUI
import {
  Box, Checkbox, FormControlLabel, styled,
} from '@mui/material';
import { useStoreRecord } from 'store/record-store';
// STORES

export default function RecordMicroSelectAll() {
  const { selectedRecordIdsStore, setSelectedRecordIdsStore, recordsStore } = useStoreRecord();

  return (

    <MicroSelectAll>
      <FormControlLabel
        sx={{ ml: 0 }}
        control={(
          <Checkbox
            size="small"
            checked={selectedRecordIdsStore?.length === recordsStore?.length}
            indeterminate={selectedRecordIdsStore?.length > 0 && selectedRecordIdsStore?.length !== recordsStore?.length}
            onChange={(e) => {
              if (e.target.checked) {
                const ids = recordsStore.map((i) => i._id);
                setSelectedRecordIdsStore([...ids]);
              } else {
                setSelectedRecordIdsStore([]);
              }
            }}
          />
        )}
        label={`${selectedRecordIdsStore?.length} item(s) selected`}
      />
    </MicroSelectAll>

  );
}

const MicroSelectAll = styled(Box)(({ theme }) => ({
  height: 40,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  backgroundColor: '#fff',
  padding: theme.spacing(0, 0),
  borderRadius: '8px',
  border: '1px solid #e3e8ee',
}));
