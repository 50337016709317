import React, { useEffect } from 'react';
import FullScreenLoader from 'components/shared/FullScreenLoader/FullScreenLoader';

export default function SplashScreen({ handleAuthProfile }) {
  useEffect(() => {
    handleAuthProfile();
  }, []);

  return <FullScreenLoader isLoading />;
}
