import React, { useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
// MUI
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { AuthSignInWithMagicLinkAPI } from 'services/auth-api-services';
import { useNavigate, useParams } from 'react-router-dom';

export default function AuthMagicLinkSignInScreen() {
  const { email } = useParams();
  const navigate = useNavigate();
  const [isMagicLinkSent, setIsMagicLinkSent] = useState(false);

  const formik = useFormik({
    initialValues: {
      email,
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const [apiStatus] = await AuthSignInWithMagicLinkAPI(values);
      setSubmitting(false);

      if (!apiStatus) {
        setIsMagicLinkSent(false);
        return;
      }

      setIsMagicLinkSent(true);
    },
  });

  const {
    getFieldProps, isSubmitting, handleSubmit,
  } = formik;

  return (
    <>
      <Typography variant="h4" gutterBottom sx={{ mb: 5 }}>
        Sign in with Magic Link
      </Typography>
      <FormikProvider value={formik}>
        <Form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
        >
          <TextField
            fullWidth
            type="email"
            disabled
            label={'Email address'}
            {...getFieldProps('email')}
          />
          <Box sx={{ mb: 3 }} />
          {isMagicLinkSent ? (
            <Alert severity="success" sx={{ mb: 3 }}>
              <AlertTitle>
                <strong>
                  A magic link is sent to your email
                </strong>
              </AlertTitle>
              You did not set password for your account. You can login to your account by clicking
              {' '}
              <b>Sign In With Magic Link</b>
              {' '}
              on your email
            </Alert>
          ) : (
            <Alert severity="info" sx={{ mb: 3 }}>
              <AlertTitle>
                <strong>Password not set</strong>
              </AlertTitle>
              You did not set a password for your account. You may request a magic link to sign into your account.
            </Alert>
          )}

          <LoadingButton
            fullWidth
            size="large"
            data-cy="magic_link_btn"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            disabled={isMagicLinkSent}
          >
            Sign in with magic link
          </LoadingButton>

          <Button
            variant="contained"
            color="tertiary"
            sx={{ my: 3 }}
            fullWidth
            onClick={() => {
              navigate('/auth');
            }}
          >
            Cancel
          </Button>
        </Form>
      </FormikProvider>

    </>
  );
}
