import React, { useEffect, useState } from 'react';
import {
  Collapse,
  Divider,
  List,
  ListItemButton, ListItemIcon, ListItemText, styled,

} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import COLORS from 'constants/COLORS';

export default function SideBarDrawerItems({ item, isSmallScreen, handleDrawer }) {
  const navigate = useNavigate();
  const location = useLocation();

  const isNested = item.subItems != null && item.subItems.length > 0;
  const selected = !isNested && !!window.location.pathname.includes(item.to);
  const [isCollapseOpen, setIsCollapseOpen] = useState(
    isNested && !!window.location.pathname.includes(item.to),
  );

  useEffect(() => {
    if (isNested && window.location.pathname.includes(item.to)) {
      setIsCollapseOpen(true);
    }
  }, [location]);

  return (
    <React.Fragment key={item.title}>
      <ListItemButton
        selected={selected}
        onClick={() => {
          if (isNested) {
            setIsCollapseOpen(!isCollapseOpen);
          } else {
            navigate(item.to);
            if (isSmallScreen) {
              handleDrawer();
            }
          }
        }}
        sx={{ py: 1.5, px: 3 }}
      >
        <ItemIcon selected={selected}>
          {item.icon}
        </ItemIcon>
        <ItemText primary={item.title} selected={selected} />
        {isNested && (
          isCollapseOpen ? <ExpandLess /> : <ExpandMore />
        )}
      </ListItemButton>
      {isNested && (
      <Collapse in={isCollapseOpen}>
        <List disablePadding>
          {item.subItems.map((sub) => {
            const isSelectedSub = !!window.location.pathname.includes(sub.to);

            return (
              sub.type === 'divider' ? <Divider sx={{ ml: 8.5, mr: 2, my: 0.8 }} key={sub.name + sub.to} /> : (
                <ListItemButton
                  key={sub.name + sub.to}
                  selected={isSelectedSub}
                  onClick={() => {
                    navigate(sub.to);
                    if (isSmallScreen) {
                      handleDrawer();
                    }
                  }}
                  sx={{ pl: 9, py: 0.8 }}
                >
                  <SubItemText primary={sub.name} isSelectedSub={isSelectedSub} />
                </ListItemButton>
              )
            );
          })}

        </List>
      </Collapse>
      )}
    </React.Fragment>
  );
}

const ItemIcon = styled(
  ListItemIcon,
  { shouldForwardProp: (prop) => prop !== 'selected' },
)(({ selected }) => ({
  minWidth: 40,
  color: selected ? COLORS.primary : COLORS.gray0,
}));

const ItemText = styled(
  ListItemText,
  { shouldForwardProp: (prop) => prop !== 'selected' },
)(({ selected }) => ({
  '.MuiTypography-root': selected ? {
    fontWeight: 'bold',
    color: COLORS.primary,
    fontSize: '12px',
  } : {
    fontWeight: 600,
    fontSize: '12px',
  },
}));

const SubItemText = styled(
  ListItemText,
  { shouldForwardProp: (prop) => prop !== 'isSelectedSub' },
)(({ isSelectedSub }) => ({
  '.MuiTypography-root': isSelectedSub ? {
    fontWeight: 'bold',
    color: COLORS.primary,
    fontSize: '11px',
  } : {
    fontWeight: 500,
    fontSize: '11px',
  },
}));
