/* eslint-disable no-unsafe-optional-chaining */
import React, { useMemo, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
// MUI
import {
  DialogContent,
  Box, CircularProgress, Divider, Chip, styled, Button, Typography,
} from '@mui/material';
import './style.css';

import { Download } from '@mui/icons-material';
import { round } from 'lodash';
import MicroEmpty from '../Micro/MicroEmpty';
import { MuiImage } from '../MuiImage';
import { enqueueSnackbar } from 'notistack';
import { format } from 'date-fns';
import { useQuery } from '@tanstack/react-query';

const fetchBlob = async (url) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    return blob;
  } catch (error) {
    console.error('Error fetching blob:', error);
    return null;
  }
};

const DEFAULT_FILE_NAME = `ara-file-${format(new Date(), 'ddMMMyyyy')}`;

export default function AttachmentViewerFile({
  url = null,
  fileName = DEFAULT_FILE_NAME,
  isShowDownloadBtn = true,
}) {
  const {
    data: blob,
    isLoading,
    error,
    refetch,
    isRefetchError,
    isRefetching,
  } = useQuery(
    {
      queryKey: ['fetch-aws-s3-blob', url],
      queryFn: () => fetchBlob(url),
      enabled: !!url,
    },
  );

  const newUrl = useMemo(() => (url?.includes('blob:http') ? url : `${url}?q=${format(new Date(), 'ddMMyyHHmm')}`), [url]);

  const renderContent = () => {
    if (!url) return <Box />;

    if (isLoading || isRefetching) {
      return (
        <Box display="flex" alignItems="center" justifyContent="center">
          <CircularProgress size={20} sx={{ mt: 4 }} />
        </Box>
      );
    }

    if (error || !blob?.type || isRefetchError) {
      return (
        <MicroEmpty
          title="Oops! Couldn't retrieve file. Please try again."
          onClick={() => {
            refetch();
          }}
        />
      );
    }

    if (blob.type === 'application/pdf') {
      return <DisplayPdf url={url} />;
    }

    return (
      <MuiImage
        key={url}
        showLoading={<CircularProgress size={44} color="neutral" />}
        src={newUrl}
        alt={newUrl}
      />
    );
  };

  return (
    <DialogContent
      sx={{
        p: 0, overflowX: 'hidden', minHeight: '0vh', textAlign: 'center',
      }}
    >
      <>
        {isShowDownloadBtn
          && (
            <Button
              startIcon={<Download />}
              sx={{ my: 3 }}
              variant="outlined"
              size="small"
              color="primary"
              data-cy="download-file"
              onClick={() => {
                enqueueSnackbar('', {
                  variant: 'downloadOneFile', url, fileName, persist: true, preventDuplicate: false,
                });
              }}
            >
              Download File
            </Button>
          )}
        {renderContent()}
      </>
    </DialogContent>
  );
}

function DisplayPdf({ url }) {
  const [numPages, setNumPages] = useState(null);

  const [docKey, setDocKey] = useState(0);
  const [loadingProgress, setLoadingProgress] = useState(0);

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
    setNumPages(nextNumPages);
  };

  const onDocumentRetry = () => {
    setDocKey(docKey + 1);
  };

  return (
    <Box className="PdfDiv" width="100%">
      <Document
        key={docKey}
        style={{ display: 'none' }}
        file={url}
        onLoadSuccess={onDocumentLoadSuccess}
        error={(
          <Box sx={{ p: 2 }}>
            <MicroEmpty
              title="Failed to load PDF file. Please check your internet connectivity and try again"
              onClick={onDocumentRetry}
              buttonLabel="RELOAD PDF"
            />
          </Box>
        )}
        onLoadProgress={({ loaded, total }) => {
          const p = round((loaded / total) * 100);
          setLoadingProgress(p);
        }}
        loading={(<Box sx={{ p: 2 }}><CircularProgressWithLabel value={loadingProgress} /></Box>)}
      >
        {Array.from({ length: numPages }, (_, index) => (
          <React.Fragment key={`page_${index + 1}`}>
            <Page
              pageNumber={index + 1}
              renderAnnotationLayer={false}
              renderTextLayer={false}
              width={document.getElementsByClassName('PdfDiv')[0]?.clientWidth ?? 700}
            >
              <PageChip
                label={`${index + 1} / ${numPages}`}
                size="small"
              />

            </Page>
            <Divider />
          </React.Fragment>
        ))}
      </Document>
    </Box>
  );
}

const PageChip = styled(
  Chip,
)(({ theme }) => ({
  position: 'absolute',
  top: 20,
  left: 20,
  padding: theme.spacing(0),
  fontSize: 10,
  fontWeight: 700,

}));

function CircularProgressWithLabel(
  { value = 0 },
) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress size={44} color="neutral" />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >
          {`${round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}
