import React from 'react';
import { useStoreAuth } from 'store/auth-store';
import OnboardingSwitchAccount from './OnboardingSwitchAccount';
import { useQueryBusinessCheckOnboardingStatus, useQueryBusinessCheckPayexOnboarding } from 'services/business-api-services';
import OnboardingNew from './OnboardingNew';
import { Box, CircularProgress } from '@mui/material';
import MicroEmpty from '../Micro/MicroEmpty';

export default function OnboardingWrapper({ children }) {
  const { businessProfileStore } = useStoreAuth();

  if (!businessProfileStore) return <OnboardingSwitchAccount />;

  const {
    isLoading, error, data, refetch, isRefetchError, isRefetching,
  } = useQueryBusinessCheckOnboardingStatus();
  useQueryBusinessCheckPayexOnboarding();

  if (isLoading || isRefetching) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress size={20} sx={{ mt: 4 }} />
      </Box>
    );
  }

  if (error || !data || isRefetchError) {
    return (
      <MicroEmpty
        title="Oops! We couldn't retrieve your onboarding status. Please try again."
        onClick={() => {
          refetch();
        }}
      />
    );
  }

  if (!data?.onboarding_status) return <OnboardingNew />;

  return children;
}
