import React from 'react';
// MUI
import {
  Box, Checkbox, FormControlLabel, styled,
} from '@mui/material';
import { useStoreDraftRecord } from 'store/draft-record-store';
// STORES

export default function DraftRecordMicroSelectAll() {
  const {
    selectedDraftRecordIdsStore, setSelectedDraftRecordIdsStore,
    draftRecordsStore,
  } = useStoreDraftRecord();

  return (

    <MicroSelectAll>
      <FormControlLabel
        sx={{ ml: 0 }}
        control={(
          <Checkbox
            size="small"
            checked={selectedDraftRecordIdsStore?.length === draftRecordsStore?.length}
            indeterminate={selectedDraftRecordIdsStore?.length > 0 && selectedDraftRecordIdsStore?.length !== draftRecordsStore?.length}
            onChange={(e) => {
              if (e.target.checked) {
                const ids = draftRecordsStore.map((i) => i._id);
                setSelectedDraftRecordIdsStore([...ids]);
              } else {
                setSelectedDraftRecordIdsStore([]);
              }
            }}
          />
        )}
        label={`${selectedDraftRecordIdsStore?.length} item(s) selected`}
      />
    </MicroSelectAll>

  );
}

const MicroSelectAll = styled(Box)(({ theme }) => ({
  height: 40,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  backgroundColor: '#fff',
  padding: theme.spacing(0, 0),
  borderRadius: '8px',
  border: '1px solid #e3e8ee',
}));
