/* eslint-disable react/jsx-no-useless-fragment */
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
// MUI
import {
  Button,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  Add,
} from '@mui/icons-material';
// STORES
import { useStoreAuth } from 'store/auth-store';
// CONSTANTS
import COLORS from 'constants/COLORS';
// COMPONENTS
import { PATH_USER } from 'constants/PATHS';

export default function AppBarHeaderButtons() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // -------------------------------------------------------------------------------------------------------------------------------------------------
  const { businessProfileStore } = useStoreAuth();

  // -------------------------------------------------------------------------------------------------------------------------------------------------

  return (
    <>
      {businessProfileStore != null && (
        <>
          {isSmScreen ? (
            <IconButton
              color="primary"
              edge="start"
              disableFocusRipple
              disableRipple
              data-cy="new-invoice-btn"
              sx={{
                backgroundColor: COLORS.primary,
                border: '1px solid #E0E3E7',
                borderRadius: '20px',

              }}
              onClick={() => {
                navigate(PATH_USER.draftinvoices.create);
              }}
            >
              <Add sx={{ color: '#fff' }} fontSize="small" />
            </IconButton>
          ) : (
            <Button
              size="small"
              startIcon={<Add />}
              variant="contained"
              data-cy="add-invoice-btn"
              onClick={() => {
                navigate(PATH_USER.draftinvoices.create);
              }}
            >
              New Invoice
            </Button>
          )}
        </>
      )}

    </>
  );
}
