import React from 'react';

// MUI
import {
  Box,
  CircularProgress,
  ListItem, ListItemIcon, ListItemText, Tooltip,
} from '@mui/material';
import {
  InfoTwoTone,
  NotificationsActiveTwoTone,
} from '@mui/icons-material';

import { IOSSwitch } from 'components/shared/styled-component';
import MicroEmpty from 'components/shared/Micro/MicroEmpty';
import { useMutationUpdateOneCustomerByCustomerId, useQueryGetOneCustomerByBizIdAndCustId } from 'services/customer-api-services';
import { useStoreAuth } from 'store/auth-store';

export default function UpdateNotification({
  customerId,
}) {
  const { businessProfileStore } = useStoreAuth();
  if (!customerId) return <Box />;

  const {
    isLoading,
    error,
    customer,
    refetch,
    isRefetchError,
  } = useQueryGetOneCustomerByBizIdAndCustId(customerId);

  const {
    mutIsPending,
    triggerMut,
  } = useMutationUpdateOneCustomerByCustomerId(customerId);

  if (isLoading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" mb={4}>
        <CircularProgress size={20} sx={{ mt: 4 }} />
      </Box>
    );
  }

  if (error || !customer || isRefetchError) {
    return (
      <MicroEmpty
        title="Oops! Couldn't retrieve customer. Please try again."
        onClick={() => {
          refetch();
        }}
      />
    );
  }

  const emailNoti = customer?.notification_settings?.email ?? businessProfileStore?.notification_settings?.email ?? null;
  const smsNoti = customer?.notification_settings?.sms ?? businessProfileStore?.notification_settings?.sms ?? null;
  const waNoti = customer?.notification_settings?.whatsapp ?? businessProfileStore?.notification_settings?.whatsapp ?? false;

  return (
    <>
      <ListItem>
        <ListItemIcon sx={{ mr: 1.5, minWidth: 0 }}>
          <NotificationsActiveTwoTone fontSize="small" color="primary" />
        </ListItemIcon>
        <ListItemText primary="Notifications" secondary={customer?.notification_settings == null ? 'Using default business settings' : ''} />
      </ListItem>
      <ListItem>
        <ListItemIcon sx={{ mr: 1.5, minWidth: 0 }}>
          <Box sx={{ width: 20 }} />
        </ListItemIcon>
        <ListItemText primary="Email notification" />
        <Tooltip title="Switch on/off the email reminders for this customer" arrow>
          <span>
            <IOSSwitch
              data-cy="customer-email-notification"
              checked={emailNoti}
              onClick={(e) => {
                const body = {
                  notification_settings: {
                    whatsapp: waNoti,
                    sms: smsNoti,
                    email: e.target.checked,
                  },
                };
                triggerMut(body, {
                  onSuccess: () => {
                    refetch();
                  },
                });
              }}
              disabled={mutIsPending}
            />
          </span>
        </Tooltip>
      </ListItem>
      <ListItem>
        <ListItemIcon sx={{ mr: 1.5, minWidth: 0 }}>
          <Box sx={{ width: 20 }} />
        </ListItemIcon>
        <ListItemText primary="SMS notification" />
        <Tooltip title="Switch on/off the sms reminders for this customer" arrow>
          <span>
            <IOSSwitch
              data-cy="customer-sms-notification"
              checked={smsNoti}
              onClick={(e) => {
                const body = {
                  whatsapp: waNoti,
                  sms: e.target.checked,
                  email: emailNoti,
                };

                triggerMut(body, {
                  onSuccess: () => {
                    refetch();
                  },
                });
              }}
              disabled={mutIsPending}
            />
          </span>
        </Tooltip>
      </ListItem>
      <ListItem>
        <ListItemIcon sx={{ mr: 1.5, minWidth: 0 }}>
          <InfoTwoTone fontSize="small" />
        </ListItemIcon>
        <ListItemText
          secondaryTypographyProps={{
            whiteSpace: 'pre-line',
          }}
          secondary={`Settings here apply to all invoices for\n this customer: ${customer?.email || customer?.phone_number}`}
        />
      </ListItem>
    </>
  );
}
