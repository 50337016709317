import React from 'react';
import {
  Drawer,
  Grid, styled, Toolbar, useMediaQuery, useTheme,
} from '@mui/material';
import COLORS from 'constants/COLORS';
import LAYOUT from 'constants/LAYOUT';

export default function ResponsiveNanoWrapperV2({
  children,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return isMobile ? (
    <Drawer
      anchor="right"
      open
      sx={styles.FullDrawerStyle}
    >
      <Toolbar sx={{ height: { xs: `${LAYOUT.APP_BAR_HEIGHT + 10}px`, sm: `${LAYOUT.APP_BAR_HEIGHT}px` } }} />
      {children}
    </Drawer>
  ) : (
    <NanoGrid item xs sx={{ overflow: 'auto' }}>
      {children}
    </NanoGrid>
  );
}

const styles = {
  FullDrawerStyle: {
    '& .MuiDrawer-paper': {
      width: '100%',
      maxWidth: 'md',
      backgroundColor: COLORS.secondaryBG,
    },
  },
  DrawerStyle: {
    '& .MuiDrawer-paper': {
      width: '85%',
      maxWidth: 'sm',
      backgroundColor: COLORS.secondaryBG,
    },
  },
};

const NanoGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: COLORS.secondaryBG,
  height: `calc(100vh - ${LAYOUT.APP_BAR_HEIGHT}px)`,
  overflowY: 'auto',
  margin: theme.spacing(0),
}));
