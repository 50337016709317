import * as React from 'react';
// MUI
import {
  AppBar,
  Box,
  IconButton,
  styled,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  GridOnTwoTone,
} from '@mui/icons-material';
// CONSTANTS
import COLORS from 'constants/COLORS';
import LAYOUT from 'constants/LAYOUT';
// COMPONENTS
import AppBarHeaderButtons from './AppBarHeaderButtons';
import AppBarHeaderAccount from './AppBarHeaderAccount';

const { APP_BAR_HEIGHT } = LAYOUT;

export default function AppBarHeader({ handleDrawer }) {
  // -------------------------------------------------------------------------------------------------------------------------------------------------
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <AraAppBar position="fixed">
      <AraToolbar disableGutters sx={{ py: 0, px: 4 }}>
        <IconButton
          color="inherit"
          onClick={handleDrawer}
          edge="start"
          sx={{
            border: '1px solid #E0E3E7',
            borderRadius: '10px',
            mr: isSmScreen ? 1 : 2,
          }}
        >
          <GridOnTwoTone color="primary" fontSize="small" />
        </IconButton>
        <img
          alt="company"
          src="/images/ara-app-logo.webp"
          style={{ height: '25px', width: '98.325px' }}
        />
        <Box sx={{ flexGrow: 1 }} />
        <Box>
          <AppBarHeaderButtons />
          <AppBarHeaderAccount />
        </Box>
      </AraToolbar>
    </AraAppBar>

  );
}

const AraAppBar = styled(AppBar, {

})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  boxShadow: 'none !important',
  height: APP_BAR_HEIGHT,
  borderBottom: `1px solid ${COLORS.divider}`,
  backgroundColor: '#fff',
}));

const AraToolbar = styled(Toolbar, {
})(() => ({
  height: APP_BAR_HEIGHT,
}));
