import _ from 'lodash';

const { utcToZonedTime, format } = require('date-fns-tz');

export const getErrMessageUtil = (err = null) => {
  // Here are all the response returned from BE
  if (err?.response?.data?.meta?.errorMessage) {
    return err.response.data.meta.errorMessage;
  }

  if (err?.response?.data?.message) {
    return err.response.data.message;
  }

  if (err?.response?.data?.errors?.[0]?.message) {
    return err.response.data.errors[0].message;
  }

  // Here are unexpected reponese and timeout response from axios

  if (err?.request && err?.code === 'ECONNABORTED') {
    return 'The request timed out. Please check your internet connection and try again later.';
  }

  if (err?.request) {
    return 'The server is not responding. Please try again later.';
  }

  if (err?.request && !navigator?.onLine) {
    return 'You are currently offline. Please check your internet connection and try again.';
  }

  if (err?.message) {
    return 'The server is not responding. Please try again later.';
  }

  return 'Something went wrong, please try again later';
};

export function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

  return JSON.parse(jsonPayload);
}

export const formatDateUtil = (inputDate, pattern = 'dd MMM yyyy', tz = 'Asia/Kuala_Lumpur') => {
  if (!inputDate) return '';

  const date = new Date(inputDate);
  const timeZone = tz;
  const zonedDate = utcToZonedTime(date, timeZone);

  const output = format(zonedDate, pattern, { timeZone: tz });

  return output;
};

export function getUrlExtUtil(url) {
  return url.split(/[#?]/)[0].split('.').pop().trim();
}

export const formatAmountUtil = (amount) => {
  if (amount == null) return '';

  const formattedAmount = amount.toLocaleString(undefined, { minimumFractionDigits: 2 });

  return formattedAmount;
};

export function checkIsValuesChanged(init, newV) {
  const getChangedValues = (newValues, initialValues) => Object
    .entries(newValues)
    .reduce((acc, [key, value]) => {
      const hasChanged = initialValues[key] !== value;

      if (hasChanged) {
        acc[key] = value;
      }

      return acc;
    }, {});

  const changed = getChangedValues(newV, init);

  return !_.isEmpty(changed);
}

export const blockInvalidCharNumberInput = (e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

// For Mobile Back Button to increase user experience
export const ensureHash = (newHash) => {
  if (window.location.hash) {
    return (
      window.location.href.substring(
        0,
        window.location.href.lastIndexOf(window.location.hash),
      ) + newHash
    );
  }
  return window.location.hash + newHash;
};
