// React imports
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

// Material-UI imports
import {
  Box,
  CircularProgress,
  styled,
  Typography,
} from '@mui/material';

// Store imports - No imports in this section

// Constants & Utilities imports
import COLORS from 'constants/COLORS';
import LAYOUT from 'constants/LAYOUT';

// Components imports
import MicroEmpty from 'components/shared/Micro/MicroEmpty';
import MicroSearchBar from 'components/shared/Micro/MicroSearchBar/MicroSearchBar';
import DraftRecordMicroListBox from './DraftRecordMicroListBox';
import DraftRecordMicroSelectAll from './DraftRecordMicroSelectAll';

// External library imports
import { uniqBy } from 'lodash';
import { useStoreDraftRecord } from 'store/draft-record-store';
import { useInfiniteQueryGetRecords } from 'services/record-api-services';

const PAGE_SIZE = 25;

const NON_LIST_HEIGHT = LAYOUT.APP_BAR_HEIGHT + LAYOUT.MICRO_HEADER_HEIGHT;

export default function RecordMicro() {
  const { draftRecordsStore, setDraftRecordsStore } = useStoreDraftRecord();
  const [search, setSearch] = useState('');

  const {
    status, data, fetchNextPage, hasNextPage, isFetching, refetch,
  } = useInfiniteQueryGetRecords({ recordStatus: 'draft', search, pageSize: PAGE_SIZE });

  useEffect(() => {
    refetch();
  }, [search]);

  useEffect(() => {
    const records = data?.pages.reduce((acc, page) => [...acc, ...page.records], []);
    const uniqRecords = uniqBy(records, '_id');
    setDraftRecordsStore(uniqRecords);
  }, [data]);

  const renderContent = () => {
    if (status === 'pending') {
      return (
        <Box display="flex" alignItems="center" justifyContent="center">
          <CircularProgress size={20} sx={{ mt: 4 }} />
        </Box>
      );
    }

    if (status === 'error') {
      return (
        <MicroEmpty
          title="Oops! Couldn't retrieve draft invoices. Please try again."
          onClick={() => {
            refetch();
          }}
        />
      );
    }

    if (!draftRecordsStore?.length) {
      return <MicroEmpty title={'There are currently no invoices'} />;
    }

    return (
      <>

        <DraftRecordMicroSelectAll />
        <Box height={5} />
        <InfiniteScroll
          height={window.innerHieght - NON_LIST_HEIGHT}
          dataLength={draftRecordsStore ? draftRecordsStore.length : 0}
          endMessage={(
            <Typography sx={{
              fontWeight: 'bold', textAlign: 'center', mb: 3, mt: 3,
            }}
            >
              Yay! You have seen it all
            </Typography>
          )}
          next={() => fetchNextPage()}
          hasMore={hasNextPage && !isFetching}
          loader={<Box sx={{ textAlign: 'center', my: 2 }}><CircularProgress /></Box>}
          scrollableTarget="scrollableDiv"
        >
          {draftRecordsStore.map((r) => <DraftRecordMicroListBox record={r} key={r._id} />)}
        </InfiniteScroll>
      </>
    );
  };

  return (
    <MicroContainer>
      <MicroHeader>
        <MicroTitle>
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            {'Draft Invoices'}
          </Typography>
        </MicroTitle>
      </MicroHeader>
      <MicroList id="scrollableDiv">
        <Box sx={{ height: '20px' }} />
        <MicroSearchBar search={search} setSearch={setSearch} placeholder="Search by invoice #" timeout={500} />
        <Box sx={{ height: `${LAYOUT.MICRO_HEADER_SPACING}px` }} />
        {renderContent()}
      </MicroList>
    </MicroContainer>
  );
}

const MicroContainer = styled('div')(() => ({
  height: '100%',
  width: '100%',
  backgroundColor: COLORS.secondaryBG,
  boxShadow: 'none',

}));

const MicroHeader = styled(
  Box,
)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  margin: 0,
  zIndex: 3,
  borderBottom: `1px solid ${COLORS.divider}`,
  height: `${LAYOUT.MICRO_HEADER_HEIGHT}px`,
  boxShadow: 'none',
  backgroundColor: '#fff',
  transition: theme.transitions.create(['boxShadow', 'backgroundColor'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.standard,
  }),
}));

const MicroTitle = styled(Box)(({ theme }) => ({
  height: LAYOUT.MICRO_HEADER_HEIGHT,
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(0, 0),

}));

const MicroList = styled(Box)(({ theme }) => ({
  height: `calc(100vh - ${NON_LIST_HEIGHT}px)`,
  overflowY: 'auto',
  overflowX: 'hidden',
  padding: theme.spacing(0, 2),

}));
