import * as Yup from 'yup';

export const BizOnboardSchema = Yup.object({
  name: Yup
    .string()
    .required('Business Name is required')
    .min(2, 'Business name should be more than 2 characters')
    .max(50, 'FiBusinessrst name should be less than 50 characters'),
  short_name: Yup
    .string()
    .required('Business Short Name is required')
    .min(2, `Business Short Name should be at least ${2} characters`)
    .max(20, `Please keep the Business Short Name under ${20} characters`),
  type: Yup
    .string()
    .required('Business Type is required'),
  phone_number: Yup
    .string()
    .required('Phone Number is required'),
  office_number: Yup
    .string()
    .notRequired(),
  address_line_1: Yup
    .string()
    .required('Address Line 1 is required'),
  address_line_2: Yup
    .string()
    .notRequired(),
  city: Yup
    .string()
    .required('City is required'),
  state: Yup
    .string()
    .required('State is required'),
  postcode: Yup
    .string()
    .required('Postcode is required'),

});
