import React from 'react';
import COLORS from 'constants/COLORS';
import {
  Box, Grid, Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { InfoOutlined } from '@mui/icons-material';
import PopoverTooltip from './PopoverTooltip';

export default function ContentGrid({
  label, value, valueColor = COLORS.labelPrimary, tooltip = '',
}) {
  return (
    <PopoverTooltip tooltip={tooltip}>
      <Grid
        item
        xs={12}
        sm={4}
        md={4}
        lg={3}
        sx={{ display: 'flex', flexDirection: { xs: 'row', sm: 'column' }, justifyContent: { xs: 'space-between', sm: 'flex-start' } }}
      >
        <Box display="flex" alignItems="center" flexDirection="row">
          <Typography variant="body2" sx={{ color: grey[600] }}>
            {label}
          </Typography>
          {tooltip != null && tooltip !== ''
            && <InfoOutlined fontSize="inherit" color="primary" sx={{ ml: 0.5 }} />}
        </Box>

        <Typography sx={{ fontWeight: 600, color: valueColor, wordBreak: 'break-word' }}>{value}</Typography>
      </Grid>
    </PopoverTooltip>
  );
}
