import React, { useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
// MUI
import {
  Grid,
  Typography,
  styled,
  Container,
  Box,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
// CONSTANTS
import COLORS from 'constants/COLORS';
import isStrongPassword from 'validator/lib/isStrongPassword';
// COMPONENTS
import { AuthUpdatePasswordAPI } from 'services/auth-api-services';

function SettingsNanoChangePassword({ refetch }) {
  const { enqueueSnackbar } = useSnackbar();

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  Yup.addMethod(Yup.string, 'isYupStrongPassword', isStrongPassword);

  const ChangePasswordSchema = Yup.object().shape({
    currentPassword: Yup.string().required('Current Password is required'),
    newPassword: Yup.string()
      .required('Password is required')
      .test(
        'is-strong-password',
        'Password should have 8 or more characters with a mix of upper and lower case letters, numbers, and symbols.',
        (value) => isStrongPassword(value || ''),
      ),
  });

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
    },
    validationSchema: ChangePasswordSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const [data] = await AuthUpdatePasswordAPI(values);
      setSubmitting(false);

      if (!data) return;

      enqueueSnackbar('Password changed successfully', { variant: 'success' });
      refetch();
    },
  });
  const {
    errors, touched, getFieldProps, isSubmitting, handleSubmit,
  } = formik;

  return (

    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <NanoPaperContainer maxWidth="sm" disableGutters>
          <NanoTitle container spacing={2}>
            <Grid item xs>
              <Typography variant="h6">{'Change password'}</Typography>
              <Typography
                variant="body1"
                sx={{ color: COLORS.labelSecondary }}
              >
                Please enter your current password and new password to continue
              </Typography>
            </Grid>
          </NanoTitle>

          <TextField
            fullWidth
            type={showCurrentPassword ? 'text' : 'password'}
            label={'Current Password'}
            data-cy="current-password"
            error={touched.currentPassword && Boolean(errors.currentPassword)}
            helperText={touched.currentPassword && errors.currentPassword}
            {...getFieldProps('currentPassword')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    tabIndex={-1}
                    aria-label="toggle password visibility"
                    onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                  >
                    {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box sx={{ height: 24 }} />
          <TextField
            fullWidth
            type={showNewPassword ? 'text' : 'password'}
            label={'New Password'}
            data-cy="new-password"
            error={touched.newPassword && Boolean(errors.newPassword)}
            helperText={touched.newPassword && errors.newPassword}
            {...getFieldProps('newPassword')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    tabIndex={-1}
                    aria-label="toggle password visibility"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                  >
                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box sx={{ height: 24 }} />
          <LoadingButton
            size="medium"
            type="submit"
            data-cy="change-password-btn"
            variant="contained"
            loading={isSubmitting}
            fullWidth
          >
            Change Password
          </LoadingButton>

        </NanoPaperContainer>
      </Form>
    </FormikProvider>

  );
}

const NanoPaperContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(4, 6),
  background: '#fff',
  borderRadius: 2,
  position: 'relative',
  marginTop: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    border: `1px solid ${COLORS.divider}`,
    boxShadow: '0 8px 10px rgba(112,144,176,0.2)',
    padding: theme.spacing(4),

  },
}));

const NanoTitle = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(0, 0, 4, 0),
}));

export default SettingsNanoChangePassword;
