// REACT + OTHERS
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

// MUI
import {
  Box, Button, IconButton, InputAdornment, TextField, Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Visibility, VisibilityOff } from '@mui/icons-material';

// STORES
import { AuthSigninAPI } from 'services/auth-api-services';
import { useStoreAuth } from 'store/auth-store';

// UTIL & CONST
import * as Yup from 'yup';
import COLORS from 'constants/COLORS';
import { Form, FormikProvider, useFormik } from 'formik';

const SigninSchema = Yup.object().shape({
  email: Yup
    .string()
    .email('Please enter a valid email address')
    .required('Email address is required'),
  password: Yup
    .string()
    .required('Password is required'),
});

export default function AuthSignInScreen() {
  const { email } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: email || '',
      password: '',
    },
    validationSchema: SigninSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const [, data] = await AuthSigninAPI(values);
      setSubmitting(false);

      if (!data?.token) return;
      useStoreAuth.getState().setUserSessionKeyStore(data.token);
      enqueueSnackbar('Login Succesfully', { variant: 'success' });
      navigate('/');
    },
  });

  const {
    errors, touched, handleSubmit, getFieldProps, isSubmitting,
  } = formik;

  return (
    <>
      <Box sx={{ mb: 5 }}>
        <Typography variant="h4" gutterBottom>
          Welcome to ARA
        </Typography>
        <Typography color="textSecondary" variant="subtitle1" sx={{ fontWeight: 500 }}>
          Enter your password below to sign in.
        </Typography>
      </Box>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

          <TextField
            fullWidth
            data-cy="email"
            type="email"
            disabled
            label={'Email address'}
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
            {...getFieldProps('email')}
            InputProps={{
              autoComplete: 'email',
            }}
          />
          <Box sx={{ mb: 3 }} />

          <TextField
            fullWidth
            autoFocus
            data-cy="password"
            type={showPassword ? 'text' : 'password'}
            label={'Password'}
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => { setShowPassword(!showPassword); }} edge="end">
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
              autoComplete: 'current-password',
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
          <Box
            sx={{
              my: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box />
            <Typography
              variant="overline"
              sx={{
                cursor: 'pointer',
                color: COLORS.primary,
                textDecoration: 'underline',
                fontWeight: 700,
              }}
              onClick={() => {
                navigate(`/auth/forgot-password/${email}`);
              }}
            >
              Forgot password
            </Typography>

          </Box>

          <LoadingButton
            fullWidth
            size="large"
            data-cy="signin_btn"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Sign In

          </LoadingButton>
          <Button
            variant="contained"
            color="tertiary"
            sx={{ my: 3, fontWeight: 'bold' }}
            fullWidth
            disabled={isSubmitting}
            onClick={() => {
              navigate('/auth');
            }}
          >
            Cancel
          </Button>
        </Form>
      </FormikProvider>
    </>
  );
}
