/* eslint-disable no-param-reassign */
import React, { useState, useCallback, useEffect } from 'react';
import {
  Form, FormikProvider, setNestedObjectValues, useFormik,
} from 'formik';
import { enqueueSnackbar } from 'notistack';

// UTIL
import { CustomerFormikInitialValues } from 'util/Customer/cust-formik-initial-values';
import { CustomerSchema } from 'util/Customer/cust-validation';

// APIS
import {
  useMutationUpdateOneCustomerByCustomerId,
  useQueryGetOneCustomerByBizIdAndCustId,
} from 'services/customer-api-services';

// MUI
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
  styled,
} from '@mui/material';

// UTIL & CONST
import COLORS from 'constants/COLORS';

import CustomerNanoInputContactDetail from 'components/Customer/CustomerInput/CustomerInputContactDetail';
import CustomerNanoInputBusinessDetail from 'components/Customer/CustomerInput/CustomerInputBusinessDetail';
import CustomerNanoInputAddressDetail from 'components/Customer/CustomerInput/CustomerInputAddressDetail';
import { useParams } from 'react-router-dom';
import { Business, PersonOutlineOutlined } from '@mui/icons-material';
import CustomerDetailActions from 'components/Customer/CustomerDetail/CustomerDetailActions';
import { LoadingButton } from '@mui/lab';
import { checkIsValuesChanged } from 'util/util';
import { useStoreCust } from 'store/cust-mgmt-store';
import MicroEmpty from 'components/shared/Micro/MicroEmpty';
import ResponsiveNanoWrapperV2 from 'components/shared/ResponsiveNanoWrapper/ResponsiveNanoWrapperV2';

export default function CustomerDetailScreen() {
  const { customerId } = useParams();

  const { upsertOneCustomersStore } = useStoreCust();

  const {
    isLoading,
    error,
    customer,
    refetch,
    isRefetchError,

  } = useQueryGetOneCustomerByBizIdAndCustId(customerId);

  useEffect(() => {
    if (customer) {
      upsertOneCustomersStore(customer);
    }
  }, [customer]);

  const renderContent = () => {
    if (isLoading) {
      return (
        <Box display="flex" alignItems="center" justifyContent="center">
          <CircularProgress size={20} sx={{ mt: 4 }} />
        </Box>
      );
    }

    if (error || !customer || isRefetchError) {
      return (
        <MicroEmpty
          title="Oops! Couldn't retrieve customer. Please try again."
          onClick={() => {
            refetch();
          }}
        />
      );
    }

    return <Detail customer={customer} refetch={refetch} />;
  };

  return (
    <ResponsiveNanoWrapperV2>
      {renderContent()}
    </ResponsiveNanoWrapperV2>
  );
}

function Detail({ customer, refetch }) {
  const [isEditing, setIsEditing] = useState(false);

  const CustomerSchemaValidation = useCallback(() => CustomerSchema(), []);

  const {
    asyncTriggerMut,
  } = useMutationUpdateOneCustomerByCustomerId(customer._id);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: CustomerFormikInitialValues(customer),
    validationSchema: CustomerSchemaValidation,
    onSubmit: async (values, { setSubmitting }) => {
      if (!checkIsValuesChanged(values, formik.initialValues)) {
        enqueueSnackbar('There are no changes to be updated', { variant: 'info' });
        setSubmitting(false);
        return;
      }

      if (values?.phone_number === '') values.phone_number = null;
      if (values?.email === '') values.email = null;

      setSubmitting(true);
      const data = await asyncTriggerMut(values);
      setSubmitting(false);
      if (!data) return;

      enqueueSnackbar('Customer details has been successfully updated', { variant: 'success' });
      setIsEditing(false);
      refetch();
    },
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = await formik.validateForm();
    if (Object.keys(validationErrors).length > 0) {
      formik.setTouched(setNestedObjectValues(validationErrors, true));
      enqueueSnackbar('Form Validation Error, please check your input and try again.', { variant: 'warning' });
      return;
    }

    handleSubmit();
  };

  const {
    handleSubmit, isSubmitting, handleReset,
  } = formik;

  return (

    <Box sx={{ p: 4 }}>
      <FormikProvider value={formik}>
        <Form
          autoComplete="off"
          noValidate
          onSubmit={onSubmit}
        >
          <NanoPaperContainer
            maxWidth="sm"
            disableGutters
          >
            <NanoTitle container spacing={2}>
              {isEditing ? (
                <Grid item xs={12} md>
                  <Typography variant="h6">Editing Customer</Typography>
                </Grid>
              )
                : (
                  <Grid item xs={12} md>
                    <Box display={'flex'} alignItems={'center'} flexDirection={'row'}>
                      {customer?.user_type === 'individual' ? <PersonOutlineOutlined sx={{ fontSize: 20, mr: 1 }} /> : <Business sx={{ fontSize: 20, mr: 1 }} />}

                      <Typography variant="h6">
                        {customer?.business_name || `${customer?.first_name} ${customer?.last_name}`}
                      </Typography>

                    </Box>
                    <Typography variant="body1" sx={{ color: COLORS.labelSecondary }}>{customer?.email || customer?.phone_number}</Typography>
                  </Grid>
                )}
              {isEditing ? (
                <Grid item xs={12} md="auto">
                  <Box display="flex" alignItems="center" flexDirection="row" height={'100%'}>
                    <LoadingButton
                      type="submit"
                      data-cy="cust-save-btn"
                      variant="contained"
                      size="small"
                      loading={isSubmitting}
                    >
                      Update
                    </LoadingButton>
                    <LoadingButton
                      sx={{ ml: 2 }}
                      type="button"
                      variant="outlined"
                      size="small"
                      color="secondary"
                      onClick={() => {
                        setIsEditing(false);
                        handleReset();
                      }}
                    >
                      Cancel
                    </LoadingButton>
                  </Box>
                </Grid>
              )
                : <CustomerDetailActions customer={customer} setIsEditing={setIsEditing} />}
            </NanoTitle>
            <CustomerNanoInputBusinessDetail isEditing={isEditing} formik={formik} />
            <Box sx={{ height: 36 }} />
            <CustomerNanoInputContactDetail isEditing={isEditing} formik={formik} />
            <Box sx={{ height: 36 }} />
            <CustomerNanoInputAddressDetail isEditing={isEditing} formik={formik} />
          </NanoPaperContainer>
        </Form>
      </FormikProvider>
    </Box>

  );
}

const NanoPaperContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(0),
  background: '#fff',
  borderRadius: 2,
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    border: `1px solid ${COLORS.divider}`,
    boxShadow: '0 8px 10px rgba(112,144,176,0.2)',
    padding: theme.spacing(4),
  },

}));

const NanoTitle = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(0, 0, 4, 0),
}));
