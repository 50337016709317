import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
// STORES
import { useStoreAuth } from 'store/auth-store';
import { useStoreCust } from 'store/cust-mgmt-store';
import { useStoreShared } from 'store/shared-store';

export default function useHandleLogoutHook() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  // -------------------------------------------------------------------------------------------------------------------------------------------------

  const resetAuthStorageAndStore = useStoreAuth((s) => s.resetAuthStorageAndStore);
  const resetCustMgmtStore = useStoreCust((s) => s.resetCustMgmtStore);
  const { resetSharedPayexStore, resetSharedModalStore } = useStoreShared();

  return () => {
    window.location.reload();

    resetAuthStorageAndStore();
    resetCustMgmtStore();
    resetSharedPayexStore();
    resetSharedModalStore();
    navigate('/auth');
    enqueueSnackbar('You have been logged out', { variant: 'success' });
  };
}
