import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
// MUI
import {
  Grid, styled,
} from '@mui/material';
// CONST & UTIL
import COLORS from 'constants/COLORS';
import LAYOUT from 'constants/LAYOUT';
// COMPS
import CustomerManagementMicro from 'components/Customer/CustomerMicro/CustomerMicro';
import OnboardingWrapper from 'components/shared/Onboarding/OnboardingWrapper';

export default function CustomerScreen() {
  const { status } = useParams();

  return (
    <OnboardingWrapper>
      <Grid
        container
        maxWidth="false"
        sx={{
          height: `calc(100vh - ${LAYOUT.APP_BAR_HEIGHT}px)`,
          backgroundColor: COLORS.secondaryBG,
        }}
      >
        <MicroGrid item>
          <CustomerManagementMicro
            status={status}
          />
        </MicroGrid>
        <Outlet />
      </Grid>
    </OnboardingWrapper>
  );
}

const MicroGrid = styled(Grid)(({ theme }) => ({
  width: '100%',
  zIndex: 2,

  [theme.breakpoints.up('md')]: {
    width: LAYOUT.MICRO_WIDTH,
    borderRight: `1px solid ${COLORS.divider}`,
  },

}));
