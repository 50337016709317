import { create } from 'zustand';

const initialAuthState = {
  userProfileStore: null,
  businessProfileStore: null,
  userSessionKeyStore: '',
  isOpenSetPasswordModalStore: false,
};

export const useStoreAuth = create((set) => ({
  ...initialAuthState,
  resetAuthStorageAndStore: () => {
    localStorage.removeItem('session_token');
    localStorage.removeItem('last_business_profile_id');
    set({
      ...initialAuthState,
    });
  },

  setUserProfileStore: (obj) => set({ userProfileStore: obj }),

  setBusinessProfileStore: (obj) => set({ businessProfileStore: obj }),

  setUserSessionKeyStore: async (str) => {
    localStorage.setItem('session_token', str);
    set({ userSessionKeyStore: str });
  },

  setIsOpenSetPasswordModalStore: (bool) => set({ isOpenSetPasswordModalStore: bool }),

}));
