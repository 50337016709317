// REACT
import React, { useCallback, useEffect, useState } from 'react';

// MUI COMPONENTS
import TextField from '@mui/material/TextField';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  Tooltip,
  Typography,
} from '@mui/material';

// MUI ICONS
import {
  Add,
  Business,
  PermIdentityOutlined,
  Search,
} from '@mui/icons-material';

// CONSTANTS
import debounce from 'lodash/debounce';
import _ from 'lodash';
import COLORS from 'constants/COLORS';
import { grey } from '@mui/material/colors';

// APIS
import { CustomerGetManyByBusinessIdAPI } from 'services/customer-api-services';
import { useNavigate } from 'react-router-dom';
import { PATH_USER } from 'constants/PATHS';

function getHighlightedText(text, highlight) {
  // Split on highlight term and include term into parts, ignore case
  const parts = text.split(new RegExp(`(${escapeRegExp(highlight)})`, 'gi'));
  /* eslint-disable react/no-array-index-key */
  return (
    <span>
      {' '}
      {parts.map((part, i) => (
        <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { backgroundColor: COLORS.ara0 } : {}}>
          {part}
        </span>
      ))}
      {' '}
    </span>
  );
}

// Helper function to escape special characters in a regular expression
function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export default function DraftRecordInputCustomerSearch({
  formik,
}) {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = React.useState('');

  const getOptionsDelayed = useCallback(
    debounce(async (searchStr) => {
      setIsLoading(true);
      const [apiStatus, data] = await CustomerGetManyByBusinessIdAPI({
        isActive: true, pageSize: 5, pageNumber: 1, search: searchStr,
      });

      setIsLoading(false);

      if (!apiStatus || !data) return;

      setOptions([...data.customers]);
    }, 600),
    [],
  );

  useEffect(() => {
    setOptions([]);
    setIsLoading(true);
    getOptionsDelayed(inputValue);
  }, [inputValue, getOptionsDelayed]);

  const onSelectOption = (customer = null) => {
    if (!customer || _.isEmpty(customer) || !_.isObject(customer)) {
      return;
    }
    formik.setFieldValue('customer_id', customer?._id);
  };

  const onRemoveSelect = () => {
    formik.setFieldValue('customer_id', null);
  };

  const handleAddNewCustomer = () => {
    navigate(PATH_USER.customers.create);
  };

  return (
    <Grid container name="create-customer-grid-search_payer" sx={{ py: 2 }} rowSpacing={2} columnSpacing={4}>
      <Grid item xs={12} sm>
        <Autocomplete
          name="create-customer-email_address"
          fullWidth
          freeSolo
          options={options}
          onChange={(_e, value) => {
            onSelectOption(value);
          }}
          getOptionLabel={(option) => {
            if (option.email) { return option.email; }
            if (typeof option === 'string') { return option; }
            return '';
          }}
          renderOption={(props, option) => (option?.user_type === 'individual' ? (
            <li {...props} key={option.email + option.first_name + option.last_name}>
              <Tooltip arrow title="Individual customer" placement="top">
                <PermIdentityOutlined sx={{ fontSize: 14, mr: 1 }} />
              </Tooltip>
              <Typography sx={{ fontWeight: 600 }}>
                {getHighlightedText(`${option.first_name} ${option.last_name}`, inputValue || '')}
              </Typography>
              <Box sx={{ mx: 0.5 }}>/</Box>
              <Typography>
                {getHighlightedText(`${option?.email || '-'} / ${option?.phone_number || '-'}`, inputValue || '')}
              </Typography>
            </li>
          ) : (
            <li {...props} key={option.email + option.business_name}>
              <Tooltip arrow title="Business customer" placement="top">
                <Business sx={{ fontSize: 14, mr: 1 }} />
              </Tooltip>

              <Typography sx={{ fontWeight: 600 }}>
                {getHighlightedText(`${option.business_name}`, inputValue || '')}
              </Typography>
              <Box sx={{ mx: 0.5 }}>/</Box>
              <Typography>
                {getHighlightedText(`${option?.email || '-'} / ${option?.phone_number || '-'}`, inputValue || '')}
              </Typography>
            </li>
          ))}
          filterOptions={(x) => x} // disable filtering on client
          loading={isLoading}
          inputValue={inputValue}
          onInputChange={(e, newInputValue) => {
            onRemoveSelect();
            setInputValue(newInputValue);
          }}
          loadingText={(
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <CircularProgress size={20} color="neutral" sx={{ mr: 2 }} />
              <Typography>Retrieving Active Customers</Typography>
            </Box>
          )}
          size="small"
          renderInput={(params) => (
            <TextField
              {...params}
              data-cy="email"
              label=""
              placeholder="Search by customer name, business name, phone number or email"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start" sx={{ width: 15 }}>
                    <Search sx={{ color: grey[500], fontSize: 18, ml: 0.5 }} />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm="auto">
        <Box sx={{ height: '100%' }} display="flex" alignItems="center">
          <Button onClick={handleAddNewCustomer} startIcon={<Add />} size="small" sx={{ textTransform: 'none' }}>
            Add customer
          </Button>
        </Box>
      </Grid>
      {!isLoading && options?.length < 1
        && (
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" sx={{ width: '100%', backgroundColor: '#f2f4f7', p: 1 }}>
              <Typography variant="body2">
                No result found, try other name or
                {' '}
                <Typography
                  component="span"
                  sx={{
                    fontWeight: 600, color: COLORS.primary, textDecoration: 'underline', cursor: 'pointer',
                  }}
                  onClick={handleAddNewCustomer}
                >
                  <q>add new customer</q>
                </Typography>
                {' '}
                instead
              </Typography>

            </Box>

          </Grid>
        )}
    </Grid>
  );
}
