import React, {
  forwardRef, useCallback, useEffect, useState,
} from 'react';
import { useSnackbar, SnackbarContent } from 'notistack';

import {
  Box, CircularProgress, IconButton, Typography,
} from '@mui/material';
import COLORS from 'constants/COLORS';
import { Close, DoneAll } from '@mui/icons-material';

function getFileExtensionFromBlob(blob) {
  // Step 1: Check if the Blob contains information about the file type
  if (blob.type) {
    // Step 2: Extract the file extension from the Blob's type property
    const fileTypeParts = blob.type.split('/');
    if (fileTypeParts.length === 2) {
      const fileExtension = fileTypeParts[1];
      return fileExtension;
    }
  }

  // If no file extension is found, return an empty string or null, depending on your needs.
  return '';
}

const DownloadOneFileSnackbar = forwardRef(
  ({ id, url, fileName }, ref) => {
    if (!url || !fileName) {
      return (
        <SnackbarContent ref={ref}>
          <Box
            backgroundColor={COLORS.ara0}
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            width="100%"
            justifyContent={'space-between'}
            sx={{ boxShadow: 2, py: 1, px: 2 }}
          >

            <Typography>
              Something went wrong, file not found
            </Typography>
          </Box>
        </SnackbarContent>
      );
    }

    useEffect(() => {
      setIsDownloading(true);
      fetch(url)
        .then((blobRes) => blobRes.blob())
        .then((blob) => {
          const blobObjectURL = URL.createObjectURL(blob);
          const downloadLink = document.createElement('a');
          downloadLink.href = blobObjectURL;
          const blobFileExtension = getFileExtensionFromBlob(blob);
          downloadLink.download = `${fileName}.${blobFileExtension}`;
          downloadLink.click();
          URL.revokeObjectURL(blobObjectURL);
        }).catch((blobErr) => {
          console.error(blobErr);
          window.open(url);
        })
        .finally(() => {
          setIsDownloading(false);
        });
    }, []);

    const [isDownloading, setIsDownloading] = useState(true);
    const { closeSnackbar } = useSnackbar();

    const handleDismiss = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    return (
      <SnackbarContent ref={ref}>

        <Box
          backgroundColor={COLORS.ara0}
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          width="100%"
          justifyContent={'space-between'}
          sx={{
            boxShadow: 2, py: 1.5, px: 2, borderRadius: 1,
          }}
        >
          {isDownloading
            ? (
              <Typography fontWeight={700}>
                {`Downloading: ${fileName}`}
              </Typography>
            )
            : (
              <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                <DoneAll sx={{ mr: 1 }} fontSize="small" />
                <Typography fontWeight={700}>
                  {`Download Complete: ${fileName}`}
                </Typography>
              </Box>
            )}
          {isDownloading ? <CircularProgress size={20} color="inherit" sx={{ ml: 1.5 }} />
            : (
              <IconButton
                size="small"
                onClick={handleDismiss}
                sx={{ ml: 1.5 }}
              >
                <Close fontSize="small" />
              </IconButton>
            )}
        </Box>

      </SnackbarContent>
    );
  },
);

DownloadOneFileSnackbar.displayName = 'DownloadOneFileSnackbar';

export default DownloadOneFileSnackbar;
