/* eslint-disable react/no-unstable-nested-components */
import React, { useRef } from 'react';
import { MaterialDesignContent, SnackbarProvider as NotistackProvider } from 'notistack';
// @mui
import { alpha, styled, useTheme } from '@mui/material/styles';
import { Box, IconButton } from '@mui/material';
//

import Iconify from './Iconify';
import DownloadOneFileSnackbar from './DownloadOneFileSnackbar';

// ----------------------------------------------------------------------

export default function AraSnackbarProvider({ children }) {
  const theme = useTheme();
  const notistackRef = useRef(null);

  const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    '&.notistack-MuiContent': {
      fontWeight: 600,
      fontFamily: 'Mulish ,sans-serif',
      width: '100%',
      padding: theme.spacing(0.5, 2),
      margin: theme.spacing(0.4, 0),
      boxShadow: `0 8px 16px 0 ${alpha('#919EAB', 0.16)}`,
      borderRadius: theme.shape.borderRadius,
      color: theme.palette.grey[800],
      backgroundColor: '#fff',
      [theme.breakpoints.up('md')]: {
        minWidth: 240,
      },
    },
  }));

  const onClose = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <NotistackProvider
      ref={notistackRef}
      dense
      maxSnack={5}
      preventDuplicate
      autoHideDuration={4000}
      TransitionComponent={undefined}
      variant="success" // Set default variant
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
        info: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
        downloadOneFile: DownloadOneFileSnackbar,
      }}
      iconVariant={{
        info: <SnackbarIcon icon="eva:info-fill" color="info" />,
        success: <SnackbarIcon icon="eva:checkmark-circle-2-fill" color="success" />,
        warning: <SnackbarIcon icon="eva:alert-triangle-fill" color="warning" />,
        error: <SnackbarIcon icon="eva:alert-circle-fill" color="error" />,
      }}
      // With close as default
      action={(key) => (
        <IconButton size="small" onClick={onClose(key)} sx={{ p: 0.5 }}>
          <Iconify icon="eva:close-fill" />
        </IconButton>
      )}
    >
      {children}
    </NotistackProvider>
  );
}

// ----------------------------------------------------------------------

function SnackbarIcon({ icon, color }) {
  return (
    <Box
      component="span"
      sx={{
        mr: 1.5,
        width: 40,
        height: 40,
        display: 'flex',
        borderRadius: 1.5,
        alignItems: 'center',
        justifyContent: 'center',
        color: `${color}.main`,
        bgcolor: (theme) => alpha(theme.palette[color].main, 0.16),
      }}
    >
      <Iconify icon={icon} width={24} />
    </Box>
  );
}
