import React from 'react';
// MUI
import {
  Box, CircularProgress,
} from '@mui/material';
// CONST AND UTIL
// COMPONENTS

import SettingsNanoChangePassword from 'components/Settings/SettingsNano/SettingsNanoChangePassword';
import SettingsNanoSetPassword from 'components/Settings/SettingsNano/SettingsNanoSetPassword';
// STORES
import { useStoreAuth } from 'store/auth-store';
// APIS

import MicroEmpty from 'components/shared/Micro/MicroEmpty';
import { getAPI } from 'util/API';
import { useQuery } from '@tanstack/react-query';

export default function SettingsPasswordScreen() {
  const { userProfileStore } = useStoreAuth();

  const API = getAPI('auth');
  const url = '/auth/check-email';

  const {
    isLoading, error, data, refetch, isRefetchError, isRefetching,
  } = useQuery({
    queryKey: ['checkAuthEmail'],
    queryFn: () => API.post(url, { email: userProfileStore?.email })
      .then((res) => res?.data?.data?.status || null)
      .catch((err) => {
        throw new Error(err); // Rethrow the error so React Query can catch it
      }),
    enabled: !!userProfileStore?.email,
  });

  if (isLoading || isRefetching) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress size={20} sx={{ mt: 4 }} />
      </Box>
    );
  }

  if (error || !data || isRefetchError) {
    return (
      <MicroEmpty
        title="Oops! Couldn't retrieve settings. Please try again."
        onClick={() => {
          refetch();
        }}
      />
    );
  }

  if (data === 'SIGN_IN') return <SettingsNanoChangePassword refetch={refetch} />;

  if (data === 'MAGIC_LINK') return <SettingsNanoSetPassword refetch={refetch} />;

  return (
    <MicroEmpty
      title="Oops! Something went wrong. Please contact support."
    />
  );
}
