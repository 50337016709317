import React from 'react';
import {
  Button, keyframes, styled, Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function NotFoundScreen404() {
  const navigate = useNavigate();

  return (
    <div style={styles.container}>

      <Typography variant="h1" sx={{ color: '#fff', fontWeight: 900 }}>404</Typography>
      <Typography variant="h2" sx={{ color: '#fff', marginTop: '20px', fontWeight: 'bold' }}>Lost In Ara?</Typography>
      <Typography variant="h5" sx={{ color: '#fff', marginTop: '20px', textAlign: 'center' }}>Hmm, looks like the page does not exist.</Typography>
      <Astronaut src={'/images/astronaut.svg'} alt="404" />
      <Planet src={'/images/planet.svg'} alt="404" />
      <Button
        variant="outlined"
        color="error"
        sx={{
          height: 50, fontSize: '20px', color: '#fff', borderColor: '#fff',
        }}
        onClick={() => {
          navigate('/', { replace: true });
        }}
      >
        Back to home
      </Button>

    </div>
  );
}

const spin = keyframes`
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
`;

const Astronaut = styled('img')(() => ({
  minWidth: '43px',
  width: '3vw',
  marginTop: '40px',
  marginLeft: '300px',
  animation: `${spin} 4.5s infinite linear`,
}));

const Planet = styled('img')(() => ({
  width: '40vw',
  maxHeight: '50vh',
  maxWidth: '600px',
  minWidth: '200px',
}));

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#24344c',
    width: '100vw',
    height: '100vh',
    textAlign: 'center',
  },

};
