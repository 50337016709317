import React from 'react';
import { enqueueSnackbar } from 'notistack';
// COMPONENTS
import AlertDialog from 'components/shared/Dialogs/AlertDialog';
// APIS
import { Box } from '@mui/material';
import { useStoreRecord } from 'store/record-store';
import { useMutationVoidRecordsAPI } from 'services/record-api-services';
import { useNavigate, useParams } from 'react-router-dom';
import { PATH_USER } from 'constants/PATHS';
import { useQueryClient } from '@tanstack/react-query';

export default function RecordVoidDialog() {
  const {
    voidRecordIdsStore,
  } = useStoreRecord();

  if (!voidRecordIdsStore?.length) return <Box />;

  return <Dialog />;
}

function Dialog() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { status, recordId } = useParams();
  const {
    voidRecordIdsStore, setVoidRecordIdsStore, removeOneSelectedRecordIds, removeOneRecordsStoreById,
  } = useStoreRecord();

  const {
    mutIsPending,
    triggerMut,
  } = useMutationVoidRecordsAPI();

  const handleVoidManyInvoices = () => {
    triggerMut(voidRecordIdsStore, {
      onSuccess: () => {
        enqueueSnackbar(`Succesfully voided ${voidRecordIdsStore.length} invoice(s).`, { variant: 'success' });

        voidRecordIdsStore.forEach((id) => {
          removeOneSelectedRecordIds(id);
          removeOneRecordsStoreById(id);
        });

        queryClient.invalidateQueries({
          queryKey: ['getRecordMetadata'],
        });

        setVoidRecordIdsStore([]);
        if (recordId) {
          navigate(PATH_USER.invoices.root(status));
        }
      },
    });
  };

  const handleClose = () => {
    if (mutIsPending) return;
    setVoidRecordIdsStore([]);
  };

  return (
    <AlertDialog
      open
      setOpen={(bool) => {
        if (bool === false) handleClose();
      }}
      isLoading={mutIsPending}
      title={`Mark ${voidRecordIdsStore?.length} Invoice(s) as Void?`}
      message="Mark the selected invoice(s) as void? This invoice will be moved to Voided and no longer will be shown to the payer."
      actions={[
        {
          text: `Mark ${voidRecordIdsStore?.length} Invoice(s) as Void`,
          color: 'secondary',
          onClick: handleVoidManyInvoices,
        },
        {
          text: 'Cancel',
          color: 'tertiary',
          variant: 'contained',
          onClick: handleClose,
        },
      ]}
    />
  );
}
