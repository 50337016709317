import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { useStoreAuth } from 'store/auth-store';
// STORES
import { getAPI, getSecurePayeeAPI } from 'util/API';
import { getErrMessageUtil } from 'util/util';

export const BusinessCreateOneVerifiedAPI = async (body) => {
  const API = getSecurePayeeAPI('user');
  const url = '/business/create/one/verified';

  try {
    const newBody = { ...body };

    const res = await API.post(url, newBody);
    const { data } = res.data;
    return [true, data];
  } catch (err) {
    const errMessage = getErrMessageUtil(err);
    enqueueSnackbar(errMessage, { variant: 'error' });
    return [null, errMessage];
  }
};

export const BusinessUpdateNotificationSettingsAPI = async (body = { sms: true, email: true }) => {
  const { businessProfileStore } = useStoreAuth.getState();
  const API = getSecurePayeeAPI('user');

  try {
    if (!businessProfileStore) return [null, 'No business provided'];

    const url = `/business/update/one/notification/${businessProfileStore._id}`;

    const res = await API.put(url, body);

    const { data } = res.data;
    return [true, data?.business];
  } catch (err) {
    const errMessage = getErrMessageUtil(err);
    enqueueSnackbar(errMessage, { variant: 'error' });
    return [null, errMessage];
  }
};

export const useQueryBusinessCheckPayexOnboarding = () => {
  const { businessProfileStore } = useStoreAuth.getState();

  const businessId = businessProfileStore?._id;

  const API = getAPI('payment');
  const url = `/payex/check-onboarding/${businessId}`;

  const queryKey = ['checkPayexOnboarding', businessId];

  const {
    isLoading, error, data, refetch, isRefetchError, isRefetching, ...others
  } = useQuery({
    queryKey,
    queryFn: () => API.get(url)
      .then((res) => res?.data?.data || null)
      .catch((err) => {
        throw new Error(err); // Rethrow the error so React Query can catch it
      }),
    enabled: !!businessId,
  });

  return {
    isLoading, error, data, refetch, isRefetchError, isRefetching, ...others,
  };
};

export const useQueryBusinessCheckOnboardingStatus = () => {
  const { businessProfileStore } = useStoreAuth.getState();

  const businessId = businessProfileStore?._id;

  const API = getAPI('user');
  const url = `/business/onboarding-status/${businessId}`;

  const queryKey = ['checkOnboardingStatus', businessId];

  const {
    isLoading, error, data, refetch, isRefetchError, isRefetching, ...others
  } = useQuery({
    queryKey,
    queryFn: () => API.get(url)
      .then((res) => res?.data || null)
      .catch((err) => {
        throw new Error(err); // Rethrow the error so React Query can catch it
      }),
    enabled: !!businessId,
  });

  return {
    isLoading, error, data, refetch, isRefetchError, isRefetching, ...others,
  };
};
