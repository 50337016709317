// React imports
import React, { useEffect } from 'react';

// Material-UI imports
import {
  Grid,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';

// Constants and utilities imports
import COLORS from 'constants/COLORS';
import LAYOUT from 'constants/LAYOUT';
import SHADOWS from 'constants/SHADOWS';

// Component imports
import OnboardingWrapper from 'components/shared/Onboarding/OnboardingWrapper';
import RecordMicro from 'components/Record/RecordMicro/RecordMicro';

// React Router imports
import {
  Outlet, useLocation, useNavigate, useParams,
} from 'react-router-dom';

// Other imports
import { PATH_USER } from 'constants/PATHS';
import { useStoreRecord } from 'store/record-store';
import RecordVoidDialog from 'components/Record/RecordDialogs/RecordVoidDialog';

export default function RecordScreen() {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();
  const { selectedRecordIdsStore } = useStoreRecord();
  const { status } = useParams();

  useEffect(() => {
    const pathContainsMulti = location.pathname.includes(PATH_USER.draftinvoices.multi);

    if (!isMobile && selectedRecordIdsStore?.length > 0 && !pathContainsMulti) {
      navigate(PATH_USER.invoices.multi(status));
    }
  }, [isMobile, selectedRecordIdsStore, location.pathname]);

  return (
    <>
      <RecordVoidDialog />
      <OnboardingWrapper>
        <Grid
          container
          maxWidth="false"
          sx={{
            height: `calc(100% - ${LAYOUT.APP_BAR_HEIGHT}px)`,
            backgroundColor: COLORS.secondaryBG,
            maxWidth: '100vw',
          }}
        >
          <MicroGrid item>
            <RecordMicro />
          </MicroGrid>
          <Outlet />
        </Grid>
      </OnboardingWrapper>
    </>
  );
}

const MicroGrid = styled(Grid)(({ theme }) => ({
  width: '100%',

  [theme.breakpoints.up('md')]: {
    width: LAYOUT.MICRO_WIDTH,
    borderRight: `1px solid ${COLORS.divider}`,
    boxShadow: SHADOWS.primary,
  },

}));
