import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
} from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CssBaseline } from '@mui/material';
// Constants
import COLORS from 'constants/COLORS';
import FONT_SIZE from 'constants/FONT_SIZE';
import App from './App';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import AraSnackbarProvider from 'components/shared/CustomSnackbar/AraSnackbarProvider';

const MUI_THEME = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: COLORS.primary,
    },
    secondary: {
      main: COLORS.red,
      light: COLORS.redLight,
    },
    tertiary: {
      main: COLORS.primaryTertiary,
      contrastText: COLORS.primary,
    },
    araSecondary: {
      main: COLORS.ara2,
      contrastText: '#fff',
    },
    background: {
      default: COLORS.mainBG,
    },
    text: {
      primary: COLORS.labelPrimary,
      secondary: COLORS.labelSecondary,
    },
    divider: COLORS.divider,
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: 'Mulish ,sans-serif',
    // fontFamily: 'Montserrat,sans-serif',
    body1: {
      fontSize: FONT_SIZE.body,
    },
    body2: {
      fontSize: FONT_SIZE.body2,
    },
    caption: {
      fontSize: FONT_SIZE.caption,
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: FONT_SIZE.hint,
      fontWeight: 500,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          '& ::-webkit-scrollbar': {
            width: '0.5em',
            height: '0.5em',
          },
          '& ::-webkit-scrollbar-track': {
            background: COLORS.divider,
          },
          '& ::-webkit-scrollbar-thumb': {
            backgroundColor: '#BEC1C5',
            borderRadius: 5,
          },
          '& ::-webkit-scrollbar-thumb:hover': {},
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        sizeSmall: {
          fontSize: '12px',
          fontWeight: 'bold',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardInfo: {
          backgroundColor: COLORS.primaryTertiary,
          color: '#000',
          '& .MuiAlert-icon': {
            color: COLORS.primary,
            fontSize: 18,
          },
        },
      },
    },

  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root'),
);

root.render(
  <Startup />,
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      gcTime: 0.5 * 60 * 1000,
    },
  },
});

function Startup() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={MUI_THEME}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <AraSnackbarProvider>
            <QueryClientProvider client={queryClient}>
              {/* <ReactQueryDevtools initialIsOpen={false} /> */}
              <App />
            </QueryClientProvider>
          </AraSnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}
