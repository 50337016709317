export const CustomerFormikInitialValues = (customer = null) => {
  let addContacts = [];

  if (customer?.additional_contacts && customer?.additional_contacts?.length > 0) {
    addContacts = customer?.additional_contacts?.map((c) => ({
      email: c?.email || '',
      first_name: c?.first_name || '',
      last_name: c?.last_name || '',
      phone_number: c?.phone_number || '',
    }));
  }

  return {
    user_type: customer?.user_type || localStorage?.getItem('last_selected_customer_type') || 'individual',
    business_name: customer?.business_name || '',
    business_short_name: customer?.business_short_name || '',
    business_type: customer?.business_type || '',

    // CONTACT DETAILS
    email: customer?.email || '',
    first_name: customer?.first_name || '',
    last_name: customer?.last_name || '',
    short_name: customer?.short_name || '',
    phone_number: customer?.phone_number || '',
    office_number: customer?.office_number || '',
    additional_contacts: addContacts,

    // ADDRESS DETAILS
    address_line_1: customer?.address_line_1 || '',
    address_line_2: customer?.address_line_2 || '',
    city: customer?.city || '',
    postcode: customer?.postcode || '',
    state: customer?.state || '',
    country_code: customer?.country_code || 'MY',

  };
};
