import React from 'react';
import {
  Grid, styled, Typography,
} from '@mui/material';
import LAYOUT from 'constants/LAYOUT';
import {
  Business, EmailOutlined, LocalPhoneOutlined, LocationOnOutlined, PersonOutlineOutlined,
} from '@mui/icons-material';
import COLORS from 'constants/COLORS';

export default function CustomerMicroListBox({ data, selected = false, onClick }) {
  return (
    <ListBox
      selected={selected}
      onClick={onClick}
      sx={{ display: 'flex' }}
    >
      <Grid
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        container
      >
        <Grid xs={7} item sx={{ alignItems: 'center', display: 'flex' }}>
          {data.user_type === 'individual' ? <PersonOutlineOutlined sx={{ fontSize: 13.5, mr: 1 }} /> : <Business sx={{ fontSize: 13.5, mr: 1 }} />}
          <Typography
            variant="body1"
            noWrap
            sx={{
              fontWeight: selected ? 700 : 600,
            }}
          >
            {data.business_name || `${data.first_name} ${data.last_name}`}
          </Typography>
        </Grid>
        <Grid xs={5} item sx={{ alignItems: 'center', display: 'flex', justifyContent: 'flex-start' }}>
          <LocalPhoneOutlined sx={{ fontSize: 13.5, mr: 1 }} />
          <Typography
            variant="body2"
            noWrap
            sx={{
              fontWeight: selected ? 600 : 500,
            }}
          >
            {data?.phone_number || '-'}
          </Typography>
        </Grid>
        <Grid xs={7} item sx={{ alignItems: 'center', display: 'flex' }}>
          <EmailOutlined sx={{ fontSize: 13.5, mr: 1 }} />
          <Typography
            variant="body2"
            noWrap
            sx={{
              fontWeight: selected ? 600 : 500,
            }}
          >
            {data?.email || '-'}
          </Typography>
        </Grid>
        <Grid xs={5} item sx={{ alignItems: 'center', display: 'flex', justifyContent: 'flex-start' }}>
          <LocationOnOutlined sx={{ fontSize: 13.5, mr: 1 }} />
          <Typography
            variant="body2"
            noWrap
            sx={{
              fontWeight: selected ? 600 : 500,
              mr: 1,
            }}
          >
            {data?.city || '-'}
          </Typography>
        </Grid>
      </Grid>
    </ListBox>
  );
}

const ListBox = styled(
  Grid,
  { shouldForwardProp: (prop) => prop !== 'selected' },
)(({ theme, selected }) => ({
  background: selected ? COLORS.primaryTertiary : '#fff',
  border: '1px solid rgba(112,144,176,0.2)',
  cursor: 'pointer',
  display: '-webkit-box',
  height: LAYOUT.MICRO_LIST_BOX_HEIGHT,
  padding: theme.spacing(1),
  position: 'relative',
  width: '100%',
}));
