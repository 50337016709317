import React from 'react';
// MUI
import {
  Box,
  Checkbox,
  styled, Typography,
} from '@mui/material';
import {
  Business, PersonOutlineOutlined,
} from '@mui/icons-material';
// CONST AND UTIL
// COMPONENTS
import COLORS from 'constants/COLORS';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { formatAmountUtil } from 'util/util';
import { useNavigate, useParams } from 'react-router-dom';
import { useStoreRecord } from 'store/record-store';
import { PATH_USER } from 'constants/PATHS';
import RecordStatusChip from '../RecordStatusChip';

const BOX_HEIGHT = 75;

export default function RecordMicroListBox({
  record,
}) {
  const navigate = useNavigate();
  const { recordId, status } = useParams();
  const { selectedRecordIdsStore, addOrRemoveSelectedRecordIdsStore, setSelectedRecordIdsStore } = useStoreRecord();

  return (
    <Grid2Container
      disableEqualOverflow
      direction="row"
      alignItems={'center'}
      container
      selected={recordId != null && recordId === record._id}
    >
      <Grid2 xs="auto">
        <Checkbox
          sx={{ pt: 0, px: 0, mr: 2 }}
          color="primary"
          checked={selectedRecordIdsStore.includes(record._id)}
          size="small"
          onClick={() => { addOrRemoveSelectedRecordIdsStore(record._id); }}
        />
      </Grid2>
      <Grid2
        onClick={() => {
          setSelectedRecordIdsStore([]);
          navigate(PATH_USER.invoices.detail(status, record._id));
        }}
        xs
      >
        <Box display={'flex'} alignItems="center" flexDirection={'row'}>
          {record?.customer?.user_type === 'individual'
            ? <PersonOutlineOutlined sx={{ fontSize: 13.5, mr: 1 }} /> : <Business sx={{ fontSize: 13.5, mr: 1 }} />}
          <Typography
            variant="body1"
            noWrap
            fontWeight={600}
            fontSize={12}
          >
            {record?.customer?.business_name || `${record?.customer?.first_name || ''} ${record?.customer?.last_name || ''}`}
          </Typography>
        </Box>
        <Typography
          variant="body1"
          fontWeight={600}
          sx={{ overflowWrap: 'anywhere', mt: 0.5, pr: 2 }}
          fontSize={12}
        >
          {record?.record_number}
        </Typography>
        <Box height={5} />
        <Typography
          variant="body1"
          noWrap
          fontSize={11}
        >
          {formatAmountUtil(record?.amount?.total_amount)}
        </Typography>
      </Grid2>
      <Grid2
        xs="auto"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          justifyContent: 'center',
          height: BOX_HEIGHT,
        }}
        onClick={() => { }}
      >
        <RecordStatusChip record={record} />
      </Grid2>

    </Grid2Container>
  );
}

const Grid2Container = styled(
  Grid2,
  { shouldForwardProp: (prop) => prop !== 'selected' },
)(({ selected }) => ({
  background: selected ? COLORS.primaryTertiary : '#fff',
  width: '100%',
  padding: '8px 14px 8px 12px',
  minHeight: `${BOX_HEIGHT}px`,
  borderRadius: '8px',
  marginBottom: '8px',
  borderBottom: '1px solid #EAECF0',
  cursor: 'pointer',
  border: '1px solid #e3e8ee',
}));
