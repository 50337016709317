import React, { useState } from 'react';
import {
  Container, styled, Typography,
} from '@mui/material';

import { LoadingButton } from '@mui/lab';

export default function SettingStripeOnboarding({ url }) {
  const title = 'You are nearly done! Upon inputting your company and bank information, you can upload invoices for free.';
  const buttonlabel = 'Continue Onboarding';

  const [isLoading, setIsLoading] = useState(false);

  return (

    <StripeImageContainer
      maxWidth="sm"
    >
      <OnboardStripeImage
        alt="ara onboard stripe"
        src="/images/stripe-onboarding.webp"
        width="50%"
      />

      <Typography variant="h5" sx={{ fontWeight: 600, mt: 3, mb: 1 }}>
        Start raising invoices and receiving payments
      </Typography>

      <Typography variant="subtitle1">
        {title}
      </Typography>

      <Typography variant="subtitle1">
        Click
        {' '}
        <b>&quot;Continue Onboarding&quot;</b>
        {' '}
        to commence step 2 (20-30 minutes).
      </Typography>

      <LoadingButton
        variant="contained"
        sx={{ mt: 3 }}
        loading={isLoading}
        data-cy="continue-onboarding-btn"
        onClick={() => {
          setIsLoading(true);
          window.location.href = url;
        }}
      >
        {buttonlabel}
      </LoadingButton>
    </StripeImageContainer>

  );
}

const StripeImageContainer = styled(Container)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  flexDirection: 'column',
  textAlign: 'center',
  padding: '10px',
}));

const OnboardStripeImage = styled('img')(() => ({

  marginBottom: 30,
}));
