/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import {
  Container, styled, Typography,
} from '@mui/material';
import LAYOUT from 'constants/LAYOUT';
import COLORS from 'constants/COLORS';

export default function OnboardingNew() {
  return (
    <OnboardingContainer maxWidth={false} disableGutters>
      <ImageContainer maxWidth="sm">
        <img
          alt="company"
          src="/images/ara-app-logo.webp"
          style={{ height: '45px', width: '180px' }}
        />
        <Typography variant="h5" sx={{ fontWeight: 600, mt: 3, mb: 1 }}>
          Account Creation Required
        </Typography>
        <Typography variant="subtitle1" sx={{ mb: 2, mt: 1 }}>
          Please contact us to create an account and get started with Ara Payments.
        </Typography>

        <Typography variant="subtitle1" sx={{ mb: 2, mt: 1 }}>
          If you have any questions, don't hesitate to reach out by clicking on
          {' '}
          <b>"Ask Ara"</b>
          {' '}
          in the top-right corner.
        </Typography>
      </ImageContainer>
    </OnboardingContainer>
  );
}

const ImageContainer = styled(Container)(() => ({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  flexDirection: 'column',
  textAlign: 'center',
  padding: '40px',
}));

const OnboardingContainer = styled(Container)(({ theme }) => ({
  height: `calc(100vh - ${LAYOUT.APP_BAR_HEIGHT}px)`,
  backgroundColor: COLORS.secondaryBG,
  overflow: 'auto',
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4, 0),
  },
}));
