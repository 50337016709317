import React, { useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
// MUI
import {
  Box, styled, Toolbar, useMediaQuery, useTheme,
} from '@mui/material';
// STORES
import { useStoreAuth } from 'store/auth-store';
// CONST
import LAYOUT from 'constants/LAYOUT';
// COMPONENTS
import AttachmentViewerDialog from 'components/shared/AttachmentViewer.js/AttachmentViewerDialog';
import OnboardingOpenBusinessDialog from 'components/shared/Onboarding/OnboardingOpenBusinessDialog';
import AppBarHeader from './AppBarHeader/AppBarHeader';
import SideBarDrawer from './SideBarDrawer/SideBarDrawer';

const { APP_BAR_HEIGHT, DRAWER_WIDTH } = LAYOUT;

export default function ProtectedUserRouteLayout({ screen, ...props }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const isBigScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const sessionToken = localStorage.getItem('session_token') || useStoreAuth((state) => state.sessionToken) || null;

  // -------------------------------------------------------------------------------------------------------------------------------------------------
  const { userProfileStore } = useStoreAuth();

  const [open, setOpen] = useState(true);

  const handleDrawer = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    if (isBigScreen) {
      setOpen(true);
      return;
    }

    if (isSmallScreen) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [isSmallScreen, isBigScreen]);

  // -------------------------------------------------------------------------------------------------------------------------------------------------

  return userProfileStore != null && sessionToken ? (
    <>
      <OnboardingOpenBusinessDialog />
      <AttachmentViewerDialog />
      <Box sx={{ display: 'flex' }}>
        <AppBarHeader props={props} handleDrawer={handleDrawer} />
        <SideBarDrawer
          isDrawerOpen={open}
          isSmallScreen={isSmallScreen}
          handleDrawer={handleDrawer}
        />
        <Main isOpen={open} isSmallScreen={isSmallScreen}>
          <Toolbar sx={{ height: APP_BAR_HEIGHT }} disableGutters />
          <Outlet />
        </Main>
      </Box>
    </>

  ) : (
    <Navigate to="/" replace />
  );
}

const Main = styled(
  'main',
  { shouldForwardProp: (prop) => prop !== 'isOpen' && prop !== 'isSmallScreen' },
)(({ theme, isOpen, isSmallScreen }) => ({
  flexGrow: 1,
  // padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: isSmallScreen ? 0 : `-${DRAWER_WIDTH}px`,
  ...(isOpen && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));
