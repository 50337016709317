import React from 'react';
import { MuiTelInput } from 'mui-tel-input';

export default function MuiPhoneInput({ formik, formikFieldKey, ...props }) {
  if (!formik || !formikFieldKey) {
    return (
      <MuiTelInput
        // MUI Tel Input Props
        defaultCountry="MY"
        preferredCountries={['MY', 'SG', 'US', 'CA', 'IN', 'ID']}
        disableFormatting
        forceCallingCode
        fullWidth
        size="small"
        // Other props
        {...props}
      />
    );
  }

  return (
    <MuiTelInput
    // MUI Tel Input Props
      defaultCountry="MY"
      preferredCountries={['MY', 'SG', 'US', 'CA', 'IN', 'ID']}
      disableFormatting
      forceCallingCode
    // Other props
      fullWidth
      data-cy={formikFieldKey}
      {...formik.getFieldProps(formikFieldKey)}
      onChange={(newValue) => {
        formik.setFieldValue(formikFieldKey, newValue || '');
      }}
      error={Boolean(formik.touched[formikFieldKey] && formik.errors[formikFieldKey])}
      helperText={formik.touched[formikFieldKey] && formik.errors[formikFieldKey]}
      size="small"
      {...props}
    />
  );
}
