import React from 'react';
import {
  Container, styled, Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import LAYOUT from 'constants/LAYOUT';
import COLORS from 'constants/COLORS';
import { useStoreAuth } from 'store/auth-store';
import { useMutation } from '@tanstack/react-query';
import { getErrMessageUtil } from 'util/util';
import { enqueueSnackbar } from 'notistack';
import { getAPI } from 'util/API';

export default function SettingStripeNotOnboarded() {
  const { businessProfileStore } = useStoreAuth();

  const API = getAPI('payment');
  const url = `/stripe/init-onboarding/${businessProfileStore?._id}`;

  const {
    isPending: mutIsPending,
    mutate: triggerMut,
  } = useMutation({
    mutationFn: () => API.get(url)
      .then((res) => {
        const finalDataObj = res?.data?.data || null;

        window.location.href = finalDataObj.stripeOnboardingUrl;
      })
      .catch((err) => {
        const errMessage = getErrMessageUtil(err);
        enqueueSnackbar(errMessage, { variant: 'error' });
        throw new Error(errMessage); // Rethrow the error so React Query can catch it
      }),

  });

  return (
    <StripeOnboardingContainer maxWidth={false} disableGutters>
      <StripeImageContainer
        maxWidth="sm"
      >
        <img
          alt="company"
          src="/images/ara-app-logo.webp"
          style={{ height: '45px', width: '180px' }}
        />
        <Typography variant="h5" sx={{ fontWeight: 600, mt: 3, mb: 1 }}>
          Start raising invoices and receiving payments
        </Typography>
        <Typography variant="subtitle1">
          Please complete our 2 step onboarding process to start using ARA.
        </Typography>

        <Typography variant="subtitle1">
          Click
          {' '}
          <b>Start Onboarding</b>
          {' '}
          to commence step 1 (2-3 minutes).
        </Typography>

        <LoadingButton
          variant="contained"
          sx={{ mt: 3 }}
          onClick={() => {
            triggerMut();
          }}
          loading={mutIsPending}
          data-cy="start-onboarding-btn"
        >
          Start Onboarding
        </LoadingButton>
      </StripeImageContainer>
    </StripeOnboardingContainer>

  );
}

const StripeImageContainer = styled(Container)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  flexDirection: 'column',
  textAlign: 'center',
  padding: '10px',
}));

const StripeOnboardingContainer = styled(Container)(({ theme }) => ({
  height: `calc(100vh - ${LAYOUT.APP_BAR_HEIGHT}px)`,
  backgroundColor: COLORS.secondaryBG,
  overflow: 'auto',
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4, 0),
  },
}));
