import React, { useMemo, useState } from 'react';
import {
  Box, styled, Input, InputAdornment, IconButton,
} from '@mui/material';

import LAYOUT from 'constants/LAYOUT';
import { Close } from '@mui/icons-material';
import COLORS from 'constants/COLORS';
import debouncedInput from './debouncedInput';

export default function MicroSearchBar({
  search, setSearch, placeholder = 'Search...', timeout = 500,
  sx = {},
}) {
  const [isClear, setIsClear] = useState(false);
  const DebouncedTextField = useMemo(() => debouncedInput(Input, { timeout }), [isClear]);

  return (
    <SearchContainer sx={sx}>
      <DebouncedTextField
        sx={{
          height: '100%',
          backgroundColor: '#fff',
          borderRadius: '10px',
          paddingLeft: '20px',
          border: `1px solid ${COLORS.divider}`,
          color: '#000',
          fontWeight: 500,
          alignItems: 'center',
          '& input::placeholder': {
            color: '#000',
            fontWeight: 500,
          },
        }}
        value={search}
        endAdornment={(
          search && search !== '' ? (
            <InputAdornment position="end" sx={{ pr: 0.5 }}>
              <IconButton
                size="small"
                onClick={() => {
                  setSearch('');
                  setIsClear(!isClear);
                }}
              >
                <Close fontSize="small" />
              </IconButton>
            </InputAdornment>
          ) : <InputAdornment position="end" sx={{ pr: 0.5 }} />
        )}
        disableUnderline
        fullWidth
        placeholder={placeholder}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      />
    </SearchContainer>
  );
}

const SearchContainer = styled(Box)(() => ({
  height: LAYOUT.MICRO_SEARCHBAR_HEIGHT,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
}));
