import React from 'react';
// MUI
import {
  Box, CircularProgress,
} from '@mui/material';
// CONST AND UTIL
// COMPONENTS

// STORES
import { useStoreAuth } from 'store/auth-store';
// APIS

import MicroEmpty from 'components/shared/Micro/MicroEmpty';
import { getAPI } from 'util/API';
import { useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import SettingStripeOnboarding from 'components/Settings/SettingsStripe/SettingStripeOnboarding';
import SettingsStripeFullyOnboarded from 'components/Settings/SettingsStripe/SettingsStripeFullyOnboarded';
import SettingStripeNotOnboarded from 'components/Settings/SettingsStripe/SettingStripeNotOnboarded';

export default function SettingsStripeScreen() {
  const { businessProfileStore } = useStoreAuth();

  const API = getAPI('payment');
  const url = `/stripe/check-onboarding/${businessProfileStore?._id}`;

  const {
    isLoading, error, data, refetch, isRefetchError, isRefetching,
  } = useQuery({
    queryKey: ['checkStripeOnboarding'],
    queryFn: () => API.get(url)
      .then((res) => {
        const finalDataObj = res?.data?.data || null;

        if (finalDataObj?.stripeWarning) {
          enqueueSnackbar(finalDataObj.stripeWarning, { variant: 'info' });
        }

        return finalDataObj;
      })
      .catch((err) => {
        throw new Error(err); // Rethrow the error so React Query can catch it
      }),
    enabled: !!businessProfileStore?._id,
    gcTime: 0,
    staleTime: 0,
  });

  if (isLoading || isRefetching) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress size={20} sx={{ mt: 4 }} />
      </Box>
    );
  }

  if (error || !data || isRefetchError || !businessProfileStore?._id) {
    return (
      <MicroEmpty
        title="Oops! We couldn't retrieve your settings. Please try again."
        onClick={() => {
          refetch();
        }}
      />
    );
  }

  if (data?.onboardingStatus === 'FULLY_ONBOARDED') return <SettingsStripeFullyOnboarded />;

  if (data?.onboardingStatus === 'ONBOARDING' && data?.stripeOnboardingUrl) return <SettingStripeOnboarding url={data.stripeOnboardingUrl} />;

  if (data?.onboardingStatus === 'NOT_ONBOARDED') return <SettingStripeNotOnboarded />;

  if (data?.onboardingStatus === 'REJECTED') {
    return (
      <MicroEmpty
        title="Oops! Something went wrong. Your Stripe account has been rejected. Please contact support for assistance."
      />
    );
  }

  return (
    <MicroEmpty
      title="Oops! Something went wrong. Please contact support for further assistance."
    />
  );
}
