// React imports
import React, { useEffect } from 'react';

// Material-UI imports
import {
  Box,
  Button,
  Grid,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';

// Constants & Utilities imports
import COLORS from 'constants/COLORS';
import LAYOUT from 'constants/LAYOUT';
import SHADOWS from 'constants/SHADOWS';

// Components imports
import OnboardingWrapper from 'components/shared/Onboarding/OnboardingWrapper';
import DraftRecordMicro from 'components/DraftRecord/DraftRecordMicro/DraftRecordMicro';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

// Store imports
import { useStoreDraftRecord } from 'store/draft-record-store';

// Other imports
import { PATH_USER } from 'constants/PATHS';

export default function DraftRecordScreen() {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();
  const { selectedDraftRecordIdsStore } = useStoreDraftRecord();

  useEffect(() => {
    const pathContainsMulti = location.pathname.includes(PATH_USER.draftinvoices.multi);

    if (!isMobile && selectedDraftRecordIdsStore?.length > 0 && !pathContainsMulti) {
      navigate(PATH_USER.draftinvoices.multi);
    }
  }, [isMobile, selectedDraftRecordIdsStore, location.pathname]);

  return (
    <OnboardingWrapper>
      <Grid
        container
        maxWidth="false"
        sx={{
          height: `calc(100% - ${LAYOUT.APP_BAR_HEIGHT}px)`,
          backgroundColor: COLORS.secondaryBG,
          maxWidth: '100vw',
        }}
      >
        <MicroGrid item>
          <DraftRecordMicro />
        </MicroGrid>

        <Outlet />
        {isMobile && selectedDraftRecordIdsStore?.length
          && (
            <MicroSmallScreenBottomBox>
              <Button
                sx={{ borderRadius: 0 }}
                disableElevation
                fullWidth
                variant="contained"
                onClick={() => {
                  navigate(PATH_USER.draftinvoices.multi);
                }}
              >
                {`Review Draft (${selectedDraftRecordIdsStore.length})`}
              </Button>
            </MicroSmallScreenBottomBox>
          )}
      </Grid>
    </OnboardingWrapper>
  );
}

const MicroGrid = styled(Grid)(({ theme }) => ({
  width: '100%',

  [theme.breakpoints.up('md')]: {
    width: LAYOUT.MICRO_WIDTH,
    borderRight: `1px solid ${COLORS.divider}`,
    boxShadow: SHADOWS.primary,
  },

}));

const MicroSmallScreenBottomBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  backgroundColor: '#fff',
  padding: theme.spacing(2),
  bottom: 0,
  zIndex: 2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: SHADOWS.primary,
}));
