import { enqueueSnackbar } from 'notistack';

// STORES
import { getAPI, getSecurePayeeAPI } from 'util/API';
import { getErrMessageUtil } from 'util/util';

export const AuthCheckEmailAPI = async (body) => {
  const API = getAPI('auth');
  const url = '/auth/check-email';

  try {
    const res = await API.post(url, body);
    const { data } = res.data;
    return [true, data?.status];
  } catch (err) {
    const errMessage = getErrMessageUtil(err);
    enqueueSnackbar(errMessage, { variant: 'error' });
    return [null, errMessage];
  }
};

export const AuthSigninAPI = async (body) => {
  const API = getAPI('auth');
  const url = '/auth/sign-in';
  try {
    const res = await API.post(url, body);
    const { data } = res.data;
    return [true, data];
  } catch (err) {
    const errMessage = getErrMessageUtil(err);
    enqueueSnackbar(errMessage, { variant: 'error' });
    return [null, errMessage];
  }
};

export const AuthSignupAPI = async (body) => {
  const API = getAPI('auth');
  const url = '/auth/sign-up';
  try {
    const res = await API.post(url, body);
    const { data } = res.data;
    enqueueSnackbar('An account verification link is sent to your email, please check your email and verify your account', { variant: 'success' });
    return [true, data];
  } catch (err) {
    const errMessage = getErrMessageUtil(err);
    enqueueSnackbar(errMessage, { variant: 'error' });
    return [null, errMessage];
  }
};

export const AuthVerifyAccountAPI = async (body) => {
  const API = getAPI('auth');
  const url = '/auth/verify-account';

  try {
    const res = await API.post(url, body);
    const { status } = res.data;
    return [true, status];
  } catch (err) {
    const errMessage = getErrMessageUtil(err);
    enqueueSnackbar(errMessage, { variant: 'error' });
    return [null, errMessage];
  }
};

export const AuthSignInWithMagicLinkAPI = async (body) => {
  const API = getAPI('auth');
  const url = '/auth/sign-in-with-magic-link';
  try {
    const res = await API.post(url, body);
    const { status } = res.data;
    enqueueSnackbar('Please check your email and sign into your account with magic link', {
      variant: 'success',
    });
    return [true, status];
  } catch (err) {
    const errMessage = getErrMessageUtil(err);
    enqueueSnackbar(errMessage, { variant: 'error' });
    return [null, errMessage];
  }
};

export const AuthSendForgotPasswordEmailAPI = async (body) => {
  const API = getAPI('auth');
  const url = '/auth/send-forgot-password-email';
  try {
    const res = await API.post(url, body);
    enqueueSnackbar('Please check your email to reset your password', { variant: 'success' });
    const { data } = res.data;
    return [true, data];
  } catch (err) {
    const errMessage = getErrMessageUtil(err);
    enqueueSnackbar(errMessage, { variant: 'error' });
    return [null, errMessage];
  }
};

export const AuthResetPasswordAPI = async (jwtToken, body) => {
  const API = getAPI('auth');
  const url = `/auth/reset-password-with-token/${jwtToken}`;
  try {
    const res = await API.post(url, body);
    const { data } = res.data;
    return [true, data];
  } catch (err) {
    const errMessage = getErrMessageUtil(err);
    enqueueSnackbar(errMessage, { variant: 'error' });
    return [null, errMessage];
  }
};

export const AuthUpdatePasswordAPI = async (body) => {
  const API = getSecurePayeeAPI('auth');
  const url = '/auth/update-current-password';
  try {
    const res = await API.post(url, body);
    const { data } = res.data;
    return [true, data];
  } catch (err) {
    const errMessage = getErrMessageUtil(err);
    enqueueSnackbar(errMessage, { variant: 'error' });
    return [null, errMessage];
  }
};
