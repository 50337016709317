// React imports
import React from 'react';
import { useParams } from 'react-router-dom';

// Material-UI imports
import {
  Box,
  CircularProgress,
  Container,
} from '@mui/material';

// Constants & Utilities - No imports in this section

// Components imports
import RecordNanoDetail from 'components/Record/RecordNano/RecordNanoDetail';
import RecordNanoFiles from 'components/Record/RecordNano/RecordNanoFiles';
import ResponsiveNanoWrapperV2 from 'components/shared/ResponsiveNanoWrapper/ResponsiveNanoWrapperV2';
import MicroEmpty from 'components/shared/Micro/MicroEmpty';

// External library import
import { useQueryGetOneRecord } from 'services/record-api-services';

export default function RecordDetailScreen() {
  const { recordId } = useParams();

  const {
    isLoading, error, data: invoice, refetch, isRefetchError, isRefetching,
  } = useQueryGetOneRecord(recordId);

  const renderContent = () => {
    if (isLoading || isRefetching) {
      return (
        <Box display="flex" alignItems="center" justifyContent="center" width={'100%'}>
          <CircularProgress size={20} sx={{ mt: 4 }} />
        </Box>
      );
    }

    if (error || !invoice || isRefetchError) {
      return (
        <MicroEmpty
          title="Oops! Couldn't retrieve invoice. Please try again."
          onClick={() => {
            refetch();
          }}
        />
      );
    }

    return (
      <Box sx={{ px: 3 }}>
        <Container
          maxWidth="md"
          disableGutters
        >
          <Box height={40} />
          <RecordNanoDetail data={invoice} />
          <Box height={40} />
          <RecordNanoFiles data={invoice} />
          {/* Others LoD, Debt Restructure here */}
          <Box height={40} />
        </Container>
      </Box>

    );
  };

  return <ResponsiveNanoWrapperV2>{renderContent()}</ResponsiveNanoWrapperV2>;
}
