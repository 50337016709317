import React, { useState } from 'react';
import './style.css';

const imageArray = [
  '/images/ara-loading/frame-1.webp',
  '/images/ara-loading/frame-2.webp',
  '/images/ara-loading/frame-3.webp',
  '/images/ara-loading/frame-4.webp',
  '/images/ara-loading/frame-5.webp',
];

/* eslint-disable react/jsx-no-useless-fragment */
export default function FullScreenLoader({ isLoading = false }) {
  const [currentImage, setCurrentImage] = useState(0);

  setTimeout(() => {
    setCurrentImage((cr) => (cr + 1) % imageArray.length);
  }, 220);

  return (
    <>
      {isLoading && (
        <div className="wrapper">
          <div className="loading">LOADING</div>
          {imageArray.map((image, index) => (
            <img
              key={image}
              src={image}
              alt="loading"
              style={{
                display: currentImage === index ? 'block' : 'none',
                width: 100,
                height: 84,
              }}
            />
          ))}
        </div>
      )}
    </>
  );
}
