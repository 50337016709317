import React, { useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import {
  Box,
  Button,
  TextField,
  Alert,
  AlertTitle,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { AuthSendForgotPasswordEmailAPI } from 'services/auth-api-services';
import { useNavigate, useParams } from 'react-router-dom';

export default function AuthForgotPasswordScreen() {
  const { email } = useParams();
  const navigate = useNavigate();

  const [isSent, setIsSent] = useState(false);

  const formik = useFormik({
    initialValues: {
      email,
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const [apiStatus, data] = await AuthSendForgotPasswordEmailAPI(values);
      setSubmitting(false);

      if (!apiStatus || !data) {
        setIsSent(false);
        return;
      }

      setIsSent(true);
    },
  });

  const {
    getFieldProps, isSubmitting, handleSubmit,
  } = formik;

  return (
    <>
      <Box sx={{ mb: 5 }}>
        <Typography variant="h4" gutterBottom>
          Forgot your password?
        </Typography>
        <Typography color="textSecondary" variant="subtitle1" sx={{ fontWeight: 500 }}>
          {'If you\'ve forgotten your password, don\'t worry! We\'ll send you an email with instructions to reset it.'}
        </Typography>
      </Box>
      <FormikProvider value={formik}>
        <Form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
        >
          <TextField
            fullWidth
            type="email"
            disabled
            label={'Email address'}
            {...getFieldProps('email')}
          />
          <Box sx={{ mb: 3 }} />

          {isSent
            && (
              <Alert severity="success" sx={{ mb: 3 }}>
                <AlertTitle>
                  <strong>
                    A reset password link is sent to your email
                  </strong>
                </AlertTitle>
                Please check your email to reset your password
              </Alert>
            )}

          <LoadingButton
            fullWidth
            size="large"
            data-cy="forgot_password_btn"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            disabled={isSent}
          >
            Forgot password
          </LoadingButton>

          <Button
            variant="contained"
            color="tertiary"
            sx={{ my: 3, fontWeight: 'bold' }}
            fullWidth
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </Button>
        </Form>
      </FormikProvider>
    </>
  );
}
