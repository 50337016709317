import React, { useEffect } from 'react';
// MUI
import {
  Box,
  Divider,
  Grid,

  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
// CONST AND UTIL
import {
  addDays,
} from 'date-fns';
import _ from 'lodash';
import { blockInvalidCharNumberInput, formatDateUtil } from 'util/util';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import COLORS from 'constants/COLORS';
import { HelpTwoTone } from '@mui/icons-material';

export default function DraftRecordInputInvoice({
  formik,
}) {
  const {
    errors, touched, getFieldProps, setFieldValue,
  } = formik;

  const totalAmount = getFieldProps('total_amount')?.value;
  const previousPaymentAmount = getFieldProps('previous_payment_amount')?.value || 0;

  useEffect(() => {
    if (totalAmount) {
      setFieldValue('processable_value', _.round(totalAmount - previousPaymentAmount, 2));
    } else {
      setFieldValue('processable_value', 0);
    }
  }, [totalAmount, previousPaymentAmount]);

  return (
    <>
      <Grid2 container sx={{ width: '100%', alignItems: 'center' }} rowSpacing={2}>
        <Grid2 xs={12} sm>
          <Box display="flex">
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 'bold', mr: 2 }}
            >
              Invoice Detail
            </Typography>
          </Box>
          <Typography
            variant="body2"
            sx={{ color: COLORS.labelSecondary, mt: 1 }}
          >
            Fill out the essential of the invoice which you want to collect
          </Typography>
        </Grid2>

      </Grid2>
      <Divider sx={{ my: 2 }} />
      <Box p={2}>

        <Grid container spacing={4} name="invoice-grid-dates">
          <Grid item sm={6} xs={12}>

            <TextField
              data-cy="create-record_number"
              fullWidth
              label={'Invoice Number'}
              {...getFieldProps('record_number')}
              error={Boolean(touched.record_number && errors.record_number)}
              helperText={touched.record_number && errors.record_number}
              size="small"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              data-cy="create-description"
              fullWidth
              label={'Description'}
              {...getFieldProps('description')}
              error={Boolean(touched.description && errors.description)}
              helperText={touched.description && errors.description}
              size="small"
            />
          </Grid>
        </Grid>
        <Box sx={{ height: 24 }} />

        <Grid container spacing={4} name="invoice-grid-dates">
          <Grid item sm={6} xs={12}>
            <MobileDatePicker
              label={'Issued On'}
              value={getFieldProps('issue_date').value}
              inputFormat="dd-MMM-yyyy"
              onChange={(val) => {
                setFieldValue('issue_date', val);
              }}
              onAccept={(val) => {
                setFieldValue('issue_date', val);
                setFieldValue('due_date', addDays(new Date(val), 30));
              }}
              renderInput={(params) => (
                <TextField
                  error={Boolean(touched.issue_date && errors.issue_date)}
                  helperText={errors.issue_date}
                  data-cy="create-issue_date"
                  {...params}
                  fullWidth
                  size="small"
                />
              )}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <MobileDatePicker
              label={'Due Date'}
              value={getFieldProps('due_date').value}
              inputFormat="dd-MMM-yyyy"
              onChange={(val) => {
                setFieldValue('due_date', val);
              }}
              renderInput={(params) => (
                <TextField
                  error={Boolean(touched.due_date && errors.due_date)}
                  helperText={errors.due_date}
                  data-cy="create-due_date"
                  {...params}
                  fullWidth
                  size="small"
                />
              )}
              minDate={addDays(new Date(getFieldProps('issue_date').value), 1) || new Date()}
              maxDate={addDays(new Date(getFieldProps('issue_date').value), 60) || addDays(formatDateUtil(new Date()), 60)}
            />
          </Grid>
        </Grid>

        <Box sx={{ height: 24 }} />
        <Grid container spacing={4} name="invoice-grid-amount">
          <Grid item md={4} sm={6} xs={12}>
            <TextField
              fullWidth
              type="number"
              onWheel={(e) => e.target.blur()}
              onKeyDown={blockInvalidCharNumberInput}
              label={'Total Invoice Value'}
              {...getFieldProps('total_amount')}
              error={Boolean(touched.total_amount && errors.total_amount)}
              helperText={touched.total_amount && errors.total_amount}
              size="small"
              InputProps={{
                startAdornment: <InputAdornment position="start">MYR</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextField
              data-cy="invoice-paid"
              fullWidth
              type="number"
              onWheel={(e) => e.target.blur()}
              onKeyDown={blockInvalidCharNumberInput}
              label={'Total Paid (optional)'}
              {...getFieldProps('previous_payment_amount')}
              error={Boolean(touched.previous_payment_amount && errors.previous_payment_amount)}
              helperText={touched.previous_payment_amount && errors.previous_payment_amount}
              size="small"
              InputProps={{
                startAdornment: <InputAdornment position="start">MYR</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextField
              data-cy="invoice-amount_due"
              fullWidth
              type="number"
              onWheel={(e) => e.target.blur()}
              onKeyDown={blockInvalidCharNumberInput}
              label={'Amount Due'}
              {...getFieldProps('processable_value')}
              error={Boolean(errors.processable_value)}
              helperText={errors.processable_value}
              size="small"
              disabled
              InputProps={{
                startAdornment: <InputAdornment position="start">MYR</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <TextField
              fullWidth
              type="number"
              onWheel={(e) => e.target.blur()}
              onKeyDown={blockInvalidCharNumberInput}
              label={formik?.values?.interest_rate_type === 'abs' ? 'Absolute Interest Rate for 30 days (MYR)' : 'Interest Rate for 30 days (%)'}
              {...getFieldProps('interest_rate')}
              error={Boolean(touched.interest_rate && errors.interest_rate)}
              helperText={touched.interest_rate && errors.interest_rate}
              size="small"
              InputProps={{
                startAdornment: (
                  <TextField
                    sx={{
                      ml: 1, mr: 2, width: 80,
                    }}
                    variant="standard"
                    select
                    value={formik.values.interest_rate_type}
                    onChange={(e) => {
                      setFieldValue('interest_rate_type', e.target.value);
                    }}
                  >
                    <MenuItem value="abs">MYR</MenuItem>
                    <MenuItem value="pct">%</MenuItem>
                  </TextField>
                ),
                endAdornment: (
                  <InputAdornment position="end" sx={{ cursor: 'pointer' }}>
                    <Tooltip title={'This represents the interest rate for 30 days. If absolute, it\'s the amount per day. If percentage, it\'s the percentage per day.'} placement="top">
                      <HelpTwoTone fontSize="small" color="primary" />
                    </Tooltip>
                  </InputAdornment>
                ),

              }}
            />
          </Grid>
        </Grid>

      </Box>
    </>

  );
}
