// React imports
import React, { useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';

// Material-UI imports
import {
  Box,
  Button,
  Collapse,
  Container,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

// Store imports
import { useStoreDraftRecord } from 'store/draft-record-store';

// Constants
import COLORS from 'constants/COLORS';
import { formatAmountUtil, formatDateUtil } from 'util/util';
import SHADOWS from 'constants/SHADOWS';

// Components imports
import AlertDialog from 'components/shared/Dialogs/AlertDialog';
import { ArrowBack, Delete, KeyboardArrowDown } from '@mui/icons-material';
import { RecordConvertDraftAPI, RecordDeleteManyDraftAPI } from 'services/record-api-services';
import { intersectionWith } from 'lodash';
import DraftRecordNanoDetail from 'components/DraftRecord/DraftRecordNano/DraftRecordNanoDetail';
import { useNavigate } from 'react-router-dom';
import { PATH_USER } from 'constants/PATHS';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import ResponsiveNanoWrapperV2 from 'components/shared/ResponsiveNanoWrapper/ResponsiveNanoWrapperV2';
import { useQueryClient } from '@tanstack/react-query';

const HEADER_DESKTOP = ['', 'To', 'Issued', 'Due Date', 'Amount Due (MYR)', ''];
const HEADER_MOBILE = ['', 'Invoice', ''];
export default function DraftRecordNanoScreen() {
  const navigate = useNavigate();
  const { draftRecordsStore, selectedDraftRecordIdsStore } = useStoreDraftRecord();

  const [datas, setDatas] = useState([]);

  useEffect(() => {
    const selectedDatas = intersectionWith(draftRecordsStore, selectedDraftRecordIdsStore, (o, id) => o?._id === id) || [];

    if (!selectedDatas?.length) {
      navigate(PATH_USER.draftinvoices.root);
    } else {
      setDatas(selectedDatas);
    }
  }, [draftRecordsStore, selectedDraftRecordIdsStore]);

  if (!datas?.length) {
    return <Box />;
  }

  return <ResponsiveNanoWrapperV2><Screen selectedDatas={datas} /></ResponsiveNanoWrapperV2>;
}

function Screen({ selectedDatas }) {
  const queryClient = useQueryClient();
  const { removeOneDraftRecordsStore } = useStoreDraftRecord();

  const selectedIds = selectedDatas.map((d) => d._id);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { enqueueSnackbar } = useSnackbar();

  const HEADER = useMemo(() => (isMobile ? HEADER_MOBILE : HEADER_DESKTOP), [isMobile]);

  const [isLoading, setIsLoading] = useState(false);
  const [isBulkSubmit, setIsBulkSubmit] = useState(false);
  const [isDeleteIds, setIsDeleteIds] = useState(null);
  const [isViewId, setIsViewId] = useState(selectedDatas[0]._id);

  const handleBulkSend = async () => {
    setIsLoading(true);

    const [status, data] = await RecordConvertDraftAPI(selectedIds);
    setIsLoading(false);
    if (!status || !data) return;

    for (let i = 0; i < selectedIds.length; i++) {
      removeOneDraftRecordsStore(selectedIds[i]);
    }

    enqueueSnackbar('The invoice(s) has been sent to the respective customer', { variant: 'success' });

    queryClient.invalidateQueries({
      queryKey: ['getRecordMetadata'],
    });
  };

  const recordhandleDeleteManyDraftRecords = async () => {
    setIsLoading(true);
    const [status] = await RecordDeleteManyDraftAPI(isDeleteIds);
    setIsLoading(false);

    if (!status) return;

    isDeleteIds.forEach((id) => {
      removeOneDraftRecordsStore(id);
    });

    enqueueSnackbar(`Succesfully deleted ${isDeleteIds.length} draft invoice`, { variant: 'success' });
    setIsDeleteIds(null);
    queryClient.invalidateQueries({
      queryKey: ['getRecordMetadata'],
    });
  };

  // -------------------------------------------------------------------------------------------------------------------------------------------------

  const renderAllAlertDialog = (
    <>
      <AlertDialog
        open={!!isDeleteIds?.length}
        setOpen={setIsDeleteIds}
        isLoading={isLoading}
        title={`Confirm Delete ${isDeleteIds?.length} Draft Invoice(s)?`}
        message="This draft invoice(s) will be permanently deleted and this process is irreversible."
        actions={[
          {
            text: `Delete ${isDeleteIds?.length} draft invoice(s)`,
            color: 'secondary',
            onClick: () => {
              recordhandleDeleteManyDraftRecords();
            },
          },
          {
            text: 'Cancel',
            color: 'tertiary',
            variant: 'contained',
            onClick: () => {
              setIsDeleteIds(null);
            },
          },
        ]}
      />
      <AlertDialog
        open={isBulkSubmit}
        isLoading={isLoading}
        setOpen={setIsBulkSubmit}
        title="Finalize Drafts into Invoices"
        message=""
        message2={(
          <>
            <Typography variant="body1" sx={{ mt: 0, mb: 2, fontSize: 14 }}>
              {`Ready to finalize ${selectedDatas.length} draft(s) into invoice(s)?`}
            </Typography>
            {selectedDatas.length > 10
              && (
                <Typography
                  variant="body1"
                  sx={{
                    mt: 0, mb: 3, fontSize: 13, fontStyle: 'italic',
                  }}
                >
                  {`** Please note that processing ${selectedDatas.length} invoices may take a moment. We appreciate your patience.`}
                </Typography>
              )}
          </>
        )}
        actions={[
          {
            text: 'Send now',
            onClick: () => {
              handleBulkSend();
            },
          },
          {
            text: 'Cancel',
            color: 'tertiary',
            variant: 'contained',
            onClick: () => {
              setIsBulkSubmit(false);
            },
          },
        ]}
      />
    </>
  );

  return (
    <>
      {renderAllAlertDialog}
      <Container
        maxWidth="md"
        disableGutters
        sx={{ p: 4 }}
      >
        <DraftInvoiceNanoDesktopHeader
          selectedDatas={selectedDatas}
          setIsBulkSubmit={setIsBulkSubmit}
          handleDeleteMany={() => { setIsDeleteIds(selectedIds); }}
        />
        <Table sx={{ backgroundColor: '#fff', boxShadow: SHADOWS.inputPaper }}>
          <TableHead>
            <TableRow>
              {HEADER.map((h, i) => (
                <TableCell
                  key={h + i}
                  align={i === 0 ? 'left' : 'left'}
                  sx={{ borderBottom: '1px solid #D0D5DD', color: COLORS.labelSecondary, fontWeight: 400 }}
                >
                  {h}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedDatas.map((d) => {
              const isExpand = d?._id === isViewId;

              return (
                <React.Fragment key={d._id}>
                  <TableRow hover onClick={() => { setIsViewId(isExpand ? null : d._id); }} sx={{ cursor: 'pointer' }}>
                    <TableCell sx={{ borderBottom: '1px solid #D0D5DD' }} align="center" padding="none">
                      <IconButton
                        size="small"
                        sx={{ fontSize: 11, fontWeight: 500 }}
                        onClick={() => { setIsViewId(isExpand ? null : d._id); }}
                      >
                        <KeyboardArrowDown />
                      </IconButton>
                    </TableCell>
                    {!isMobile ? (
                      <>
                        <TableCell sx={{ borderBottom: '1px solid #D0D5DD' }} padding="none">
                          <Typography>
                            {d?.record_number}
                          </Typography>
                          <Typography variant="caption" color={'text.secondary'}>
                            {d?.customer?.business_name || `${d?.customer?.first_name || ''} ${d?.customer?.last_name || ''}`}
                          </Typography>
                        </TableCell>

                        <TableCell sx={{ borderBottom: '1px solid #D0D5DD' }}>
                          {formatDateUtil(d?.date?.issue_date, 'dd MMM yyyy')}
                        </TableCell>
                        <TableCell sx={{ borderBottom: '1px solid #D0D5DD' }}>
                          {formatDateUtil(d?.date?.due_date, 'dd MMM yyyy')}
                        </TableCell>
                        <TableCell sx={{ borderBottom: '1px solid #D0D5DD' }}>
                          {formatAmountUtil(d?.amount?.processable_value)}
                        </TableCell>
                      </>
                    ) : (
                      <TableCell sx={{ borderBottom: '1px solid #D0D5DD' }}>
                        <Typography>
                          {d?.record_number}
                        </Typography>
                        <Typography variant="caption">
                          {d?.customer?.business_name || `${d?.customer?.first_name || ''} ${d?.customer?.last_name || ''}`}
                        </Typography>
                      </TableCell>
                    )}
                    <TableCell sx={{ borderBottom: '1px solid #D0D5DD' }} align="center" padding="none">
                      <IconButton
                        size="small"
                        color="error"
                        onClick={() => { setIsDeleteIds([d._id]); }}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>

                  </TableRow>
                  <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0, borderBottom: isExpand ? '1px solid #D0D5DD' : '' }} colSpan={6}>
                      <Collapse in={isExpand} timeout="auto" unmountOnExit>
                        <DraftRecordNanoDetail data={d} />

                      </Collapse>
                    </TableCell>

                  </TableRow>
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </Container>
    </>
  );
}

function DraftInvoiceNanoDesktopHeader({
  selectedDatas, setIsBulkSubmit, handleDeleteMany,
}) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Grid2 container sx={{ mb: 4 }} rowSpacing={2}>
      <Grid2 xs={12} sm display={'flex'} flexDirection={'row'} alignItems={'center'}>
        <IconButton
          sx={{
            display: isMobile ? 'block' : 'none', p: 0, mt: 0.25, mr: 1,
          }}
          size="small"
          onClick={() => {
            navigate(PATH_USER.draftinvoices.root);
          }}
        >
          <ArrowBack sx={{ fontSize: 16 }} />
        </IconButton>
        <Typography variant="body1" sx={{ fontWeight: 700 }}>
          {selectedDatas.length}
          {' '}
          item(s) selected | MYR
          {' '}
          {formatAmountUtil(selectedDatas.reduce((acc, i) => acc + (i?.amount?.processable_value || 0), 0))}

        </Typography>
      </Grid2>
      <Grid2 xs={12} sm="auto" display={'flex'} flexDirection={'row'} alignItems={'center'}>
        <NanoButton
          variant="outlined"
          size="small"
          color="error"
          onClick={handleDeleteMany}
          sx={{ fontSize: 11, mr: 1 }}
        >
          Delete All
        </NanoButton>
        <NanoButton
          variant="contained"
          size="small"
          onClick={() => {
            setIsBulkSubmit(true);
          }}
          sx={{ fontSize: 11 }}
        >
          Send
        </NanoButton>
      </Grid2>
    </Grid2>
  );
}

const NanoButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(0),

}));
