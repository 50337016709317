import * as React from 'react';
// MUI
import {
  Avatar,
  Badge,
  Button,
  styled,
} from '@mui/material';
import {
  ExpandMore,
} from '@mui/icons-material';
// CONSTANTS
import COLORS from 'constants/COLORS';
import FONT_SIZE from 'constants/FONT_SIZE';

export default function AppBarHeaderProfile({
  anchorEl, setAnchorEl, isSmScreen, getIconType, getBadgeType,
}) {
  // -------------------------------------------------------------------------------------------------------------------------------------------------

  return (
    <ProfileButton
      endIcon={<ExpandMore />}
      size="small"
      color="neutral"
      onClick={(e) => {
        if (anchorEl) {
          setAnchorEl(null);
        } else {
          setAnchorEl(e.currentTarget);
        }
      }}
    >
      {isSmScreen ? <div />
        : (
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={getBadgeType()}
          >
            <Avatar
              sx={{
                backgroundColor: COLORS.gray0,
                width: 34,
                height: 34,
                fontSize: FONT_SIZE.body,
                fontWeight: 'bold',
                '& .MuiSvgIcon-root': {
                  width: 16, height: 16,
                },
              }}
            >
              {getIconType()}
            </Avatar>
          </Badge>

        )}
    </ProfileButton>

  );
}

const ProfileButton = styled(
  Button,
)(({ theme }) => ({

  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(0),
    minWidth: 0,
    '& .MuiSvgIcon-root': {
      fontSize: '25px',
    },
  },
  marginLeft: theme.spacing(2),
  marginRight: '-12px',

}));
