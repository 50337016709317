import React from 'react';
// MUI
import {
  Box,
  Divider,
  Link,
  Typography,
} from '@mui/material';
// CONST AND UTIL
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import COLORS from 'constants/COLORS';

import FileInput from 'components/shared/Input/FileInput';
import AttachmentViewerFile from 'components/shared/AttachmentViewer.js/AttachmentViewerFile';

export default function DraftRecordInputFile({
  editInvoice, formik,
}) {
  return (
    <>
      <Grid2 container sx={{ width: '100%', alignItems: 'center' }} rowSpacing={2}>
        <Grid2 xs={12} sm>
          <Box display="flex">
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 'bold', mr: 2 }}
            >
              Upload Invoice Attachment (Optional)
            </Typography>
          </Box>
          <Typography
            variant="body2"
            sx={{ color: COLORS.labelSecondary, mt: 1 }}
          >
            No file? Ara auto-generates your invoice —
            {' '}
            <Link
              href={'https://ara-app-public.s3.ap-southeast-1.amazonaws.com/public/ara-app-sample-invoice.pdf'}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ fontWeight: 'bold' }}
            >
              check out a sample!
            </Link>
          </Typography>
        </Grid2>

      </Grid2>
      <Divider sx={{ my: 2 }} />
      <Box p={2}>
        <Grid2 container sx={{ width: '100%', alignItems: 'center' }} rowSpacing={4} columnSpacing={4}>
          {editInvoice?.files?.user_uploaded_record?.url
            && (
              <Grid2 xs={12} md={6}>
                <Box maxWidth={240}>
                  <AttachmentViewerFile
                    url={`${editInvoice.files.user_uploaded_record?.url}`}
                    fileName={`draft-${editInvoice?.record_number}`}
                  />
                </Box>

              </Grid2>
            )}
          <Grid2 xs={12} md={6}>
            <FileInput formik={formik} />
          </Grid2>
        </Grid2>
      </Box>

    </>

  );
}
