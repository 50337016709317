import { find } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { useStoreAuth } from 'store/auth-store';

// STORES
import { getSecurePayeeAPI } from 'util/API';
import { getErrMessageUtil } from 'util/util';

export const UserAboutMeAPI = async () => {
  const { setUserProfileStore, setBusinessProfileStore } = useStoreAuth.getState();

  const API = getSecurePayeeAPI('user');
  const url = '/user/aboutme';

  try {
    const res = await API.get(url);
    const { data } = res.data;
    const userData = data?.user;

    if (!userData) return [null, 'User data not found'];

    const isVerifiedBizExist = find(userData?.businesses, (b) => b?.account_type === 'verified') || null;
    setBusinessProfileStore(isVerifiedBizExist);
    setUserProfileStore(userData);

    return [true, data?.user];
  } catch (err) {
    const errMessage = getErrMessageUtil(err);
    enqueueSnackbar(errMessage, { variant: 'error' });
    return [null, errMessage];
  }
};
