import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, CircularProgress, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import COLORS from 'constants/COLORS';

export default function AlertDialog({
  open, setOpen, title = '', message = '', actions = [], isLoading = false, message2 = '', maxWidth = 'xs',
}) {
  const handleClose = () => {
    if (isLoading) return;

    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={maxWidth}
        disableEscapeKeyDown={isLoading}
      >
        <DialogTitle sx={{ px: 4, pt: 4, fontSize: 24 }}>
          {title}
        </DialogTitle>
        <DialogContent sx={{ px: 4, pb: 5 }}>
          {message != null && message !== ''
          && (
          <Typography variant="body1" sx={{ mt: 0, mb: 3, fontSize: 14 }}>
            {message}
          </Typography>
          )}
          {message2 != null && message2 !== '' && message2}
          {isLoading ? (
            <Box sx={{
              width: '100%', minHeight: 120, display: 'flex', alignItems: 'center', justifyContent: 'center',
            }}
            >
              <CircularProgress sx={{ color: COLORS.gray0 }} size={30} />
            </Box>
          ) : (
            <>
              {actions.map((a) => (
                <LoadingButton
                  key={a.text}
                  sx={{ mt: 2 }}
                  onClick={a.onClick}
                  color={a.color != null ? a.color : 'primary'}
                  fullWidth
                  variant={a.variant != null ? a.variant : 'contained'}
                >
                  {a.text}
                </LoadingButton>
              ))}
            </>
          )}

        </DialogContent>

      </Dialog>
    </div>
  );
}
