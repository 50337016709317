import COLORS from 'constants/COLORS';
import { startCase } from 'lodash';

export const getRecordBadgeColorUtil = (data) => {
  const stat = data.status;

  let color = COLORS.primary;
  let bgColor = COLORS.primaryLight;
  let label = startCase(stat);
  const tooltip = '';

  if (stat === 'outstanding') {
    color = COLORS.primary;
    bgColor = COLORS.primaryLight;
    label = 'Not due';
  } else if (stat === 'overdue') {
    color = COLORS.red;
    bgColor = COLORS.redLight;
  } else if (stat === 'paid') {
    color = COLORS.green;
    bgColor = COLORS.greenLight;
  } else if (stat === 'voided') {
    color = COLORS.badgeGrey;
    bgColor = COLORS.badgeGreyLight;
  } else if (stat === 'draft') {
    color = COLORS.badgeGrey;
    bgColor = COLORS.badgeGreyLight;
  }

  return {
    color, bgColor, label, tooltip,
  };
};
