import React from 'react';
// MUI
import {
  Box, Button, Divider, Typography,
} from '@mui/material';

import COLORS from 'constants/COLORS';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import DraftInvoiceInputCustomerSearch from './DraftRecordInputCustomerSearch';
import { AraStatusChip } from 'components/shared/styled-component';
import DraftInvoiceInputCustomerEmpty from './DraftRecordInputCustomerEmpty';
import { ChangeCircleOutlined } from '@mui/icons-material';

export default function DraftRecordInputCustomer({
  formik,
}) {
  const customerId = formik?.values?.customer_id;

  return (
    <>
      <Grid2 container sx={{ width: '100%', alignItems: 'center' }} rowSpacing={2}>
        <Grid2 xs={12} sm>
          <Box display="flex" alignItems={'center'}>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 'bold', mr: 2 }}
            >
              Customer Detail
            </Typography>
            <AraStatusChip
              label={customerId ? 'Completed' : 'Required'}
              size="small"
              customColor={customerId ? COLORS.green : COLORS.red}
              bgColor={customerId ? COLORS.greenLight : COLORS.redLight}
            />

          </Box>
          <Typography
            variant="body2"
            sx={{ color: COLORS.labelSecondary, mt: 1 }}
          >
            Search among existing customers or add a new one
          </Typography>
        </Grid2>
        <Grid2 xs={12} sm="auto">
          {customerId != null
            && (
              <Button
                size="small"
                color="primary"
                variant="outlined"
                sx={{
                  fontWeight: 'bold',
                  boxShadow: 'none',
                  fontSize: 10,
                  mr: 2,
                  zIndex: 99,
                  whiteSpace: 'nowrap',
                }}
                startIcon={<ChangeCircleOutlined />}
                onClick={() => {
                  formik.setFieldValue('customer_id', null);
                }}
              >
                Change customer
              </Button>
            )}
        </Grid2>

      </Grid2>
      <Divider sx={{ my: 2 }} />
      <Box p={2}>
        {customerId == null && <DraftInvoiceInputCustomerSearch formik={formik} />}
        {customerId != null && <DraftInvoiceInputCustomerEmpty customerId={customerId} />}
      </Box>
    </>
  );
}
