// React imports
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

// Material-UI imports
import {
  Box,
  Container,
  Grid,
  Typography,
  styled,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// Forms
import { Form, FormikProvider, useFormik } from 'formik';

// Constants & Utilities
import COLORS from 'constants/COLORS';
import { DraftInvoiceFormikInitialValues } from 'util/DraftInvoice/draft-invoice-formik-initial-values';
import { DraftInvoiceSchema } from 'util/DraftInvoice/draft-invoice-validation';

// Components
import ResponsiveNanoWrapper from 'components/shared/ResponsiveNanoWrapper/ResponsiveNanoWrapper';
import DraftRecordInputCustomer from 'components/DraftRecord/DraftRecordInput/DraftRecordInputCustomer/DraftRecordInputCustomer';
import DraftRecordInputInvoice from 'components/DraftRecord/DraftRecordInput/DraftRecordInputInvoice';
import DraftRecordInputFile from 'components/DraftRecord/DraftRecordInput/DraftRecordInputFile';

// Validation and Notification
import { cloneDeep } from 'lodash';
import { RecordConvertDraftAPI, RecordCreateOneDraftAPI } from 'services/record-api-services';
import { enqueueSnackbar } from 'notistack';

// Other imports
import { PATH_USER } from 'constants/PATHS';
import { useQueryClient } from '@tanstack/react-query';

export default function DraftRecordCreateScreen() {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isConvert, setIsConvert] = useState(false);

  const formik = useFormik({
    initialValues: DraftInvoiceFormikInitialValues({ customer_id: searchParams?.get('customerId') || null }),
    validationSchema: DraftInvoiceSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        const reqPayload = cloneDeep(values);
        delete reqPayload.processable_value;
        delete reqPayload.file;

        const [status, data] = await RecordCreateOneDraftAPI(reqPayload, values?.file || null);

        if (!status || !data) return;

        if (!isConvert) {
          enqueueSnackbar('Your draft invoice has been successfully created.', { variant: 'success' });
          navigate(PATH_USER.draftinvoices.root);
          return;
        }

        const [convertStatus, convertData] = await RecordConvertDraftAPI([data?.record?._id]);

        if (!convertStatus || !convertData) {
          enqueueSnackbar('Oops! Something went wrong while sending the invoice, but your draft invoice has been successfully created.', { variant: 'info' });
          navigate(PATH_USER.draftinvoices.root);
          return;
        }

        enqueueSnackbar('Your invoice has been successfully sent.', { variant: 'success' });
        navigate('/user/invoices/active');
        navigate(PATH_USER.invoices.detail('active', data?.record?._id));
      } catch (e) {
        console.error(e);
      } finally {
        setSubmitting(false);
        setIsConvert(false);
        queryClient.invalidateQueries({
          queryKey: ['getRecordMetadata'],
        });
      }
    },
  });

  const handleClose = () => {
    navigate(PATH_USER.draftinvoices.root);
  };

  const { handleSubmit, isSubmitting } = formik;

  useEffect(() => {
    if (isConvert) {
      handleSubmit();
    }
  }, [isConvert]);

  return (

    <ResponsiveNanoWrapper
      isShowCloseButton={false}
      isSmallScreenOpen
      handleOnCloseSmallScreen={handleClose}
      handleOnOpenSmallScreen={() => { }}
    >

      <Box sx={{ p: 4 }}>

        <FormikProvider value={formik}>

          <Form
            autoComplete="on"
            noValidate
            onSubmit={handleSubmit}
          >
            <Container maxWidth="md" disableGutters sx={{ mb: 4 }}>
              <Grid container spacing={2}>
                <Grid item xs>
                  <Typography variant="h5" fontWeight={'bold'}>Create New Invoice</Typography>
                </Grid>
                <Grid item xs="auto">
                  <LoadingButton
                    type="button"
                    size="small"
                    color="neutral"
                    sx={{ mr: 2 }}
                    onClick={handleClose}
                  >
                    Cancel
                  </LoadingButton>
                  <LoadingButton
                    size="small"
                    type="submit"
                    sx={{ mr: 2, fontSize: 11 }}
                    variant="contained"
                    loading={isSubmitting}
                    color="neutral"
                  >
                    Save As draft
                  </LoadingButton>
                  <LoadingButton
                    size="small"
                    variant="contained"
                    loading={isSubmitting}
                    onClick={() => {
                      if (!formik.isValid) {
                        formik.setTouched(Object.keys(formik.values).reduce((acc, key) => {
                          acc[key] = true;
                          return acc;
                        }, {}));
                        return;
                      }

                      setIsConvert(true);
                    }}
                  >
                    Send Invoice
                  </LoadingButton>
                </Grid>
              </Grid>
            </Container>
            <NanoPaperContainer
              maxWidth="md"
              disableGutters
            >

              <DraftRecordInputCustomer formik={formik} />
              <Box sx={{ height: 40 }} />
              <DraftRecordInputInvoice formik={formik} />
              <Box sx={{ height: 40 }} />
              <DraftRecordInputFile formik={formik} />
            </NanoPaperContainer>
          </Form>
        </FormikProvider>
      </Box>
    </ResponsiveNanoWrapper>

  );
}

const NanoPaperContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(0),
  background: '#fff',
  borderRadius: 2,
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    border: `1px solid ${COLORS.divider}`,
    boxShadow: '0 8px 10px rgba(112,144,176,0.2)',
    padding: theme.spacing(4),
  },

}));
