// React
import React from 'react';
import { useNavigate } from 'react-router-dom';

// MUI
import {
  Box,
  Container,
  Grid,
  IconButton,
  Typography,
  styled,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// Forms
import { Form, FormikProvider, useFormik } from 'formik';

// Services
import { CustomerCreateOneByBusinessIdAPI } from 'services/customer-api-services';

// Constants & Utils
import { CustomerFormikInitialValues } from 'util/Customer/cust-formik-initial-values';
import COLORS from 'constants/COLORS';

// Components
import ResponsiveNanoWrapper from 'components/shared/ResponsiveNanoWrapper/ResponsiveNanoWrapper';
import CustomerManagementNanoInputBusinessDetail from 'components/Customer/CustomerInput/CustomerInputBusinessDetail';
import CustomerManagementNanoInputContactDetail from 'components/Customer/CustomerInput/CustomerInputContactDetail';
import CustomerManagementNanoInputAddressDetail from 'components/Customer/CustomerInput/CustomerInputAddressDetail';
import OnboardingWrapper from 'components/shared/Onboarding/OnboardingWrapper';

// Icons
import { Close } from '@mui/icons-material';

// Validation and Notification
import { CustomerSchema } from 'util/Customer/cust-validation';
import { enqueueSnackbar } from 'notistack';
import { PATH_USER } from 'constants/PATHS';

export default function CustomerCreateScreen() {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: CustomerFormikInitialValues(),
    validationSchema: CustomerSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const [status, data] = await CustomerCreateOneByBusinessIdAPI(values);
      if (!status || !data) {
        setSubmitting(false);
        return;
      }

      setSubmitting(false);
      const newCustomer = data;

      enqueueSnackbar('Successfully added new customer,  you can select this new customer as recipient to make payment for your next invoices.', { variant: 'success' });
      navigate(PATH_USER.customers.detail('active', newCustomer._id));
      localStorage?.setItem('last_selected_customer_type', values?.user_type);
    },
  });

  const handleClose = () => {
    navigate(PATH_USER.customers.root('active'));
  };

  const { handleSubmit, isSubmitting } = formik;

  return (
    <OnboardingWrapper>
      <ResponsiveNanoWrapper
        isShowCloseButton={false}
        isSmallScreenOpen
        handleOnCloseSmallScreen={handleClose}
        handleOnOpenSmallScreen={() => { }}
      >
        <Box sx={{ p: 4 }}>
          <FormikProvider value={formik}>

            <Form
              autoComplete="on"
              noValidate
              onSubmit={handleSubmit}
            >
              <NanoPaperContainer
                maxWidth="sm"
                disableGutters
              >
                <Grid container spacing={2} sx={{ p: 0, pb: 4 }}>
                  <Grid item xs>
                    <Typography variant="h6">Create New Customer</Typography>
                  </Grid>
                  <Grid item xs="auto">
                    <IconButton onClick={handleClose}><Close /></IconButton>
                  </Grid>
                </Grid>

                <CustomerManagementNanoInputBusinessDetail formik={formik} />
                <Box sx={{ height: 36 }} />
                <CustomerManagementNanoInputContactDetail formik={formik} />
                <Box sx={{ height: 36 }} />
                <CustomerManagementNanoInputAddressDetail formik={formik} />

                <Grid container justifyContent="flex-end" mt={4}>
                  <LoadingButton
                    type="button"
                    data-cy="cancel-customer-btn"
                    size="small"
                    color="neutral"
                    sx={{ mr: 2 }}
                    onClick={handleClose}
                  >
                    Cancel
                  </LoadingButton>
                  <LoadingButton
                    size="medium"
                    type="submit"
                    data-cy="create-customer-btn"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    Create Customer
                  </LoadingButton>

                </Grid>

              </NanoPaperContainer>
            </Form>
          </FormikProvider>
        </Box>
      </ResponsiveNanoWrapper>
    </OnboardingWrapper>
  );
}

const NanoPaperContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(0),
  background: '#fff',
  borderRadius: 2,
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    border: `1px solid ${COLORS.divider}`,
    boxShadow: '0 8px 10px rgba(112,144,176,0.2)',
    padding: theme.spacing(4),
  },

}));
