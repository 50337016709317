/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import {
  alpha,
  Box, Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state';
import HoverPopover from 'material-ui-popup-state/HoverPopover';

export default function PopoverTooltip({ children, tooltip = '' }) {
  return (
    <PopupState variant="popover">
      {(popupState) => (
        <>
          {React.cloneElement(children, bindHover(popupState))}
          {tooltip != null && tooltip !== '' && (
            <HoverPopover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              slotProps={{
                paper: {
                  elevation: 1,
                  sx: {
                    backgroundColor: alpha(grey[900], 0.85),
                    color: grey[100],
                    borderRadius: '4px',
                  },
                },
              }}
            >
              <Box sx={{ maxWidth: 'none', p: 1 }}>
                <Typography variant="caption">
                  {tooltip}
                </Typography>
              </Box>
            </HoverPopover>
          )}
        </>
      )}
    </PopupState>
  );
}
