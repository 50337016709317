// React imports
import React, {
  useState, useEffect, Suspense, lazy,
} from 'react';
import {
  Navigate, Outlet, Route, Routes, useNavigate,
} from 'react-router-dom';

// CSS import
import './App.css';

// Components
import FullScreenLoader from 'components/shared/FullScreenLoader/FullScreenLoader';

// Screens
import AuthScreen from 'screens/Auth/AuthScreen';
import FileDownloadScreen from 'screens/FileDownloadScreen';
import NotFoundScreen404 from 'screens/NotFoundScreen404';
import SplashScreen from 'screens/SplashScreen';
import SettingsScreen from 'screens/Settings/SettingsScreen';
import CustomerCreateScreen from 'screens/Customer/CustomerCreateScreen';
import CustomerScreen from 'screens/Customer/CustomerScreen';
import CustomerDetailScreen from 'screens/Customer/CustomerDetailScreen';

// Store
import { useStoreAuth } from 'store/auth-store';

// Services
import { UserAboutMeAPI } from 'services/user-api-services';

// Layouts
import ProtectedUserRouteLayout from 'components/shared/Layout/ProtectedUserRouteLayout/ProtectedUserRouteLayout';
import AuthLayout from 'components/shared/Layout/AuthLayout/AuthLayout';

// Auth Screens
import AuthSignInScreen from 'screens/Auth/AuthSignInScreen';
import AuthSignUpScreen from 'screens/Auth/AuthSignUpScreen';
import AuthVerifyAccountScreen from 'screens/Auth/AuthVerifyAccountScreen';
import AuthMagicLinkSignInScreen from 'screens/Auth/AuthMagicLinkSignInScreen';
import AuthForgotPasswordScreen from 'screens/Auth/AuthForgotPasswordScreen';
import VerifyScreen from 'screens/VerifyScreen';

// Draft Record Screens
import DraftRecordCreateScreen from 'screens/DraftRecord/DraftRecordCreateScreen';
import DraftRecordEditScreen from 'screens/DraftRecord/DraftRecordEditScreen';
import DraftRecordScreen from 'screens/DraftRecord/DraftRecordScreen';
import DraftRecordNanoScreen from 'screens/DraftRecord/DraftRecordNanoScreen';

// Record Screens
import RecordScreen from 'screens/Record/RecordScreen';
import RecordDetailScreen from 'screens/Record/RecordDetailScreen';
import RecordNanoScreen from 'screens/Record/RecordNanoScreen';
import SettingsPasswordScreen from 'screens/Settings/SettingsPasswordScreen';
import SettingsStripeScreen from 'screens/Settings/SettingsStripeScreen';
import SettingsNotificationScreen from 'screens/Settings/SettingsNotificationScreen';

// LAZY LOADING FOR CODE SPLITING TO IMPROVE PERFORMANCE
const AuthResetPasswordScreen = lazy(() => import('screens/Auth/AuthResetPasswordScreen'));
const TermsScreen = lazy(() => import('screens/TermsScreen'));

function App() {
  const navigate = useNavigate();

  const {
    resetAuthStorageAndStore,
  } = useStoreAuth();

  const [isLoaded, setIsLoaded] = useState(false);

  // -------------------------------------------------------------------------------------------------------------------------------------------------

  const handleAuthProfile = async (redirect = null) => {
    const pathName = window.location.pathname;
    const sessionToken = await localStorage.getItem('session_token');

    // Go To Page Directly, Skip Auth if URL are the following pages
    const availablePathName = ['/verify', '/auth/verify', '/files', '/terms', '/auth/reset-password'];
    if (!redirect && pathName) {
      for (let i = 0; i < availablePathName.length; i++) {
        if (pathName.includes(availablePathName[i])) {
          setIsLoaded(true);
          return;
        }
      }
    }

    // Quick Registration and Login for User
    // For example: http://localhost:3000/auth?email=joe+payee
    const searchParams = new URLSearchParams(window.location.search);
    if (pathName.includes('/auth') && searchParams?.get('email')) {
      setIsLoaded(true);
      return;
    }

    // If session token does not exist, meant not login. Go Auth Page
    if (!sessionToken) {
      setIsLoaded(true);
      resetAuthStorageAndStore();
      navigate('/auth');
      return;
    }

    // Verify User
    const [apiStatus, userData] = await UserAboutMeAPI();
    if (!apiStatus || !userData) {
      setIsLoaded(true);
      resetAuthStorageAndStore();
      navigate('/auth');
      return;
    }

    if (redirect) {
      navigate(redirect);
      return;
    }

    if (!pathName || pathName === '/' || pathName === '/auth') {
      navigate('/user/invoices/active');
    }

    setIsLoaded(true);
  };

  useEffect(() => {
    handleAuthProfile();
  }, []);

  // -------------------------------------------------------------------------------------------------------------------------------------------------

  return isLoaded ? (
    <Suspense fallback={<FullScreenLoader isLoading />}>
      <Routes>
        <Route path="/" element={<Outlet />}>
          <Route
            index
            element={<SplashScreen handleAuthProfile={handleAuthProfile} />}
          />
          <Route path="terms" element={<TermsScreen />} />
          <Route path="files" element={<Outlet />}>
            <Route path="download/:jwtToken" element={<FileDownloadScreen />} />
          </Route>
          <Route path="verify/:nanoid" element={<VerifyScreen handleAuthProfile={handleAuthProfile} />} />
          <Route path="auth" element={<AuthLayout />}>
            <Route
              index
              element={<AuthScreen />}
            />
            <Route path="sign-in/:email" element={<AuthSignInScreen />} />
            <Route path="magic-link-sign-in/:email" element={<AuthMagicLinkSignInScreen />} />
            <Route path="sign-up/:email" element={<AuthSignUpScreen />} />
            <Route path="verify-account/:email" element={<AuthVerifyAccountScreen />} />
            <Route path="forgot-password/:email" element={<AuthForgotPasswordScreen />} />

            <Route path="reset-password/:jwtToken" element={<AuthResetPasswordScreen />} />
          </Route>
          <Route
            path="/user"
            element={(<ProtectedUserRouteLayout />)}
          >
            <Route
              index
              element={<Navigate to="/user/invoices" replace />}
            />
            <Route path="settings" element={<SettingsScreen />} />
            <Route path="draftinvoices" element={<DraftRecordScreen />}>
              <Route path="multi" element={<DraftRecordNanoScreen />} />
            </Route>
            <Route path="draftinvoices/create" element={<DraftRecordCreateScreen />} />
            <Route path="draftinvoices/edit/:recordId" element={<DraftRecordEditScreen />} />

            <Route path="invoices" element={<Outlet />}>
              <Route index element={<Navigate to="/user/invoices/active" replace />} />
              <Route path=":status" element={<RecordScreen />}>
                <Route path="detail/:recordId" element={<RecordDetailScreen />} />
                <Route path="multi" element={<RecordNanoScreen />} />
              </Route>
            </Route>
            <Route path="customers" element={<Outlet />}>
              <Route index element={<Navigate to="/user/customers/active" replace />} />
              <Route path="create" element={<CustomerCreateScreen />} />
              <Route path=":status" element={<CustomerScreen />}>
                <Route path="detail/:customerId" element={<CustomerDetailScreen />} />
              </Route>
            </Route>
            <Route
              path="settings"
              element={<SettingsScreen />}
            >
              <Route path="password" element={<SettingsPasswordScreen />} />
              <Route path="stripe" element={<SettingsStripeScreen />} />
              <Route path="notification" element={<SettingsNotificationScreen />} />
            </Route>
          </Route>
          <Route path="*" element={<NotFoundScreen404 />} />
        </Route>
      </Routes>
    </Suspense>
  ) : (
    <FullScreenLoader isLoading />
  );
}

export default App;
