import { create } from 'zustand';
import _ from 'lodash';

const initialDraftRecordStore = {
  draftRecordsStore: [],
  selectedDraftRecordIdsStore: [],

};

export const useStoreDraftRecord = create((set, get) => ({
  ...initialDraftRecordStore,
  resetDraftRecordStore: () => set({ ...initialDraftRecordStore }),

  // -------------------------------------------------------------------------------------------------------------------------------------------------
  // draftRecordsStore
  setDraftRecordsStore: (arr) => set({ draftRecordsStore: arr }),
  removeOneDraftRecordsStore: (remDataId) => {
    const {
      draftRecordsStore, addOrRemoveSelectedDraftRecordIdsStore,
    } = get();

    // Remove data from store
    const tempArr = _.filter(draftRecordsStore, (item) => item._id !== remDataId);
    set({ draftRecordsStore: [...tempArr] });

    // Remove id from selectedDraftRecordIdsStore
    addOrRemoveSelectedDraftRecordIdsStore(remDataId);
  },

  // -------------------------------------------------------------------------------------------------------------------------------------------------
  // selectedDraftRecordIdsStore
  setSelectedDraftRecordIdsStore: (arrOfIds) => set({ selectedDraftRecordIdsStore: arrOfIds }),
  addOrRemoveSelectedDraftRecordIdsStore: (newId) => {
    const { selectedDraftRecordIdsStore } = get();
    const isIdExist = _.find(selectedDraftRecordIdsStore, (id) => id === newId);

    if (isIdExist) {
      const remainingIds = _.filter(selectedDraftRecordIdsStore, (id) => id !== newId);
      set({ selectedDraftRecordIdsStore: remainingIds });
    } else {
      set({ selectedDraftRecordIdsStore: [...selectedDraftRecordIdsStore, newId] });
    }
  },

}));
