import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useStoreAuth } from 'store/auth-store';
// STORES
import { getAPI, getSecurePayeeAPI } from 'util/API';
import { getErrMessageUtil } from 'util/util';

export const useQueryGetOneRecord = (recordId) => {
  const { businessProfileStore } = useStoreAuth.getState();
  const businessId = businessProfileStore?._id;
  const API = getAPI('record');
  const queryKey = ['getOneRecord', businessId, recordId];

  const {
    isLoading, error, data, refetch, isRefetchError, isRefetching, ...others
  } = useQuery({
    queryKey,
    queryFn: () => API
      .get(`/record/get-one/${businessId}/${recordId}`)
      .then((res) => res?.data?.data?.record || null)
      .catch((err) => {
        throw new Error(err);
      }),
    enabled: recordId != null && businessId != null,
  });

  return {
    isLoading, error, data, refetch, isRefetchError, isRefetching, ...others,
  };
};

export const useInfiniteQueryGetRecords = ({ recordStatus, search, pageSize }) => {
  const { businessProfileStore } = useStoreAuth.getState();
  const businessId = businessProfileStore?._id;
  const API = getAPI('record');
  const queryKey = ['getRecords', recordStatus, search];

  const {
    status, data, fetchNextPage, hasNextPage, isFetching, refetch, ...others
  } = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam }) => API.get(`/record/${businessId}`, {
      params: {
        status: recordStatus,
        pageSize,
        search,
        pageNumber: pageParam,
        sort: JSON.stringify({ 'date.converted_at': -1 }),
      },
    })
      .then((res) => {
        const resData = res.data.data;

        return { ...resData, prevPage: pageParam };
      })
      .catch((err) => {
        throw new Error(err);
      }),
    getNextPageParam: (lastPage) => {
      if ((lastPage.prevPage * pageSize) >= lastPage.count) {
        return null;
      }

      return lastPage.prevPage + 1;
    },
    initialPageParam: 1,
  });

  return {
    status, data, fetchNextPage, hasNextPage, isFetching, refetch, ...others,
  };
};

export const useMutationVoidRecordsAPI = () => {
  const { businessProfileStore } = useStoreAuth.getState();
  const businessId = businessProfileStore?._id;
  const API = getAPI('record');

  const {
    data: mutData,
    error: mutError,
    isPending: mutIsPending,
    mutate: triggerMut,
    mutateAsync: asyncTriggerMut,
    reset: resetMut,
    status: mutStatus,
    ...othersMut
  } = useMutation({
    mutationFn: (recordIds) => API.patch('/record/void', { record_ids: recordIds, business_id: businessId })
      .then((res) => res?.data)
      .catch((err) => {
        const errMessage = getErrMessageUtil(err);
        enqueueSnackbar(errMessage, { variant: 'error' });
        throw new Error(errMessage); // Rethrow the error so React Query can catch it
      }),

  });

  return {
    mutData,
    mutError,
    mutIsPending,
    triggerMut,
    asyncTriggerMut,
    resetMut,
    mutStatus,
    othersMut,
  };
};

export const RecordCreateOneDraftAPI = async (reqBody, file = null) => {
  const { businessProfileStore } = useStoreAuth.getState();
  const API = getSecurePayeeAPI('record');
  const url = '/record';
  try {
    const res = await API.post(url, { ...reqBody, is_user_uploaded_record: file != null, business_id: businessProfileStore._id });
    const presignedUploadUrl = res?.data?.data?.presignedUploadUrl || null;
    if (presignedUploadUrl && file) {
      await axios.put(presignedUploadUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
      });
      enqueueSnackbar('Succesfully uploaded invoice file', { variant: 'success' });
    }

    return [true, res?.data?.data || null];
  } catch (err) {
    const errMessage = getErrMessageUtil(err);
    enqueueSnackbar(errMessage, { variant: 'error' });
    return [null, errMessage];
  }
};

export const RecordUpdateOneDraftAPI = async (recordId, reqBody, file = null) => {
  const { businessProfileStore } = useStoreAuth.getState();
  const API = getSecurePayeeAPI('record');
  const url = `/record/${recordId}`;
  try {
    const res = await API.put(url, { ...reqBody, is_user_uploaded_record: file != null, business_id: businessProfileStore._id });
    const presignedUploadUrl = res?.data?.data?.presignedUploadUrl || null;
    if (presignedUploadUrl && file) {
      await axios.put(presignedUploadUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
      });
      enqueueSnackbar('Succesfully uploaded invoice file', { variant: 'success' });
    }

    return [true, res?.data?.data || null];
  } catch (err) {
    const errMessage = getErrMessageUtil(err);
    enqueueSnackbar(errMessage, { variant: 'error' });
    return [null, errMessage];
  }
};

export const RecordDeleteManyDraftAPI = async (recordIds) => {
  const API = getSecurePayeeAPI('record');
  const url = '/record/delete-many';

  const { businessProfileStore } = useStoreAuth.getState();
  try {
    const body = {
      record_ids: recordIds,
      business_id: businessProfileStore?._id,
    };

    const res = await API.post(url, body);

    return [true, res.data];
  } catch (err) {
    const errMessage = getErrMessageUtil(err);
    enqueueSnackbar(errMessage, { variant: 'error' });
    return [null, errMessage];
  }
};

export const RecordConvertDraftAPI = async (recordIds) => {
  const { businessProfileStore } = useStoreAuth.getState();

  // 2 minutes
  const API = getSecurePayeeAPI('record');
  const url = '/record/convert';

  try {
    const newBody = {
      record_ids: recordIds,
      business_id: businessProfileStore._id,
    };

    const res = await API.patch(url, newBody);
    const { data } = res.data;
    return [true, data];
  } catch (err) {
    const errMessage = getErrMessageUtil(err);
    enqueueSnackbar(errMessage, { variant: 'error' });
    return [null, errMessage];
  }
};

export const useQueryGetRecordMetadata = () => {
  const { businessProfileStore } = useStoreAuth.getState();
  const businessId = businessProfileStore?._id;
  const API = getAPI('record');
  const queryKey = ['getRecordMetadata'];

  const url = `/record/metadata/${businessId}`;

  const {
    isLoading, error, data, refetch, isRefetchError, isRefetching, ...others
  } = useQuery({
    queryKey,
    queryFn: () => API
      .get(url)
      .then((res) => res?.data?.data?.metadata || null)
      .catch((err) => {
        throw new Error(err);
      }),
    enabled: businessId != null,
    staleTime: 30 * 1000, // 30 seconds
    refetchOnWindowFocus: true,
    retry: false,
  });

  return {
    isLoading, error, data, refetch, isRefetchError, isRefetching, ...others,
  };
};
