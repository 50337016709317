import * as React from 'react';
// MUI
import {
  Avatar,
  Box,
  Chip,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  Add,
  ExitToAppTwoTone, PersonRounded, Refresh, Schedule, VerifiedUser, WorkRounded,
} from '@mui/icons-material';
import { grey, yellow } from '@mui/material/colors';
// HOOKS
import useHandleLogoutHook from 'hooks/shared/useHandleLogoutHook';
// STORES
import { useStoreAuth } from 'store/auth-store';
import { useStoreShared } from 'store/shared-store';
// CONSTANTS
import COLORS from 'constants/COLORS';
// COMPS
import AppBarHeaderProfile from './AppBarHeaderProfile';
import { trim } from 'lodash';
import { LoadingButton } from '@mui/lab';
import { useQueryBusinessCheckOnboardingStatus } from 'services/business-api-services';

const LIST_WIDTH = '250px';

export default function AppBarHeaderAccount() {
  const handleLogoutHook = useHandleLogoutHook();

  const { businessProfileStore, userProfileStore } = useStoreAuth();
  const { setIsOpenBizAccModalStore } = useStoreShared();

  // -------------------------------------------------------------------------------------------------------------------------------------------------
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const fullName = businessProfileStore != null ? businessProfileStore.name : `${trim(userProfileStore?.first_name)} ${trim(userProfileStore?.last_name)}`;

  const {
    isLoading, error, data, refetch, isRefetchError,
  } = useQueryBusinessCheckOnboardingStatus();

  const isBizPending = !data?.onboarding_status;

  if (isLoading) {
    return (

      <LoadingButton loading>loading</LoadingButton>

    );
  }

  if (error || !data || isRefetchError) {
    return (
      <IconButton
        sx={{ ml: 1 }}
        onClick={() => {
          refetch();
        }}
      >
        <Refresh />
      </IconButton>

    );
  }

  const getBadgeType = () => {
    if (businessProfileStore != null && isBizPending) {
      return <OtherBadge><Schedule /></OtherBadge>;
    }

    if (businessProfileStore != null) {
      return (
        <OtherBadge sx={{ backgroundColor: COLORS.greenLight, border: `1px solid ${COLORS.greenLight}` }}>
          <VerifiedUser sx={{ color: 'green', backgroundColor: COLORS.greenLight }} />
        </OtherBadge>
      );
    }

    return <OtherBadge><PersonRounded /></OtherBadge>;
  };

  const getChipType = () => {
    if (businessProfileStore != null && isBizPending) {
      return <OtherChip icon={<Schedule />} label={'Pending'} size="small" sx={{ backgroundColor: yellow[600] }} />;
    }

    if (businessProfileStore != null) {
      return (
        <OtherChip
          icon={<VerifiedUser sx={{ color: 'green !important' }} />}
          label={'Verified'}
          size="small"
          sx={{ backgroundColor: COLORS.greenLight }}
        />
      );
    }

    return <OtherChip label={'Individual'} size="small" />;
  };

  const getIconType = () => {
    if (businessProfileStore) {
      return <WorkRounded fontSize="small" />;
    }

    return <PersonRounded fontSize="small" />;
  };

  // -------------------------------------------------------------------------------------------------------------------------------------------------

  return (
    <>
      <AppBarHeaderProfile
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        isSmScreen={isSmScreen}
        getIconType={getIconType}
        getBadgeType={getBadgeType}
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => { setAnchorEl(null); }}
      >
        <List sx={{
          minWidth: LIST_WIDTH, py: 2, px: 2, backgroundColor: COLORS.primary,
        }}
        >
          <ListItem disablePadding>
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
              <Avatar sx={{ mr: 1 }}>
                {getIconType()}
              </Avatar>
              <Box>
                <Typography sx={{ fontWeight: 'bold', m: 0, color: '#fff' }}>
                  {fullName}
                </Typography>
                <Typography
                  sx={{
                    ml: 0.2, color: '#fff', mb: 1,
                  }}
                  variant="subtitle2"
                >
                  {userProfileStore.email}
                </Typography>
                {getChipType()}
              </Box>
            </Box>
          </ListItem>
        </List>
        <List sx={{ minWidth: LIST_WIDTH, py: 0, px: 0 }}>
          <ListItem disablePadding>
            <ListItemButton onClick={() => { setIsOpenBizAccModalStore(true); }}>
              <ListItemIcon sx={{ mr: 1, minWidth: 0 }}>
                <Add fontSize="small" color="primary" />
              </ListItemIcon>
              <ListItemText primary="Open Business Account" sx={{ color: COLORS.primary, '& .MuiTypography-root': { fontWeight: 700 } }} />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
        <List sx={{ minWidth: LIST_WIDTH, py: 0, px: 0 }}>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleLogoutHook()}>
              <ListItemIcon sx={{ mr: 1, minWidth: 0 }}>
                <ExitToAppTwoTone fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Sign out" />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>

    </>
  );
}

const OtherChip = styled(
  Chip,
)(({ theme }) => ({
  padding: theme.spacing(0),
  fontSize: 10,
  fontWeight: 600,
  height: 18,
  minWidth: 50,
  backgroundColor: grey[300],
  color: grey[800],
  '& .MuiSvgIcon-root': {
    width: 12,
    height: 12,
    color: grey[800],
  },
  '& .MuiChip-label': {
    // padding: 0,
    paddingLeft: 7,
  },
}));

const OtherBadge = styled(Avatar)(() => ({
  width: 14,
  height: 14,
  backgroundColor: grey[200],
  color: grey[800],
  border: '1px solid #fff',
  '& .MuiSvgIcon-root': {
    width: 10,
    height: 10,
  },
}));
