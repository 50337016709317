import React, { useState } from 'react';

// MUI
import {
  Grid,
  Typography,
  styled,
  Container,
  Box,
  Divider,

} from '@mui/material';

// CONSTANTS
import COLORS from 'constants/COLORS';

import { useStoreAuth } from 'store/auth-store';
import { BusinessUpdateNotificationSettingsAPI } from 'services/business-api-services';
import { IOSSwitch } from 'components/shared/styled-component';

export default function SettingsNotificationScreen() {
  const { businessProfileStore, setBusinessProfileStore } = useStoreAuth();

  const [isReminderLoading, setIsReminderLoading] = useState(false);

  const handleUpdateNotification = async (body) => {
    setIsReminderLoading(true);
    const [apiStatus, business] = await BusinessUpdateNotificationSettingsAPI(body);
    setIsReminderLoading(false);

    if (!apiStatus || !business) return;

    setBusinessProfileStore({ ...business });
  };

  const emailNoti = businessProfileStore?.notification_settings?.email;
  const smsNoti = businessProfileStore?.notification_settings?.sms;

  return (

    <NanoPaperContainer maxWidth="sm" disableGutters>
      <NanoTitle container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Notifications</Typography>
          <Typography
            mt={1}
            variant="body1"
            sx={{ color: COLORS.labelSecondary }}
          >
            Switch on which notification channel you’d like to use as default for existing & new customers.
            Existing customers with custom notification settings won’t be affected.
          </Typography>
        </Grid>
        {smsNoti != null && emailNoti != null
          && (
            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                <Typography variant="subtitle1" fontWeight={700}>Email</Typography>
                <IOSSwitch
                  data-cy="customer-email-notification"
                  checked={emailNoti}
                  onClick={(e) => {
                    handleUpdateNotification({
                      sms: smsNoti,
                      email: e.target.checked,
                    });
                  }}
                  disabled={isReminderLoading}
                />
              </Box>
              <Divider sx={{ my: 2 }} />
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="subtitle1" fontWeight={700}>SMS</Typography>
                <IOSSwitch
                  data-cy="customer-sms-notification"
                  checked={smsNoti}
                  onClick={(e) => {
                    handleUpdateNotification({
                      sms: e.target.checked,
                      email: emailNoti,
                    });
                  }}
                  disabled={isReminderLoading}
                />
              </Box>
            </Grid>
          )}
      </NanoTitle>

    </NanoPaperContainer>

  );
}

const NanoPaperContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(4, 2),
  background: '#fff',
  borderRadius: 2,
  position: 'relative',
  marginTop: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    border: `1px solid ${COLORS.divider}`,
    boxShadow: '0 8px 10px rgba(112,144,176,0.2)',
    padding: theme.spacing(4, 4),

  },
}));

const NanoTitle = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(0, 0, 0, 0),
}));
