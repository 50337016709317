import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
// MUI
import {
  Box,
  LinearProgress, Link, Stack, Typography, styled,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// STORES

// UITL CONSTANTS
import { getErrMessageUtil } from 'util/util';
import COLORS from 'constants/COLORS';

import { useStoreAuth } from 'store/auth-store';
import { getAPI } from 'util/API';

const MagicLinkVerifyTokenAPI = async (nanoid) => {
  const API = getAPI('magic_link');
  const url = `/magiclink/verify/${nanoid}`;
  try {
    const res = await API.get(url);
    const { data } = res.data;
    return [true, data];
  } catch (err) {
    const errMessage = getErrMessageUtil(err);
    enqueueSnackbar(errMessage, { variant: 'error' });
    return [null, errMessage];
  }
};

export default function VerifyScreen({ handleAuthProfile }) {
  const navigate = useNavigate();
  const { nanoid } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const handleAuthVerify = async () => {
    setIsLoading(true);
    const [apiStatus, data] = await MagicLinkVerifyTokenAPI(nanoid);
    setIsLoading(false);

    if (!apiStatus || !data) {
      setIsError(true);
      return;
    }

    handleNavigationFlow(data);
  };

  const handleNavigationFlow = (data) => {
    const { action, token, resetPasswordToken } = data;

    switch (action) {
      case 'payee_auth_forgot_password':
        navigate(`/auth/reset-password/${resetPasswordToken}`);
        break;
      case 'payee_sign_in':
        useStoreAuth.getState().setUserSessionKeyStore(token);
        handleAuthProfile('/user/invoices/active');
        break;
      case 'payee_verify_email_address':
        useStoreAuth.getState().setUserSessionKeyStore(token);
        handleAuthProfile('/user/invoices/active');
        break;
      default:
        handleAuthProfile('/user/invoices/active');
    }
  };

  useEffect(() => {
    if (!nanoid) {
      navigate('/');
    }

    handleAuthVerify();
  }, [nanoid]);

  if (isLoading) return <LoadingScreen />;
  if (isError) return <ErrorScreen />;

  return <VerifiedScreen />;
}

function ErrorScreen() {
  return (
    <MainBox>
      <Stack sx={{ width: '80%', maxWidth: '400px', textAlign: 'center' }} spacing={2}>
        <img
          alt="verify-failed"
          src="/images/auth/verify-failed.webp"
          style={{ width: '80%' }}
        />
        <Typography sx={{ pt: 4, fontWeight: 700 }} variant="h5">
          Verification Failed
        </Typography>
        <Typography variant="subtitle1">
          If you need any help you can email us at
          {' '}
          <Link
            tabIndex={-1}
            target="_top"
            rel="noopener noreferrer"
            href="mailto:sales@ara-app.com"
            sx={{ fontWeight: 'bold' }}
          >
            sales@ara-app.com
          </Link>
          .
        </Typography>
      </Stack>

    </MainBox>
  );
}

function LoadingScreen() {
  return (
    <MainBox>
      <Stack sx={{ width: '80%', maxWidth: '400px', textAlign: 'center' }} spacing={2}>
        <img
          alt="verifying"
          src="/images/auth/verifying.webp"
          style={{ width: '100%' }}
        />
        <LinearProgress />
        <Typography sx={{ pt: 4, fontWeight: 700 }} variant="h5">
          Verifying
        </Typography>
      </Stack>

    </MainBox>
  );
}

function VerifiedScreen() {
  return (
    <MainBox>
      <Stack sx={{ width: '80%', maxWidth: '400px', textAlign: 'center' }} spacing={2}>
        <img
          alt="verified"
          src="/images/auth/verified.webp"
          style={{ width: '100%' }}
        />
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          pt: 4,
          color: COLORS.green,
        }}
        >
          <CheckCircleIcon fontSize="large" sx={{ mr: 2 }} />
          <Typography sx={{ fontWeight: 700 }} variant="h4">
            Verified
          </Typography>
        </Box>

      </Stack>

    </MainBox>
  );
}

const MainBox = styled(Box)(() => ({
  marginTop: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',

}));
